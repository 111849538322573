.dynamic-footer {
	width: 1530px;
	height: 70px;
	display: flex;
	align-items: center;
	// margin-left: 10px;
	margin-bottom: 10px;
	margin-top: 20px;
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 4px rgb(0 0 0 / 16%);
	border-radius: 10px;
	opacity: 1;
}
