.holiday-widget-comp {
  padding: 10px;
  padding-right: 0px;
  height: 100%;

  .holiday-widget-header {
    height: 40px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #fefefe;
    // background-color: red;
    .item-header {
      display: flex;
      padding-left: 10px;
      font-weight: 600;
      font-size: 15px;
    }
  }

  .holiday-calendar-content {
    height: calc(100% - 40px);
    overflow: scroll;
  }
  .content-holiday {
    display: flex;
    .content-itme {
      font-size: 14px;
      font-weight: 400;
      padding-left: 10px;
      margin-bottom: 5px;
      .item-day {
        width: 50%;
        border-radius: 5px;
        color: #fff;
        display: flex;
        justify-content: center;
      }
    }
  }

  // .holiday-calendar-content {
  // 	// margin-top: -30px;
  // }
}
