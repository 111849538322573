.employee-create-confirm,
.departmentpopover,
.reasonPopover {
  top: 50px !important;
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-message-title {
    font: normal normal normal 16px "Open Sans";
    letter-spacing: 0;
    color: #323338;
    opacity: 1;
  }
  .ant-popover-inner {
    align-items: center;
    width: auto;
    height: 70px;
    border-radius: 10px !important;
  }
  .ant-popover-message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
  }
  .ant-popover-inner-content {
    display: flex;
    padding: 0;
  }
  .ant-btn-sm {
    border-color: #ff4e00 !important;
    background-color: #ff4e00 !important;
    color: #fff !important;
    width: 50px !important;
    height: 70px !important;
    border-radius: 0;
  }
  .ant-btn-sm:hover {
    background-color: #ff4e00;
    border-color: #ff4e00;
    color: #fff;
  }
  .ant-btn-sm:focus {
    color: #fff;
  }
  .ant-btn-primary.ant-btn-sm {
    margin: 0;
    border-color: #1da193 !important;
    background: #1da193 !important;
    border-radius: 0 10px 10px 0;
  }
  .ant-popover.ant-popconfirm.employee-create-confirm.ant-popover-placement-top {
    top: 60px !important;
    left: 33.3% !important;
    .ant-popover-arrow {
      display: none !important;
    }

    .ant-btn-primary.ant-btn-sm {
      margin: 0;
      border-color: #1da193 !important;
      background: #1da193 !important;
      border-radius: 0 10px 10px 0;
    }
  }
}
.employee-create-confirm {
  left: 33% !important;
  top: 1px !important;
}
.departmentpopover {
  left: 25% !important;
}
.reasonPopover {
  left: 29% !important;
}

//* responsive Popover for iPad Air
@media (max-width: 821px) {
  .employee-create-confirm {
    left: 10% !important;
  }
  .employee-create-confirm .ant-popover-inner {
    width: 100%;
    margin-left: -30px;
  }
  .ant-popover-buttons {
    display: flex;
  }

  .departmentpopover {
    left: 1% !important;
    top: 64vw !important;
  }
  .departmentpopover .ant-popover-inner {
    width: 80vw;
  }
}

//* responsive Popover for Pc monitor
@media (max-width: 1025px) {
  .ant-popover.ant-popconfirm.employee-create-confirm.ant-popover-placement-top {
    left: 13% !important;
    top: 150px !important;
  }
  .ant-popover.ant-popconfirm.departmentpopover.ant-popover-placement-top {
    left: 13% !important;
    top: 510px !important;
  }
}

@media (max-width: 450px) {
  .departmentpopover .ant-popover-inner {
    height: fit-content;
  }
}

//* responsive Popover for iPhone 12 Pro
@media (max-width: 391px) {
  .ant-popover.ant-popconfirm.employee-create-confirm.ant-popover-placement-top {
    left: 15% !important;
    top: 560px !important;
  }
  .employee-create-confirm .ant-popover-inner {
    width: 100%;
    height: 70px;
  }
  .employee-create-confirm .ant-popover-message-title {
    margin: 0;
    font-size: 12px;
  }
  .ant-popover-buttons {
    width: 70px;
  }
  .employee-create-confirm .ant-btn-sm {
    left: 16px;
  }

  .ant-popover.ant-popconfirm.departmentpopover.ant-popover-placement-top {
    top: 600px !important;
  }
  .departmentpopover .ant-popover-message-title {
    font-size: 12px;
    margin-top: 0;
  }
}
