$card_blue: #e7eef2;
$input_background: #f5f5f7;
$checkbox_color: #1da193;

@mixin icon-buttons($bgcolor) {
  width: 150px;
  height: 32px;
  background: $bgcolor !important;
  border: none;
  border-radius: 5px;
  color: white;
  font: normal normal normal 14px/6px "Open Sans";
}
.add-new-request .ant-modal-content {
  border-radius: 20px 20px 10px 10px;
  width: 839px;
  height: 100%;
  margin-top: 20px;
  margin-right: 300px !important;
}
.add-new-request .ant-modal-body {
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
}
.add-new-request .ant-modal-header {
  height: 35px;
  display: flex;
  align-items: center;
  background-color: #e7eef2;
  border-radius: 10px 10px 0px 0px;
}
.add-new-request .ant-modal-title {
  color: #1d3445;
  font-weight: 600;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.add-new-request {
  .send-request.ant-btn {
    @include icon-buttons(#1da193);
  }
  .decline.ant-btn {
    @include icon-buttons(#fe4c4a);
    margin-right: 499px;
  }
  .ant-modal-footer {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .ant-form-item-control-input-content .ant-input {
    border: none;
    background-color: #f5f5f7;
    border-radius: 5px;
  }
  .ant-form-item {
    margin: 0px 0px 15px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: $input_background;
    border-radius: 5px;
    margin: 0 !important;
  }
  .ant-select {
    width: 100%;
  }
  .my-picker {
    width: 100%;
  }
  .label-quill-div {
    h3 {
      margin-top: 20px;
      text-align: start;
      margin-left: 90px;
    }
  }
  .quill-div {
    display: flex;
    justify-content: center;
    // margin-bottom: 20px;
  }
  .quill.note_input.quillTextEditor.request-text {
    display: inline-block;
    width: 100%;
    // height: 40px;
    .ql-toolbar.ql-snow {
      width: 100%;
      background: #f5f5f7;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .ql-container.ql-snow {
      background: #f5f5f7;
      height: 80px;
      border: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

@media (max-width: 390px) {
  .add-new-request .ant-modal-content {
    width: 370px;
    height: 100%;
  }
  .add-new-request .ant-modal-content {
    width: 370px;
    height: 100%;
  }
  .chair-icon {
    display: none;
  }
  .add-new-request .decline.ant-btn {
    margin-right: 22px !important;
  }
  .add-new-request .ant-modal-title {
    margin-left: 60px !important;
  }
  .add-new-request {
    .ant-select {
      width: 325px !important;
    }
    .my-picker {
      width: 325px;
    }
    .quill.note_input.quillTextEditor.request-text {
      width: 325px;
    }
  }
}

@media (max-width: 820px) {
  .add-new-request .ant-modal-content {
    width: 100%;
    height: 100%;
  }
  .add-new-request .ant-modal-content {
    width: 100%;
    height: 100%;
  }
  .chair-icon {
    display: none;
  }
  .add-new-request .decline.ant-btn {
    margin-right: 180px;
  }
  .add-new-request .ant-modal-title {
    margin-left: 130px;
  }
}
