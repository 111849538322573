@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,600,700);

#root {
  height: calc(100vh - 70px) !important;
}

.globalPageWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  width: 100%;
  height: 100%;
}
@media (max-width: 450px) {
  .globalPageWrapper {
    padding: 10px;
    gap: 10px;
    height: auto;
  }
}
.ant-tabs-nav {
  margin: 0 !important;
}

.ag-theme-alpine {
  .ag-grid-component {
    .ag-root-wrapper {
      border-bottom-left-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
      .ag-header {
        background: #1d3445;
        border: none;
        .ag-header-row.ag-header-row-column {
          color: #ffffff !important;
          font-weight: 400;
          font-size: medium;
        }
      }
      .ag-center-cols-viewport {
        height: 100%;
        .ag-center-cols-container {
          height: 100%;
          .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
            background: #f5f5f7 !important;
            border: none !important;
            &:hover {
              background: #e1e1e6 !important;
            }
          }
          .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
            border: none !important;
            &:hover {
              background: #e1e1e6 !important;
            }
          }
        }
      }
    }
  }
}

a:hover {
  text-decoration: none;
}
svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}

//SCROLLBAR

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #1f2a44;
  // Autumn
  background: #1d3445 !important;
  border-radius: 5px;
  &:hover {
    background-color: #35415e;
  }
}

.ag-center-cols-viewport {
  overflow-x: hidden !important;
}

.pad-0-20 {
  padding: 0 20px;
}

.ft-30 {
  font-size: 30px;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.ag-theme-alpine {
  --ag-row-hover-color: #e1e1e6 !important;
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-row-not-inline-editing {
    background: #f5f5f7 !important;
    border: none !important;
  }
  .ag-row-even.ag-row.ag-row-position-absolute.ag-row-not-inline-editing {
    border: none !important;
  }
}
.ant-modal {
  .ant-modal-content {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.65);
    .ant-modal-close {
      top: 0;
      inset-inline-end: 0;
      width: 45px;
      height: 45px;
      justify-content: center;
      border-radius: 0;
      border-top-right-radius: 10px;
    }
    display: flex;
    flex-direction: column;
    padding: 0;
    .ant-modal-header {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      border-radius: 0;
      height: 45px;
      border-radius: 10px 10px 0 0;
      .ant-modal-title {
        font: normal 600 normal 16px / 25px Open Sans;
        margin-left: 24px;
      }
    }
    .ant-modal-body {
      padding: 20px 24px;
      .ant-form-item {
        margin: 0;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 10px 24px;
      border-top: 1px solid #f0f0f0;
      .mondayButtonContainer:only-child {
        margin-left: auto;
      }
    }
  }
}
.lightHeader {
  .ant-modal-close {
    background: #e7eef2 0% 0% no-repeat padding-box;
    &:hover {
      background: #d5dbdf;
    }
    .ant-modal-close-x {
      align-items: center;
      height: 100%;
      color: #1d3445;
    }
  }
  .ant-modal-header {
    background: #e7eef2 0% 0% no-repeat padding-box;
    .ant-modal-title {
      color: #1d3445;
    }
  }
}
.darkHeader {
  .ant-modal-close {
    background: #1d3445 0% 0% no-repeat padding-box;
    &:hover {
      background: #233e53;
    }
    .ant-modal-close-x {
      align-items: center;
      height: 100%;
      color: #e7eef2;
    }
  }
  .ant-modal-header {
    background: #1d3445 0% 0% no-repeat padding-box;
    .ant-modal-title {
      color: #fff;
    }
  }
}
.ant-popover {
  .ant-popover-inner {
    padding: 0;
    .ant-popconfirm-inner-content {
      display: flex;
      .ant-popconfirm-message {
        margin: 0;
        align-items: center;
        padding: 0 15px;
        .ant-popconfirm-title {
          color: #323338;
          font: normal normal normal 16px Open Sans;
        }
      }
      .ant-popconfirm-buttons {
        button {
          margin-inline-start: 0;
        }
        button.ant-btn-variant-outlined:hover {
          border-color: #ff4e00 !important;
          background-color: #ff4e00 !important;
        }
      }
    }
  }
}
.ant-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 450px) {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        max-height: calc(100dvh - 150px);
        // max-width: calc(100dvw - 50px);
        padding: 10px 14px;
        overflow-y: auto;
      }
    }
  }
  .mondayButtonContainer {
    .mondayButtonText {
      min-width: max-content !important;
    }
  }
}
.ant-btn.mondayButtonGreen {
  &:hover {
    border-color: #1da193 !important;
  }
}

@mixin textEllipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin cardLayout() {
  background-color: #fff;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;

  .cardHeader {
    @include text(16px, 700, 0, #323338);
    @include flex(center, flex-start);
    background-color: #f8f8fa;
    padding: 0 20px;
    height: 45px;
    margin: -20px -20px 20px;
    // border-radius: 20px 20px 0 0;
  }
}

@mixin text(
  $font-size,
  $font-weight,
  $letter-spacing,
  $color,
  $text-transform: none,
  $opacity: 1,
  $important: false
) {
  @if $important == true {
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    opacity: $opacity !important;
    text-transform: $text-transform !important;
    letter-spacing: $letter-spacing !important;
    color: $color !important;
  }
  @if $important == false {
    font-size: $font-size;
    font-weight: $font-weight;
    opacity: $opacity;
    text-transform: $text-transform;
    letter-spacing: $letter-spacing;
    color: $color;
  }
}

@mixin flex($align-items, $justify-content, $direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin square($width, $height, $radius, $background: #fff, $opacity: 1) {
  width: $width;
  height: $height;
  border-radius: $radius;
  opacity: $opacity;
  background-color: $background;
}

@mixin circle($size, $color, $opacity: 1) {
  @include square($size, $size, 50%, $color, $opacity);
}

@mixin transition($time) {
  transition: all $time ease-in-out;
}

@mixin absolute(
  $top: unset,
  $right: unset,
  $bottom: unset,
  $left: unset,
  $position: absolute
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin mondayButton($backgroundColor, $hoverColor, $textColor: #fff) {
  background-color: $backgroundColor !important;
  color: $textColor !important;
  .mondayButtonText {
    min-width: 100px;
  }

  .mondayButtonDivider {
    background-color: $hoverColor !important;
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: $hoverColor !important;
    color: $textColor;

    .mondayButtonDivider {
      background-color: $backgroundColor !important;
    }
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Autumn
.mondayButtonBlue {
  @include mondayButton(#2e5d9e, #0f5c97);
  // @include mondayButton(#0f5c97, #0f5c97);
}
.mondayButtonRed {
  @include mondayButton(#ea3943, #eb4345);
  // @include mondayButton(#fe4c4a, #eb4345);
}
.mondayButtonGreen {
  @include mondayButton(#1da193, #1da193, #fff);
}
.mondayButtonGrey {
  @include mondayButton(#f5f5f7, #e6e9ef, #323338);
}
.mondayButtonDarkGrey {
  @include mondayButton(#787677, #787677, #fbfbfb);
}
.mondayButtonYellow {
  @include mondayButton(#fcc94a, #fcc94a, #fbfbfb);
}
@mixin flex($align-items, $justify-content, $flex-direction: row) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
}

@media (max-width: 450px) {
  body {
    -webkit-overflow-scrolling: touch;
  }
}
