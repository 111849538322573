.agGridHeader {
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 10px 20px;
  .headerRefresh {
    background-color: #f1f7fb;
    border: none;
    // width: 32px;
    // height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }
  .headerSearch {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    height: 100%;
    background-color: #f1f7fb;
    padding: 2px 10px;
    .icon {
      display: flex;
      align-items: center;
    }
    .headerInput {
      background-color: #f1f7fb;
      border: none;
    }
    .headerButton {
      width: 80px;
      height: 26px;
    }
  }
  .headerIcons {
    display: flex;
    align-items: center;
    gap: 15px;
    .headerIcon {
      display: flex;
      background-color: #f1f7fb;
      padding: 7px;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
        background-color: #e6ecf0;
      }
    }
  }
  .headerDropdown {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #f1f7fb;
    border-radius: 5px;
    padding: 0 10px;
    min-width: 250px;
    .ant-select {
      width: 100%;
      height: 100%;
      .ant-select-selector {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background: transparent;
        padding: 0;
        border: none;
      }
      span.ant-select-arrow {
        color: #323338;
      }
    }
  }
  .headerSwitch {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}
