@import "../../../../index.scss";

//* styles squircleBackButton justify-content:center, align-items:center
.squircleBackButton {
  @include flex(center, center);
  width: fit-content;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  font-size: 25px;
  cursor: pointer;
  box-shadow: 0px 1px 1px #00000029;

  &:hover {
    background-color: #e7e9ec;
  }
}

//* responsive SquircleBackButton for iPhone 12 Pro
@media (max-width: 391px) {
  .squircleBackButton {
    font-size: 10px;
  }
}