@import "../../../../../../index.scss";
// ditlindje tab card
.ditlindjet-container {
	width: 100%;
	padding-left: 20px;
	padding-right: 15px;
	overflow: auto !important;
	height: 400px;
	.ditlindjet-header {
		background: #f1f7fb;
		width: 100%;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		margin-bottom: 15px;
		font-size: large;
		font-weight: 500;
	}
	.ditlindjet-card-content {
		display: flex;
		align-items: center;
		.ditlindjet-card-info {
			display: flex;
			margin-left: 5px;
			font-size: medium;
		}
		.bold {
			font-size: medium;
			font-weight: 500;
			margin-left: 5px;
		}
	}
}

.line {
	border-right: 1px solid black;
	height: 400px;
	margin-left: -6px;
	margin-right: 15px !important;
}

.flex-logo-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ditlindjet-card-content {
	margin-bottom: 5px;
}
.calendar-col{
.calendar-container {
	margin: 0px 20px 20px 20px;
	height: 405px;
	border-radius: 10px;
	background: #f1f7fb;
	label.ant-radio-button-wrapper {
		background: #f1f7fb !important;
	}
	.ant-radio-button-wrapper-checked:not(
			[class*=" ant-radio-button-wrapper-disabled"]
		).ant-radio-button-wrapper:first-child {
		border-right-color: #1d3445 !important;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
		border-color: #1d3445 !important;
		color: #1d3445 !important;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
		background-color: #1d3445;
	}

	label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
		border-color: #1d3445 !important;
		color: #1d3445 !important;
	}
	.ant-picker-calendar {
		background: #f1f7fb;
		border-radius: 10px;
	}
	.ant-picker-calendar .ant-picker-panel {
		background: #f1f7fb;
		border-top: 1px dashed black;
		border-bottom: 1px dashed black;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: none !important;
		background: none !important;
	}
	span.ant-select-arrow {
		display: none;
	}
	.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
		border: none;
	}
	label.ant-radio-button-wrapper {
		margin-right: 5px;
	}
	.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0 3px;
	}
	.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
		.ant-select-selection-item,
	.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
		.ant-select-selection-placeholder {
		padding: 0px;
		font-size: MEDIUM;
		font-weight: 500;
	}
	.event-calendar.calendar-tooltip {
		@include flex(center, center);
		color: #fff !important;
		width: 35px;
		height: 30px;
		// margin-left: 5px;
		border-radius: 5px;
		z-index: 4;
		pointer-events: visible;
	}
	.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
	.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
		color: rgb(26, 24, 24);
		background: none;
	}

	.custom-date-wrapper {
		width: 100%;
		height: 100%;
		@include flex(center, center);
	}
}
}
.ant-tooltip.calendar-tooltip.ant-tooltip-placement-top {
	border-radius: 10px !important;
	.ant-tooltip-content {
		border-radius: 10px !important;
		background: #263238 !important;
	}
	.ant-tooltip-inner {
		border-radius: 10px !important;
		background: #263238 !important;
	}
	.toptil-calendar-content {
		@include flex(flex-start, flex-start, column);
	}
}

.calendar-footer {
	display: flex;
	align-items: center;
	height: 80px;
	width: auto;
	overflow: auto;
}

.card-calendar-foter {
	img {
		border-radius: 5px;
	}
	margin-right: 10px;
	.data {
		justify-content: center;
		align-items: center;
		display: flex;
		color: #fff;
		margin-top: 5px;
		border-radius: 5px;
	}
}

.footer-card {
	display: flex;
	width: 100%;
	align-items: center;
	margin-left: 20px;
	margin-right: 20px;
}

.ant-row.ditlindje-row {
	.calendar-container {
		.ant-picker-content td,
		.ant-picker-content th {
			pointer-events: none !important;
		}
	}
}
