@import "../../../../index.scss";

$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
.name-card {
  // width: 200px;
  // height: 225px;
  .ant-card-head-title {
    margin-left: 23px;
    height: 45px;
    color: #ffffff;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    font-size: 18px;
    padding: 10px;
  }
  .ant-card-head {
    height: 45px;
    background-color: $card_blue;
    border-radius: 10px 10px 0 0 !important;
  }
  .act {
    color: $card_blue;
    margin: 1px;
    padding: 1px;
    font-size: 16px;
  }
}

.activity {
  color: white !important;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
}
#active {
  text-align: center;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  width: 100%;
  height: 30px;
  background-color: #1da193;
  border-radius: 5px;
  padding-top: 2px;
  margin-bottom: 5px;
}
.changes {
  background-color: $checkbox_color;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 3px;
  padding: 3px 10px;
}
.changes:hover {
  opacity: 0.7;
}

.show-doc {
  @include flex(center, flex-start, column);
  max-height: 115px;
  overflow-y: auto;
  width: 100%;
  margin-top: 20px;
}

.uploadedFiles {
  @include flex(center, center);
  flex-grow: 1;
}

.fileIcon {
  justify-self: flex-start;
  width: 30px;
}

.fileC {
  margin-bottom: 10px;
  background-color: #f7f8fa;
  border-radius: 5px;
  padding: 10px;
  animation: fadeInDown 1000ms both;
}

.filesName {
  @include text(16px, 700, 0, #1264a3);
  margin: 0 10px;
  cursor: pointer;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: #1f2a44;
  }
}

.fileAuthor {
  width: 100%;
  @include flex(center, space-around);
  color: rgba(0, 0, 0, 0.45);
}

.closeIcon {
  width: 12px;
  fill: #fff;
}

.closeIconC {
  background-color: #fe4c4a;
  justify-self: flex-end;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;
  @include flex(center, center);

  &:hover {
    background-color: #eb4345;
  }
}
.nameCard-buttons {
  display: flex;
  gap: 5px;
}
.ant-btn.cardDeleteButton {
  height: 30px;
  background-color: #fcc94a;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 3px;
  padding: 3px 10px;
  cursor: pointer;
}
.ant-btn.cardDeleteButton:hover {
  background-color: #fcc94a;
  color: #fff;
}
.ant-btn.cardDeleteButton:focus {
  background-color: #fcc94a;
  color: #fff;
}
button.ant-btn.ant-btn-default.disableActiveButton {
  height: 30;
  top: 3px;
  border-radius: 5px;
  border-color: #d9d9d9;
  background-color: #f5f5f5;
  color: rgb(0 0 0 / 25%);
}
.fireEmployeePopConfirm {
  top: 3px;
  height: 30px;
  background-color: #ea3943 !important;
  border-radius: 5px;
  color: #fff;
  border: none;
}
button.ant-btn.ant-btn-default.terminateEmployeeButton {
  height: 30px;
  top: 3px;
  border-radius: 5px;
  background-color: #ea3943;
  color: #fff;
}
button.ant-btn.ant-btn-default.disableTerminateButton {
  height: 30;
  top: 3px;
  border-radius: 5px;
  border-color: #d9d9d9;
  background-color: #f5f5f5;
  color: rgb(0 0 0 / 25%);
}

.inputReason {
  height: 150px;
  background-color: #f5f5f7;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.suspensionChair {
  width: 150px;
  height: 150px;
}

.image-empView-div {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  .empView-img {
    width: 200px;
    height: 200px;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 16%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media (max-width: 768px) {
  .nameCard-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    .changes {
      grid-column: 1/3;
    }
  }
  .image-empView-div {
    height: 200px;
  }
}

@media (max-width: 391px) {
  .ant-card.ant-card-bordered.name-card {
    min-height: 400px !important;
  }
  .fileC {
    width: 200px;
  }
}
