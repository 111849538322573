.weekly-monthly {
  // width: 100%;
  height: 225px;
  // background-color: red;
  justify-content: center;
  align-items: center;
  text-align: left;
  //   font-size: 26px;
  letter-spacing: 0px;
  color: #323338;
  display: flex;
  flex-direction: column;
  animation: appearSoftly 1s;
  .weekly-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 20px;

    .summary-calendar-icon {
      width: 42px;
      height: 46px;
    }

    .info-container {
      display: flex;
      flex-direction: column;
      margin-top: -5px;
      .info-actions {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        s h1 {
          font-size: large;
        }
        .monthly-btn {
          margin-left: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 20px;
          background: #f1f7fb 0% 0% no-repeat padding-box;
          border-radius: 2px;
          color: #323338;
          border: none;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &:hover {
            cursor: pointer;
            background: #1da193;
            color: #fff;
            ::after {
              span.muj {
                display: none !important;
              }
              margin-right: 65px;
              margin-left: 20px;
              content: "Mujore";
            }
          }
        }
      }

      .monthly-btn-javore {
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 20px;
        background: #f1f7fb 0% 0% no-repeat padding-box;
        border-radius: 2px;
        color: #323338;
        border: none;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:hover {
          cursor: pointer;
          background: #1da193;
          color: #fff;
          ::after {
            span.muj {
              display: none !important;
            }
            margin-right: 65px;
            margin-left: 20px;
            content: "Javore";
          }
        }
      }

      .weeks-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-left: 10px;
        justify-content: baseline;
        align-items: center;
        .small-circle {
          display: flex;
          width: 5px;
          height: 5px;
          background: #1da193 0% 0% no-repeat padding-box;
          opacity: 1;
          border-radius: 50%;
          justify-content: center;
          align-items: flex-end;
          margin-left: 3px;
        }
        .dashes {
          margin-bottom: 2px;
          margin-left: 3px;
          margin-right: 3px;
        }
        .circle {
          width: 7px;
          display: inline-block;
          height: 7px;
          background: #1da193 0% 0% no-repeat padding-box;
          opacity: 1;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-right: 3px;
        }
      }
    }
  }

  .weekly-picker {
    margin-top: 17px;
    width: 344px;
    height: 32px;

    .ant-picker-input > input {
      width: 300px;
      text-align: center;
    }
    .ant-picker {
      background: #f5f5f7;
      border: none;

      border-radius: 5px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .monthly-picker {
    margin-top: 17px;
    width: 344px;
    height: 32px;
    .ant-picker-input > input {
      width: 300px;
      text-align: center;
    }
    .ant-picker {
      background: #f5f5f7;
      border: none;
      border-radius: 5px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.calendar-pick {
  .ant-picker-week-panel {
    width: 344px;
  }
  .ant-picker-month-panel {
    width: 344px;
  }
  .ant-picker-week-panel-row-selected:hover td,
  .ant-picker-week-panel-row-selected td {
    background: #1da193;
  }

  td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background: #1da193;
    }
  }
}

.ant-picker-dropdown.calendar-pick.ant-picker-dropdown-placement-bottomLeft {
  width: 344px;
}
