$card_blue: #e7eef2;
@mixin actionButtons($color, $mleft) {
  width: 120px;
  height: 32px;
  background: $color;
  color: #fff;
  border-radius: 5px;
  margin-left: $mleft !important;
  border: none;
}
.add-leave-request-modal .ant-modal-content {
  border-radius: 20px 20px 10px 10px;
  // min-width: 552px;
  // width: fit-content;
  // height: 100%;
  // margin-right: 300px !important;
}

.add-leave-request-modal .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
  background-color: #e7eef2 !important;
  font-weight: bolder;
}
.add-leave-request-modal .ant-modal-title {
  color: #1d3445;
  font-weight: 600;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.add-leave-request-modal .ant-modal-body {
  padding: 24px 48px;
}
.add-leave-request-modal .ant-modal-close {
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.ant-modal.add-leave-request-modal {
  .ant-modal-header {
    height: 45px;
    align-items: center;
    display: flex;
  }
}
// .ant-modal.add-leave-request-modal {
// 	// width: 500px !important;
// }
// .req-content {
// 	// margin: 10px 100px;
// }
.modal-text {
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
}
.ant-modal-body {
  .title-container {
    display: flex;
    gap: 5px;
    width: max-content;
    margin: 15px auto;
    .request-title {
      background: #1d3445 0% 0% no-repeat padding-box;
      border-radius: 2px;
      color: white;
      text-align: center;
      font: normal normal normal 13px/18px "Open Sans";
    }
    // margin: 0px 10px;
    // justify-content: center;
    // justify-content: space-between;
    // padding: 10px 35px;
    // margin-top: 20px;
  }
}

.ant-modal.add-leave-request-modal {
  form.ant-form.ant-form-horizontal {
    width: 100% !important;
  }
  .ant-modal-footer {
    justify-content: space-between;
    display: flex;
    border: none;
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
    }
    .ant-form-item {
      margin: 0px;
      width: 100%;
    }
    button.ant-btn.accept {
      @include actionButtons(#1da193, 0);
      .disabled {
        @include actionButtons(#1da193, 0);
        opacity: 0.7;
      }
    }
    button.ant-btn.pending {
      @include actionButtons(#fcc94a, 0px);
      .disabled {
        opacity: 0.7;
      }
    }
    button.ant-btn.refuse {
      @include actionButtons(#ea3943, 0px);
    }
    button.ant-btn.delete-request {
      display: flex;
      align-items: center;
      justify-content: center;
      @include actionButtons(#7f7e7e, 0px);
      .del-btn-text {
        margin: 0px !important;
      }
      .del-icon {
        display: none;
      }
      &:hover {
        background: #ea3943;
        .del-btn-text {
          display: none;
        }
        .del-icon {
          display: block;
        }
      }
    }
  }
  form.ant-form.ant-form-horizontal.prano-from {
    display: flex;
    .ant-form-item-control-input-content {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 450px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.req-drawer {
    .ant-drawer-wrapper-body .ant-drawer-body {
      padding: 20px !important;
    }
  }
}

@media (max-width: 391px) {
  .add-leave-request-modal .ant-modal-content {
    margin-right: 200px !important;
  }
  .req-container {
    display: flex;
    flex-direction: column;
  }
  .add-leave-request-modal .ant-modal-content {
    border-radius: 20px 20px 10px 10px;
    width: 300px;
  }
  .ant-modal-wrap.request {
    width: 450px;
  }
  .modal-text {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
  }
  .start-date {
    margin-top: 20px;
    p {
      width: 203px !important;
      margin-left: 25px;
    }
  }
  .end-date {
    margin-top: 20px;
    p {
      width: 203px !important;
      margin-left: 25px;
    }
  }

  .request-description {
    // width: 250px !important;
    height: 100px !important;

    padding: 20px 38px;

    margin-bottom: 30px;
    margin-top: 65px !important;
  }
  .user-img {
    margin-left: 35px;
  }
  button.ant-btn.accept {
    width: 70px !important;
  }
  button.ant-btn.refuse {
    width: 70px !important;
  }
}
