@import "../../../../index.scss";

//* styles docFieldOption justify-content:center, and align-items:center,text font-size:16px, font-weight:500, letter-spacing:0, and text color,and text-overflow:ellipsis 
.docFieldOption {
  @include flex(center, center);
  .fieldOptionLabel {
    width: 350px;
    padding: 5px 10px;
    background-color: #fff;
    @include text(16px, 500, 0, #323338);
    @include textEllipsis();
    text-align: center;
    border-radius: 5px;
    &:hover {
      background-color: #e7e9ec;
    }
  }

  .fieldOptionButton {
    height: 100%;
    padding: 5px;
    width: 34px;
    fill: #fff;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }

  .closeIcon {
    width: 17px;
  }

  .added {
    background-color: #ff4d4f;

    &:hover {
      background-color: #eb4345;
    }
  }

  .notAdded {
    background-color: #71cf48;
    &:hover {
      background-color: #68c142;
    }
  }
}


//* responsive for iPhone 12 Pro
@media (max-width: 391px) {
  .docFieldOption .fieldOptionLabel {
    width: 200px;
  }
}