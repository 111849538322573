@import "../../../../index.scss";
.multi-generate-modal {
  width: max-content !important;
  max-width: calc(100dvw - 100px);
  .ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin-left: -12px;
    width: 200px;
  }
  .input-form {
    width: 345px;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;
    .ant-modal-body {
      background-color: white;
      // height: 800px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-left: 20px;
      .generate-modal-container {
        height: 100%;
        margin-top: 50px;
        .pickEmployeeStep {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
          .pickEmployee {
            flex: 1 1 0;
            .selectionButtons {
              display: flex;
              justify-content: center;
              gap: 10px;
            }
            .employeePicker {
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin: 30px 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 7px;
              .ant-select:not(.ant-select-customize-input)
                .ant-select-selector {
                position: relative;
                background-color: #fff;
                border: none;
                background-color: #f1f7fb;
                border: none;
              }
            }
          }
          .pickedEmployeesList {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .pickedContainer {
              display: flex;
              gap: 5px;
              background-color: #f5f5f7;
              padding: 5px;
              .deletePicked {
                width: 28px;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                :hover {
                  cursor: pointer;
                }
              }
              .pickedTitle {
                @include flex(center, flex-start);
                @include text(14px, 600, 0, #323338);
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      .finished-gen {
        display: flex;
        gap: 20px;
      }
    }
  }
}

.select-approved-btn {
  top: 763px;
  left: 890px;
  // width: 140px;
  height: 32px;
  /* UI Properties */
  background: #1da193 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.pick-month {
  .ant-picker {
    background-color: #f1f7fb;
    border: none;
  }
}

.multi-generate-table {
  padding: 20px;
  width: 100%;
}

@media (max-width: 450px) {
  .multi-generate-modal {
    .ant-modal-content {
      .ant-modal-footer {
        // flex-direction: column;
        // height: max-content;
        // gap: 10px;
        // align-items: center;
      }
    }
  }
}

@keyframes animatePicked {
  0% {
    width: 100%;
  }
  100% {
    width: 105%;
  }
}
