.permissionsDocModal {
  width: 420px !important;
  .ant-modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f8f8fa;
    padding: 0 20px;
    height: 45px;
  }
  .ant-modal-body {
    height: 420px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .fileCard {
        display: flex;
        gap: 15px;
        width: 100%;
        padding: 10px;
        &:hover {
          background-color: #f7f8fa;
          border-radius: 5px;
          cursor: pointer;
        }
        .uploadedFile {
          display: flex;
          justify-content: space-evenly;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          .row {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
      }
    }
  }
}
