.edit-employee-wage {
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;
    .ant-modal-close {
      color: white;
      //   background-color: #1D3445;
      //   display: flex;
      //   justify-content: flex-end;
    }
  }

  .ant-modal-header {
    height: 45px;
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    color: white;
    .ant-modal-title {
      color: white;
      font-size: 18px;
      font-family: "Open Sans", sans-serif;
    }
  }

  .ant-modal-body {
    background-color: white;
    height: 850px;
    display: flex;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    .edit-wage-div {
      .warning-header {
        // display: flex;
        strong {
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
        }
      }
    }
  }

  .ant-modal-footer {
    // height: 82px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close-btn {
      width: 150px;
      height: 32px;
      background: #ea3943 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      text-decoration: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
    .start-btn {
      width: 150px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      text-decoration: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.edit-wage-div {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .warning-header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #ea3943;
    opacity: 1;
  }

  .edit-wage-header {
    height: 148px;
    background: #f1f7fb 0% 0% no-repeat padding-box;
    border-radius: 5px;

    .warning-header {
      .warning-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        margin-left: 20px;
        margin-top: 20px;
        width: 1212px;
        height: 32px;
        background: #fce8e8 0% 0% no-repeat padding-box;
        border: 1px solid #f5d4d4;
        border-radius: 5px;
      }
    }

    .edit-wage-row {
      display: flex;
      border-radius: 5px;
      justify-content: space-between;
      padding: 20px;
      .edit-wage-paymentType,
      .edit-wage-hourlyPay,
      .edit-wage-net,
      .edit-wage-gross {
        display: flex;
        flex-direction: column;
        width: 254px;
        // align-items: center;
        justify-content: space-between;

        label {
          margin-left: 5px;
          font: normal normal 600 16px/22px Open Sans;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          position: relative;
          background-color: white;
          border: none;
          border-radius: 5px;
        }
        .ant-input-number-input {
          background-color: white;
        }
        .ant-input-number {
          border: none;
          border-radius: 5px;
        }
      }
      .personal-paycheck {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }
}

.ant-popover.ant-popconfirm.popup-edit-wage.ant-popover-placement-left {
  padding-right: 25.313708500000004px;
}

.ant-popover.ant-popconfirm.popup-edit-wage.ant-popover-placement-left {
  width: 542px !important;
  height: 70px;
  .ant-popover-inner-content {
    height: 70px !important;
    display: flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .ant-popover-inner {
    border-radius: 10px !important;
  }
  .ant-popover-message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-popover-message-title {
    margin-right: 13px;
    font-weight: 500;
  }
  .ant-popover-buttons {
    height: 70px;
    margin: 0px;
    padding-left: 8.2px;
    button.ant-btn.ant-btn-sm {
      width: 50px;
      height: 70px;
      background: #ff4e00;
      border: none;
      color: #fff;
      border-radius: 0px;
      cursor: pointer;
    }
    button.ant-btn.ant-btn-primary.ant-btn-sm {
      width: 50px;
      height: 70px;
      border: none;
      background: #1da193;
      margin: 0px;
      color: #fff;
      border-radius: 0px 8px 8px 0px;
      cursor: pointer;
    }
  }
}
