.generalViewModal {
  .ant-modal-content {
    .ant-modal-body {
      height: 50dvh;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
      background-color: #f5f5f7;
      border-radius: 0 0 10px 10px;
      .closeModal {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        border: none;
        background: #ea3943;
        color: #fff;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 30px;
    gap: 0;
    .tableContainer {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
      width: 100%;
      .tableHeaders {
        width: calc(100% - 10px);
        display: flex;
        gap: 50px;
        span {
          flex: 1 1 0;
          text-align: center;
          background: #1d3445;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
      .tableContent {
        overflow: hidden;
        overflow-y: scroll;
        .ant-row {
          padding: 5px 0;
          &:not(:last-child) {
            border-bottom: 1px solid #ccc;
          }
          position: relative;
          display: flex;
          gap: 50px;
          flex: 1 1 0;
          .item {
            flex: 1 1 0;
            display: flex;
            align-items: center;
            font-size: medium;
            .ant-badge {
              margin: 0 10px 0 5px;
            }
          }
        }
      }
      .row {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 10px;
      }
    }
    .tableContent.scrollable {
      overflow: hidden;
      overflow-y: auto;
    }
    .paginationFooter {
      user-select: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 30px;
      width: 100%;
      div {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        height: 100%;
        .pagingButton {
          font-size: 20px;
          font-weight: 600;
          color: #1d3445;
          &:hover {
            cursor: pointer;
          }
        }
        .disabled {
          color: #919ba3;
          pointer-events: none;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .tableContainer {
    padding: 0 5px !important;
    gap: 20px !important;
  }
}
@media (max-width: 450px) {
}
