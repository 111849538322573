.drag-card-wrapper-blured {
  width: 385px;
  height: 315px;
  filter: blur(4px);
  /* UI Properties */

  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  opacity: 1;
  // overflow-y: scroll;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // margin-bottom: 10px;
  ::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    margin-top: 5px;
    top: 10px;
    cursor: pointer !important;
  }
  &:hover {
    filter: none;
  }
}

.drag-card-wrapper {
  height: calc(100% - 35px);
  width: 100%;

  // width: 400px;
  // height: 280px;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 1px 4px #00000029;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 1;
  // overflow-y: scroll;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // margin-bottom: 10px;
  ::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    margin-top: 5px;
    top: 10px;
    cursor: pointer !important;
  }
}

.drag-pop-over {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 5px 0;
  .action-label {
    justify-content: center;
    color: #323338;
    cursor: pointer;
    height: 25px;
    padding: 2px 0 0 10px;
    &:hover {
      background-color: #1d3445;
      color: white;
    }
  }
}

.antd-more-drag {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.drag-container {
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  height: 100%;

  // background: transparent !important;
  // animation: appearSoftly 1s;
  // background-color: #f1f7fb;

  background-image: url("../../../../assets/icons/flexbackground.svg");
  background-size: 100% 100%; /* Adjust width and height */
  background-position: center; /* Adjust position */
  background-color: #f1f7fb;
  // //Autumn Theme
  // opacity: 0.9;
  // height: 100%;
  // background-image: url("https://images.unsplash.com/photo-1535406148013-5806f2127f58?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  // // background-image: url("https://images.unsplash.com/photo-1542664483-9a6423c9bd73?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/25839/trees.svg");
    // background-image: url("https://images.unsplash.com/photo-1542664483-9a6423c9bd73?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    // background-image: url("https://images.unsplash.com/photo-1444492964720-877b17cdb041?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80");
    opacity: 1;
    z-index: -1; /* Place the overlay below the content */
  }
  // background-position: center;
  // background-repeat: no-repeat;
  // height: calc(100vh - 119px);
  // justify-content: center;
  // align-items: center;
  // display: flex;
  // end of autumn theme
  .drag-container-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
    width: 100%;
    height: 50px;
    // background: #e8e9ea;
    .ant-tooltip-arrow {
      right: 5px;
    }
    .save-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      // background: #1DA193;
      //Autumn Theme
      background: #1d3445;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      cursor: pointer;
    }
    .hide-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      // background: #1D3445;
      //Autumn Theme
      background: #1d3445;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      cursor: pointer;
    }
    .refresh-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      // background: #1DA193;
      //Autumn Theme
      background: #1d3445;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      cursor: pointer;
      svg {
        fill: white;
      }
    }
  }
}

.sortable-list-container {
  // display: flex;
  // flex-wrap: wrap;
  // padding-left: 10px;
  // background-color: #fff5d6;
  // margin-left: -18px;
  // overflow-y: scroll;
  // height: 700px;
  // white-space: nowrap;'
  gap: 10px;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

.sortable-item-style {
  float: left;
  height: fit-content;
  // margin-left: 10px;
  // margin-bottom: 10px;
  // padding-right: 8px;
  // background-color: red;
  animation: appearSoftly 1s;
  // width: calc(32% - 16px);
  .content {
    padding: 8px 12px;
    background-color: #f7f8fa;
    // height: 100%;
    background-color: white;
  }
  .content-style {
    display: flex;
    flex-direction: column;
    // min-width: 400px;
    height: 100%;
    width: 100%;
    min-height: 315px;
    // width: "auto",
    //   height: "225px",
    background-color: white;
    // text-align: "center";
    box-shadow: 0px 1px 4px 0px #00000029;

    //   border: "2px solid #F9A620",
    border-radius: 10px;
    padding: 0;
  }
}

.blured-card-wrapper {
  height: calc(100% - 38px);
  // width: 100%;
  // width: 400px;
  // height: 280px;
  // filter: blur(20px);
  /* UI Properties */
  // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  // background: inherit;
  // background-image: url("https://www.teahub.io/photos/full/59-595359_2483x1552-pure-white-wallpaper-wallpapersafari-white-grey-blurred.jpg");
  // box-shadow: 0px 5px 10px #00000029;
  border-radius: 10px;
  // opacity: 0.4;
  z-index: 1;

  // overflow-y: scroll;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // margin-bottom: 10px;
  .content {
    filter: blur(20px);
    padding: 0;
    margin: 0;
  }

  .switch-div {
    background-color: #1d3445;
    opacity: 1;
    border-radius: 25px;
    width: 100px;
    height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button.ant-switch {
    height: 1px;
    background-color: #198d02;
    width: 60px;
  }
  .ant-switch-handle {
    margin-top: -15px;
  }

  button.ant-switch {
    .ant-switch-handle::before {
      background: url(../../../../assets/icons/icon_locked.svg) no-repeat;
      width: 25px;
      height: 25px;
      margin-top: 4px;
      width: 19px;
      height: 19px;
      background-position: center;
      border-radius: 50%;
      z-index: 3;
      background-color: #ea3943 !important;
    }
  }

  path#Path_8110 {
    fill: black;
  }
  button.ant-switch.ant-switch-checked {
    .ant-switch-handle::before {
      background: url(../../../../assets/icons/icon_locked.svg) no-repeat;
      // width: 25px;
      // height: 25px;
      margin-top: 4px;
      width: 19px;
      height: 19px;
      background-position: center;
      border-radius: 50%;
      z-index: 3;
      background-color: #1da193 !important;
    }
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    margin-top: 5px;
    top: 10px;
    cursor: pointer !important;
  }
  &:hover {
    // filter: none;
    animation: appearSoftly 1;
  }
}

@keyframes appearSoftly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    filter: none;
  }
}

@media (max-width: 391px) {
  .sortable-item-style {
    width: 290px !important;
    .total-small-card {
      width: 280px !important;
    }
  }
  .drag-card-wrapper {
    width: 280px;
  }
  .birthday-card-kryefaqja {
    width: 280px !important;
    .anniversary {
      margin: 10px 10px !important;
      img {
        width: 35px;
        height: 35px;
      }
    }
    .anniversary span {
      font-size: 13px !important;
    }
  }
  // .sortable-item-style .content-style .employee-card {
  //   gap: 30px !important;
  // }
  .ant-row.leave-request-row {
    width: 90% !important;
    .ant-col.ant-col-10 {
      font-size: 12px;
    }
    .ant-col.ant-col-9 {
      font-size: 12px;
    }
  }
  .njoftime {
    width: 280px !important;
    padding: 0px;
  }
}
