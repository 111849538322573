.preferencat {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, -50%);
  animation: preferencat 1s;
  .ant-card-head {
    color: #f1f7fb;
    background-color: #1f2a44;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .ant-card-body {
    width: 800px;
    display: flex;
    justify-content: center;
    height: 700px;
    overflow: scroll;
    overflow-x: hidden;
  }

  .KartatEShfaqura {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
  }
  .textCards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 17rem;
    flex-direction: row;
  }
  .save-btn {
    position: absolute;
    bottom: 2%;
    right: 1.5%;
    .saveStorage {
      width: 125px;
      background: #1da193 0% 0% no-repeat padding-box;
      opacity: 1;
      color: #f1f7fb;
      border-radius: 10px;
    }
  }
  .customAntdTransfer .transferIcon {
    svg {
      fill: #323338 !important;
    }
  }
}

@keyframes preferencat {
  from {
    opacity: 0;
    filter: blur(2px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
}

@media (max-width: 450px) {
  .div-pref {
    width: 100% !important;
  }
  .preferencat {
    width: 100% !important;
    position: relative !important;
    top: 0% !important;
    left: 0% !important;
    transform: translate(0%, 0%) !important;
    padding: 10px;
    .ant-card.ant-card-bordered.KartatEShfaqura {
      width: 100% !important;
    }
  }
  .preferencat .ant-card-body {
    width: 100%;
    height: 100%;
  }
  .customAntdTransfer .transfersContainer {
    display: grid !important;
  }
  .preferencat .textCards {
    display: none !important;
  }
  .customAntdTransfer .transferIcon {
    height: 80px !important;
  }

  .customAntdTransfer {
    .source::before {
      content: "Kartat e shfaqura";
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .transferredKeys::before {
      content: "Kartat e fshehura";
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}
