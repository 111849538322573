.new-announcemenet-modal {
  // style antd modal

  .custom-modal-btn_cancel {
    width: 150px;
    height: 32px;
    color: #fff;
    cursor: pointer;
    background: #ea3943;
    border-radius: 5px;
    border: none;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    /* Gray 3 */
    &:hover {
      opacity: 0.8;
    }
    &:disabled {
      background: #d9d9d9;
      cursor: not-allowed;
    }
  }
  .custom-modal-btn_confirm {
    width: 150px;
    height: 32px;
    color: #fff;
    cursor: pointer;
    background: #1da193;
    border-radius: 5px;
    border: none;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    /* Gray 3 */
    &:hover {
      opacity: 0.8;
    }
    &:disabled {
      background: #d9d9d9;
      cursor: not-allowed;
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      // padding: 0;
      .ant-form-item {
        margin-bottom: 0;
      }
      .new-announcemenet-content {
        .new-announcemenet-content__item {
          // ant input
          .quill.note_input.quillTextEditor.modal-quill-edit
            .ql-editor.ql-blank {
            height: fit-content !important;
          }
          .quill.note_input.quillTextEditor.modal-quill-edit
            .ql-container.ql-snow {
            height: fit-content !important;
            max-height: 250px !important;
            overflow-y: auto !important;
          }
          .ant-input {
            border-radius: 5px;
            background: #f5f5f7;
            border: none;
          }
        }
        .new-announcemenet-merged-items {
          // background-color: red;
          display: flex;
          justify-content: space-between;
          .new-announcemenet-content__item {
            flex: 1;
            // antd select style
            .ant-select-selector {
              border-radius: 5px;
              background: #f5f5f7;
              border: none;
            }
            .ant-input {
              border-radius: 5px;
              background: #f5f5f7;
            }
          }
          .new-announcemenet-content__item2 {
            flex: 1;
            .ant-input {
              border-radius: 5px;
              background: #f5f5f7;
              border: none;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      border-radius: 0 0 10px 10px;
      background: white;
      padding: 10px 20px;
      border: none;
      // padding: 0;
      // .ant-btn {
      // 	border-radius: 0;
      // 	width: 100%;
      // 	height: 50px;
      // 	font-size: 20px;
      // 	font-weight: 600;
      // }
      .new-announcemenet-footer {
        display: flex;
        justify-content: space-between;
        // padding-right: 20px;
        gap: 20px;
      }
    }
  }
}
