.change-shift {
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;

    .ant-modal-body {
      background-color: white;
      height: 411px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
    }
  }
}

.edit-shift-wrapper {
  .warning-title {
    display: flex;
    justify-content: center;
    margin-left: -110px;
    width: 800px;
    margin-top: -10px;
    align-items: center;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #ea3943;
    .warn-icon {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .to-edit-fields {
    margin-top: 20px;
    display: flex;
    margin-left: -30px;
    flex-direction: row;
    align-items: center;
    .shift-field-start {
      display: flex;
      flex-direction: column;
      margin-left: 30px;

      label {
        margin-right: 10px;
        margin-left: 5px;
      }
      .ant-picker {
        background-color: #f1f7fb;
        border: none;
      }
    }
    .shift-field-end {
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      label {
        margin-right: 10px;
        margin-left: 5px;
      }
      .ant-picker {
        background-color: #f1f7fb;
        border: none;
      }
      .daytype-select {
        .ant-select-selector {
          width: 200px;
          background-color: #f1f7fb;
          border: none;
        }
      }
    }
  }
  .to-note {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-bottom: 20px;
    // justify-content: center;

    textarea.ant-input.to-note-textarea {
      margin-left: 0px;
      width: 630px;
      height: 81px;
      /* UI Properties */
      background-color: #f1f7fb;
      border-radius: 5px;
      border: none;
      opacity: 1;
    }
  }

  .hours-generation {
    display: flex;
    // align-items: center;
    margin-left: 0px;
    margin-top: 20px;
    flex-direction: column;
    strong {
      align-items: center;
    }
    .animated-hours {
      // margin-left: 10px;

      width: 708px;
      flex: 1;
      background-color: #f1f7fb;
      border-radius: 5px;
      height: 32px;
      .calculated-hours {
        background-color: #1da193 !important;
        // background: linear-gradient(90deg, hsla(175, 79%, 63%, 1) 0%, hsla(82, 96%, 57%, 1) 100%);
        border-radius: 5px;
        height: 32px;
        animation: chartAnimation 1.9s;
      }
    }
  }
  .status-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    strong {
      align-items: center;
    }
  }
}

@keyframes chartAnimation {
  from {
    width: 0;
  }
  to {
    width: 1;
  }
}

@media (max-width: 820px) {
  .employee-shifts {
    width: 96% !important;
  }
  .shift-approvals .more-shift-filters2 {
    width: 94% !important;
  }
}
@media (max-width: 450px) {
  .ant-modal.change-shift {
    .edit-shift-wrapper .warning-title {
      margin-left: 0px !important;
      width: 100% !important;
    }
    .edit-shift-wrapper .to-edit-fields {
      flex-wrap: wrap !important;
    }
    .ant-modal-body {
      height: 100% !important;
    }
    .edit-shift-wrapper .hours-generation .animated-hours {
      width: 100% !important;
    }
  }
}
