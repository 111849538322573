.paySub-container {
	// display: flex;
	// margin-left: 100px;
	// margin-top: 100px;
	background-color: rgb(255, 255, 255);
	flex-direction: column;
	.paySub-header {
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 30px;
		justify-content: space-between !important;
		.employee-info {
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 20px;

			.decorative-border {
				display: block;
				width: 100px;
				height: 3px;
				background: #f1cd8f;
				// margin: 20px auto;
			}
		}
		.earnings-title {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.decorative-border {
				display: block;
				width: 200px;
				height: 3px;
				background: #f1cd8f;
				// margin: 20px auto;
			}
		}
	}
	.earnings-period {
		display: flex;
		flex-direction: column;
		margin-right: 20px;
		margin-top: 20px;
		.decorative-border {
			display: block;
			width: 170px;
			height: 3px;
			background: #f1cd8f;
			// margin: 20px auto;
		}
	}
	.paySub-preview {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between !important;
		margin-top: 20px;

		.empl-Department {
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.decorative-border {
				display: block;
				width: 100px;
				height: 3px;
				background: #f1cd8f;
				// margin: 20px auto;
			}
		}
		.empl-wage-type {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			.decorative-border {
				display: block;
				width: 100px;
				height: 3px;
				background: #f1cd8f;
				// margin: 20px auto;
			}
		}
		.empl-hourly-pay {
			margin-right: 20px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			.decorative-border {
				display: block;
				width: 160px;
				height: 3px;
				background: #f1cd8f;
				// margin: 20px auto;
			}
		}
	}

	.paySub-calculations {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between !important;
		margin-top: 20px;

		.paySub-earnings {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			// margin-right: 20px;

			.decorative-border {
				display: block;
				width: 110px;
				height: 3px;
				background: #f1cd8f;
				// margin: 20px auto;
			}
		}

		.paySub-earnings-calc {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			.decorative-border {
				display: block;
				width: 100px;
				height: 3px;
				background: #f1cd8f;
				// margin: 20px auto;
			}
		}

		.paySub-earnings-amount {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			margin-right: 20px;

			.decorative-border {
				display: block;
				width: 100px;
				height: 3px;
				background: #f1cd8f;
				// margin: 20px auto;
			}
		}
	}
}

.shift-approvals {
	.payStub-print {
		visibility: collapse !important;
	}
}

.payStub-print {
	width: 100%;
	// height: 792px;
	// background-color: yellow;
	padding: 30px;
	.payStub-header {
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 30px;
		// justify-content: space-between !important;
		.employee-info {
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 20px;

			.decorative-border {
				display: block;
				width: 100px;
				height: 3px;
				background: #f1cd8f;
				// margin: 20px auto;
			}
		}
	}
	.payStub-header-divider {
		display: block;
		width: 100%; /*or whatever width you want the effect of <hr>*/
		border-top: 5px solid #323338;
		border-radius: 10px;
	}
	.payStub-Info {
		margin-top: 30px;
		// width: 512px;
		// height: 90px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.payStub-info__employee {
		}
		.payStub-info__timely {
			width: 217px;
			// height: 90px;
			background-color: white;
			.employeer-info-label-double {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
			.employeer-info-label {
				display: flex;
			}
		}
	}
	.payStub-table-hourly-pay {
		margin-top: 30px;
	}
	.payStub-footer {
		margin-top: 50px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		// background-color: red;

		.payStub-footer-memo {
			// margin-top: 30px;
			padding: 30px;
			// width: 269px;
			// height: 93px;
			.payStub-footer-memo__content {
				// background-color: red;
			}
		}
		.payStub-footer-table {
			.payStub-footer-amount {
				margin-top: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: #707070;
				color: #ffffff;
				padding: 0px 10px 0px 10px;
			}
		}
	}
}

.payStub-table {
	table {
		font-family: arial, sans-serif;
		border-collapse: collapse;
		border: none;
		width: 100%;
	}

	td,
	th {
		// border: 1px solid #dddddd;
		// text-align: left;
		padding: 5px;
	}
	th {
		background-color: white;
	}
	tr {
		background-color: #70707017;
		// text-align: right;
	}

	tr:nth-child(even) {
		background-color: #7070703d;
	}
}

.payStub-final-table {
	table {
		font-family: arial, sans-serif;
		border-collapse: collapse;
		width: 100%;
		border-radius: 10px;
		border: 2px solid #323338;
	}

	td,
	th {
		// border: 1px solid #dddddd;
		// text-align: center;
		padding: 5px;
	}
	th {
		background-color: #323338;
		color: white;
	}
	tr {
		background-color: #70707017;
	}

	tr:nth-child(even) {
		background-color: #7070703d;
	}
}
.payStub-print {
	box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.53);
	border-radius: 10px;
}

@media print {
	.payStub-print {
		// display: flex;
		// flex-direction: column;
		width: 100%;
		box-shadow: none;
		.unprintable-warning {
			display: none;
		}
		.timesheet-table {
			page-break-before: auto;
		}
	}
}
