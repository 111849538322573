// punojsit page CARDS PUNOJSTI STYLE
// .punonjesit-cards {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   // width: auto;
//   .ant-card.ant-card-bordered {
//     border-radius: 10px;
//     box-shadow: 1px 1px 1px 1px #00000029;
//     justify-content: center;
//     align-items: center;
//     width: 1190px;
//   }
//   .ant-card.ant-card-bordered {
//     border-radius: 10px;
//     box-shadow: 1px 1px 1px 1px #00000029;
//     justify-content: center;
//     align-items: center;
//     display: flex;
//   }
//   .ant-progress-inner {
//     width: 200px !important;
//     height: 200px !important;
//     margin-top: 40px;
//   }
//   span.anticon.anticon-idcard svg {
//     margin-left: 100px;
//     width: 6rem;
//     height: 6rem;
//     color: #239281;
//   }
//   button.ant-btn {
//     background: #1da193;
//     border-radius: 5px;
//     margin-left: 90px;
//     color: #fff;
//   }
//   .ant-progress-circle .ant-progress-text {
//     font-size: 19px;
//   }
// }

//ICONA per cards
svg#Layer_1 {
  fill: transparent;
}
.add-icon {
  margin-left: 110px;
  path#Path_6861_00000147205192370109036890000003473032641409739905_ {
    fill: #1da193;
  }
}

// .punonjesit {
//   justify-content: center;
//   align-items: center;
//   width: 1190px;
// }
// .ag-root-wrapper.ag-layout-normal.ag-ltr {
// 	width: 99% !important;
// }
// hader i ag grid
.ag-grid-punojsit {
  background: #ffffff;
  width: 1190px;
  height: 572px;
  margin-left: 25px;
  box-shadow: 1px 1px 2px 1px #00000029;
  border-radius: 15px;
  margin-top: 0px !important;
  .ag-grid-header {
    padding: 10px;
    display: flex;
    background: transparent;
    input.ant-input {
      background: transparent !important;
      margin-left: 10px;
      border: none;
    }
    input.ant-input::placeholder {
      color: black !important;
    }
    .icon-search-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      path#Path_6858_00000086666139294581268680000013212754614146113725_ {
        fill: #1d3445;
      }
    }
  }
  button.ant-btn.ant-btn-icon-only.ant-input-search-button {
    border: none;
    color: black !important;
    background: #f1f7fb;
  }
  button.ant-btn.pastro-btn {
    background: #ea3943;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    width: 80px;
    border-radius: 5px;
    margin-right: 20px;
    margin-left: 20px;
    color: #fff;
    cursor: pointer;
  }

  .header-search {
    background: #f1f7fb;
    display: flex;
    margin-left: 20px;
    border-radius: 5px;
    height: 32px;
  }
  // dropdwon departamenti
  .punojsit-dropdown {
    margin-left: 3px !important;
    border: none;
    .ant-select-selector {
      border-radius: 5px !important;
      background: transparent !important;
    }
    span.ant-select-arrow {
      color: #323338;
    }
  }
  .punojsit-div {
    display: flex;
    background: #f1f7fb;
    height: 32px;
    margin-left: 3px;
    border-radius: 5px;
    padding-left: 10px;
    .icon-punojsit {
      margin-top: 5px;
      path#Path_6861_00000147205192370109036890000003473032641409739905_ {
        fill: #323338;
      }
    }
    input.ant-input::placeholder {
      color: black !important;
    }
  }
  button.ant-btn.ant-btn-icon-only.ant-input-search-button {
    border: none;
    color: black !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
}

// style per iconat e header te ag grid
.header-icons {
  margin-left: 3px;
  display: flex;
  .paygrid-btn {
    // width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin-right: 10px;
    padding-right: 10px;
    padding-left: 10px;
    background: #1da193 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: white;
    text-decoration: none;
    border: none;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
}

.header-icons svg {
  background: #f1f7fb;
  width: 31px;
  height: 31px;
  border-radius: 5px;
  fill: #323338;
  padding: 7px;
  margin-right: 20px;
  cursor: pointer;
}

.header-icons {
  svg#Layer_1 {
    fill: #fff;
  }
  path#Path_7431_00000060022719262200020110000009623688766172120195_ {
    fill: #323338;
  }
  path#Path_7428_00000028307989207194211430000003663479962056332434_ {
    fill: #323338;
  }
  path#Path_6546_00000109745927328506804000000014354080646234951053_ {
    fill: #323338;
  }
  path#Path_7427_00000027570199658901481330000001697806187456729770_ {
    fill: #323338;
  }

  path#Path_7429_00000029032457934157017360000010140754146058612150_ {
    fill: #323338;
  }
}

// style per iconat e header te ag grid
.header-icons {
  margin-left: 3px;
  display: flex;
}

.header-icons {
  svg#Layer_1 {
    fill: #fff;
  }
  path#Path_7431_00000060022719262200020110000009623688766172120195_ {
    fill: #323338;
  }
  path#Path_7428_00000028307989207194211430000003663479962056332434_ {
    fill: #323338;
  }
  path#Path_6546_00000109745927328506804000000014354080646234951053_ {
    fill: #323338;
  }
  path#Path_7427_00000027570199658901481330000001697806187456729770_ {
    fill: #323338;
  }

  path#Path_7429_00000029032457934157017360000010140754146058612150_ {
    fill: #323338;
  }
  path#Path_7433 {
    fill: #323338;
  }
}

// dropdown icona rraket
// .ant-dropdown.ant-dropdown-placement-bottomCenter.ant-dropdown {
//   width: 250px;
// }

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  height: 32px !important;
  border-bottom: 2px solid #efefef;
  padding-top: 19px;
  padding-bottom: 19px;
}
.ant-dropdown-menu {
  background: #f5f5f7;
  padding: 0px 0px;
}

.ant-dropdown.ant-dropdown-placement-bottomCenter
  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
  background: #cdd3df;
}
// .ant-dropdown.ant-dropdown-placement-bottomCenter
//   li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
//   height: 32px !important;
//   border-bottom: 2px solid #efefef;
//   padding-top: 19px;
//   padding-bottom: 19px;
// }

// .ant-dropdown.ant-dropdown-placement-bottomCenter .ant-dropdown-menu {
//   background: #f5f5f7;
//   padding: 0px 0px;
// }

// ag grid  punojsit
.ag-grid-punojsit .ag-theme-alpine .ag-header {
  background: #1d3445 !important;
  border: none;
}

.ag-grid-punojsit .ag-theme-alpine .ag-root-wrapper {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-header-row.ag-header-row-column {
  color: #ffffff !important;
  font-weight: 400;
  font-size: medium;
}

.ag-grid-punojsit .ag-theme-alpine .ag-header-cell-resize {
  // display: none;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
  background: #f5f5f7 !important;
  border: none !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
  border: none !important;
}

//hover cells ag grid
.ag-grid-punojsit
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit
  .ag-row-even.ag-row-position-absolute.ag-after-created.ag-row-focus {
  background: #fff;
  border: none;
}
.ag-grid-punojsit
  .ag-row-odd.ag-row-position-absolute.ag-after-created.ag-row-focus {
  background: #f6f6f6;
  border: none;
}
.ag-grid-punojsit
  .ag-row-even.ag-row-position-absolute.ag-after-created.ag-row-focus:hover {
  background: #e1e1e6;
}
.ag-grid-punojsit
  .ag-row-odd.ag-row-position-absolute.ag-after-created.ag-row-focus:hover {
  background: #e1e1e6;
}
.ag-grid-punojsit
  .ag-theme-alpine
  .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  background: #1d3445;
}
.ag-grid-punojsit .ag-row-even.ag-after-created.ag-row-selected:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-focus.ag-row-selected:hover {
  background: #e1e1e6 !important;
}
.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-selected.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-checkbox .ag-input-wrapper {
  background: #1d3445;
  width: 20px;
  height: 18px;
}
.ag-grid-punojsit .ag-theme-alpine .ag-checkbox-input-wrapper::after {
  color: #1d3445 !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-checkbox-input-wrapper.ag-checked::after {
  color: #1d3445 !important;
  background: #ffffff !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-checkbox-input-wrapper {
  font-size: 20px !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-row-selected {
  background: #cdd3df !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-focus.ag-row-selected {
  background: #cdd3df !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-selected.ag-row-no-focus {
  background: #cdd3df !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-paging-panel {
  border: none !important;
}

.ag-grid-punojsit .ag-theme-alpine a {
  color: #323338;
}

.ag-grid-punojsit
  .ag-theme-alpine
  span.ag-header-icon.ag-header-cell-menu-button {
  color: #fff !important;
}
.ag-grid-punojsit .ag-theme-alpine span.ag-icon.ag-icon-asc {
  color: #fff !important;
}
.ag-grid-punojsit .ag-theme-alpine span.ag-icon.ag-icon-desc {
  color: #fff !important;
}

@media (max-width: 450px) {
  .header-icons {
    gap: 10px;
    width: 100%;
    overflow-x: auto;
    height: 100%;
    overflow-y: hidden;
    button {
      height: 100% !important;
      width: 100px !important;
      text-align: center;
      margin: 0 !important;
    }
  }
}
