@import "../../../../index.scss";

.configElementContainer {
  background-color: #eceff4;
  height: 36px;
  min-width: 280px;
  width: 100%;
  display: flex;
  margin-right: 10px;
  opacity: 1;
  margin-bottom: 5px;
  cursor: pointer;

  .bluContainer {
    width: 8px;
    background-color: #0073ea;
    transition: width 0.5s;
    .navyBlueContainer {
      margin: 9px;
      width: 18px;
      height: 18px;
      background-color: #0060b9;
      overflow: hidden; //* Hide the element content, while height = 0 
      opacity: 0;
      transition: opacity 300ms 0ms;
    }
    .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border: 2px solid #0060b9;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
    .ant-checkbox-checked::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid white;
      border-radius: 2px;
      visibility: hidden;
      -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
      animation: antCheckboxEffect 0.36s ease-in-out;
      -webkit-animation-fill-mode: backwards;
      animation-fill-mode: backwards;
      content: "";
    }
    .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
    .ant-checkbox-inner {
      position: relative;
      top: -3px;
      left: 0;
      display: block;
      width: 18px;
      height: 18px;
      direction: ltr;
      background-color: white;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      border-collapse: separate;
      transition: all 0.3s;
    }
  }
  .configElementTitle {
    @include flex(center, unset);
    @include text(20px, 400, 0, #000);
    text-align: left;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    margin-left: 7px;
  }

  .checkboxTitle {
    max-width: 230px;
    @include textEllipsis();
  }
}
.configElementContainer:hover {
  background-color: #e6e9ef;
  .navyBlueContainer {
    opacity: 1;
  }
  .bluContainer {
    width: 36px;
    background-color: #0073ea;
  }
}

.disabledConfigElementContainer {
  filter: brightness(0.8);
  pointer-events: none;
}
