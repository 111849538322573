.single-notif {
  background: #dce4e9 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-radius: 5px;
  // border-bottom: 1px solid #bebaba;
  animation: appearSoftly 1s;
  height: auto;
  padding: 3px;
  width: 100%;
  color: #1d3445;
  // opacity: 0.45;

  &:hover {
    background-color: #efefef !important;
    cursor: pointer;
    // -webkit-box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.5);
    // box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.5);
    // -webkit-transform: scale(1.005);
    color: #1d3445;
  }

  &.out {
    animation: disappear 1.6s;
    background-color: #1d3445 !important;
    color: #1d3445 !important;
  }
  .notif-content {
    flex: 6.5;
    font: normal normal normal 15px/20px Open Sans;
    display: flex;
    align-items: center;
    .notif-icon {
      margin-left: 10px;
      font-size: 25px;
      .white {
        svg {
          fill: white;
        }
      }
    }
    .important {
      margin-left: 10px;
      font-size: 25px;
      color: #fcc94a;
    }

    .notif-body {
      margin-left: 10px;
      max-width: 1200px;
      // min-width: 300px;
      flex-wrap: wrap;
      font-size: 14px;
      font-weight: 500;
      padding-top: 5px;
    }
    .relative-time {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .notif-actions {
    flex: 1;
    min-width: 130px;
    display: flex;
    margin-left: 20px;
    .lexo-me-shum-icon {
      width: 32px;
      height: 32px;
      background-color: #1da193;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      path#read_more_FILL1_wght400_GRAD0_opsz48 {
        fill: #fff;
      }
    }
    .lexo-me-shum-icon:hover {
      opacity: 0.7;
    }
    .show-more-icon {
      font-size: 24px;
      margin-right: 10px;
      margin-left: 10px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      border-radius: 20%;
      background-color: #1da193;
      color: white;
      // padding-right: 4px;
      // padding-left: 4px;
      border-radius: 5px;
      &:hover {
        background-color: #1da193;
      }
    }
    .delete-icon {
      color: white;
      width: 32px;
      height: 32px;
      justify-content: center;
      display: flex;
      align-items: center;
      background-color: #ea3943;
      border-radius: 5px;
    }
  }
}

.single-notif .notif-body .ant-typography {
  color: inherit;
}
.single-notif.read {
  background-color: #f1f1f5;
  color: #1d3445;
  &:hover {
    // background-color: #dfdfdf;
    cursor: pointer;
  }
}

span.notif-icon.important {
  color: #fcc94a;
  path#Path_8272 {
    fill: #fcc94a;
  }
  path#Path_8273 {
    fill: #fcc94a;
  }
  path#Path_8274 {
    fill: #fcc94a;
  }
  path#Path_8275 {
    fill: #fcc94a;
  }
  path#Path_8276 {
    fill: #fcc94a;
  }
}

.show-more-dropdown {
  background-color: #bebaba;
  width: 180px !important;
  border-radius: 7px !important;

  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
    background: #1d3445 !important;
    color: #fff;
  }
}

.single-notif .notif-body .ant-typography {
  color: inherit;
}

@keyframes appearSoftly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(1700px);
  }
}
