.konfigurimet {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 10px;
  overflow: hidden;
  // width: 1500px;
}

@media (max-width: 1100px) {
  .header-app {
    display: grid;
  }
}
