.generate-page {
  display: flex;
  flex-direction: column;
  gap: 15px;
  .report-wrapper {
    .card-report-header {
      width: 100%;
    }
  }
  .reports-main {
    .report-options {
      font: normal normal normal 12px/17px Open Sans;
      .time {
        margin-left: 15px;
        background: #1da193;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 2px;
      }
    }
    .ant-collapse.reports-collapse {
      border-radius: 10px;
      border: none;
      box-shadow: 0px 1px 4px #00000029;
      .ant-collapse-header {
        background: #1d3445 !important;
        height: 45px;
        color: #fff;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
      }
      .header-description {
        display: flex;
        align-items: center;
      }
      .ant-collapse-content.ant-collapse-content-active {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background: #fff;
        border: none !important;
      }
      .ant-collapse-item.ant-collapse-item-active {
        border-bottom: 0px !important;
      }
      .ant-collapse-content-box {
        padding: 0px;
        ul {
          border: none !important;
          margin: 0px;
          height: 44px;
          display: flex;
          align-items: center;
          font: normal normal normal 16px/16px Open Sans;
          cursor: pointer;
        }
        ul:hover {
          background: #e6eff6;
          //    border-radius: 5px;
        }
      }
    }
  }
}
@media (max-width: 820px) {
  .div-reports-padding {
    padding: 10px !important;
  }
  ul.record-ul-in-reports {
    display: flex;
    flex-wrap: wrap;
    height: 100% !important;
    padding: 10px !important;
    .div-inside-ul {
      display: block !important;
      padding-bottom: 5px;
      border-bottom: 1px solid #1d3445 !important;
      span.report-title {
        font-size: 14px !important;
        font-weight: 600 !important;
      }
    }
  }
  .reports-main {
    overflow: auto !important;
  }
  .generate-page {
    .generate-page-header {
      .ant-card.card-report-header .content-header-card {
        flex-wrap: wrap;
        gap: 10px;
      }
      .ant-card.card-report-header .content-header-card .time-period {
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
  .report-wrapper {
    // padding-left: 15px;
    // padding-right: 15px;
    height: 100% !important;
    .report {
      min-width: 100% !important;
      height: 100% !important;
      .report-tools {
        flex-wrap: wrap;
        justify-content: flex-start !important;
      }
    }
  }
  .report-wrapper .report .report-tools .leftDiv {
    button.ant-btn.ant-btn-default.write-note {
      margin-right: 5px !important;
      margin-left: 5px !important;
      width: 140px !important;
    }
  }
  .report-wrapper .report .report-tools .rightDiv {
    padding: 0px !important;
    // margin-left: 20PX;
    justify-content: center;
    display: flex;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .div-reports-padding {
    padding: 10px !important;
  }
  ul.record-ul-in-reports {
    display: flex;
    flex-wrap: wrap;
    height: 100% !important;
    padding: 10px !important;
    .div-inside-ul {
      span.report-title {
        font-size: 14px !important;
        font-weight: 600 !important;
      }
      border-bottom: 1px solid #1d3445 !important;
      display: block !important;
      padding-bottom: 3px;
      .report-options {
        display: grid !important;
        gap: 5px;
      }
    }
  }
  .generate-page {
    .generate-page-header {
      .content-header-card {
        .time-period {
          width: 250px !important;
          margin-left: 0px !important;
          .ant-picker.ant-picker-range.ant-picker-borderless.range-pick-report {
            width: 250px !important;
          }
        }
      }
    }
  }
  .report-wrapper {
    width: 95% !important;
    height: 100%;
    padding-left: 10px !important;
  }
  .ant-card.card-report-header {
    height: 100% !important;
  }
  .report-wrapper
    .report
    .report-tools
    .leftDiv
    button.ant-btn.ant-btn-default.write-note {
    width: 110px !important;
    padding: 0px !important;
    font-size: 12px !important;
    margin-bottom: 10px;
  }
}

@media (max-width: 450px) {
  .generate-page {
    .generate-page-header {
      .content-header-card {
        .time-period {
          width: 230px !important;
          margin-left: 0px !important;
          .ant-picker.ant-picker-range.ant-picker-borderless.range-pick-report {
            width: 220px !important;
          }
        }
      }
    }
  }
}
