$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
// icont
path#Path_6858_00000086666139294581268680000013212754614146113725_ {
  fill: #fff;
}
path#Path_7414_00000160884763990628686030000012834844531731564702_ {
  fill: #fff;
}
path#Path_7413_00000169551406889492978480000003617827709912210329_ {
  fill: #fff;
}
path#Path_6856_00000139267737419035309210000012936793702009836202_ {
  fill: #fff;
}
path#Path_7411_00000052097550595528871170000001066866502481234860_ {
  fill: #fff;
}
path#Path_7412_00000127017924034809551100000006747330487866600615_ {
  fill: #fff;
}
path#Path_6861_00000147205192370109036890000003473032641409739905_ {
  fill: #fff;
}
path#Path_6860_00000023978195008706719270000003653334959357513148_ {
  fill: #fff;
}
path#Path_6863_00000143612096821327462770000016226962901781674155_ {
  fill: #fff;
}
path#Path_6864_00000120529441780123305430000011664436185562788282_ {
  fill: #fff;
}
path#Path_6868_00000085935721844432230080000004082495129742223240_ {
  fill: #fff;
}
path#Path_6866_00000180330936760804433140000001257146959290337971_ {
  fill: #fff;
}
path#Path_7433 {
  fill: #fff;
}
path#Path_7623 {
  fill: #fff;
}
path#Path_6864_00000018929140721829161270000002019101969291326904_ {
  fill: #fff;
}
path#Path_7618_00000008129437919190415880000001438429491828514181_ {
  fill: #323338;
}

.container {
  display: flex;
  grid-gap: 20px;
  text-align: left;
  padding-bottom: 23px;
  flex-wrap: nowrap;
  width: 100%;
}

.ant-card {
  border-radius: 10px !important;
  box-shadow: 0px 1px 4px #00000029;
}
.info-card .ant-card-head {
  background-color: $card_blue !important;
  border-radius: 10px 10px 0 0 !important;
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 18px;
  .ant-card-head-wrapper {
    height: 45px;
  }
}
.info-card .ant-card-body {
  padding: 15px 20px;
  height: 422px;
  overflow-y: auto;
  overflow-x: hidden;
  .ant-form-item {
    margin-bottom: 12px;
  }
}

/* Form input fields */
.ant-form-item-control-input-content .ant-input {
  border: none;
  background-color: $input_background;
  border-radius: 5px;
}
.wide-div .ant-form-item-control-input-content .ant-input {
  width: 50%;
  border: none;
  background-color: $input_background;
  border-radius: 5px;
}
.ant-form-item-has-error .ant-form-item-control-input-content .ant-input {
  border-color: 2px solid red !important;
  background-color: $input_background !important;
}
.container .ant-form-item {
  margin-bottom: 12px !important;
}
/* Icon styles */
.icon-button {
  button {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  button:hover {
    opacity: 0.7;
  }
  #close {
    background-color: #ea3943;
    :hover {
      opacity: 0.7;
    }
  }
  #tick {
    background-color: #1da193;
    :hover {
      opacity: 0.7;
    }
  }
}

.pen-icon {
  margin-top: 5px;
  width: 14px;
  pointer-events: none;
}

.action-icon {
  margin-top: 5px;
  width: 16px;
  pointer-events: none;
}

.personal-info {
  width: 330px;
}

//Pozicionon checkboxin te statusi, pa editim
.info-main-div {
  height: 310px !important;
}

//Per te gjitha datepicker
.my-picker.ant-picker {
  border: 1px solid white !important;
  background-color: $input_background;
  border-radius: 5px;
  width: 220px;
}

div#tabBody {
  justify-content: flex-start;
  margin-left: 20px;
}

.ant-row.employe-view-row {
  .react-tel-input .form-control {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .ant-tabs.ant-tabs-top.ant-tabs-mobile.punojsit-tab {
    .ant-tabs-nav-list {
      display: grid !important;
    }
  }
}

@media (max-width: 391px) {
  .icon-button button {
    margin-right: -5px;
  }
}
