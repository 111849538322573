.present-list-wrapper {
  height: 100%;
  margin-top: 0px;
}

@media (max-width: 450px) {
  .present-list-wrapper {
    height: fit-content;
  }
  li.ant-menu-item.ant-menu-item-active.sigurt {
    padding-left: 31px !important;
  }
  .timesheet-calculations {
    width: 99% !important;
  }
  .timesheet-wrapper {
    display: grid;
    gap: 10px;
    width: 100% !important;
    position: absolute;
  }
  .header-filter-buttons {
    flex-wrap: wrap !important;
  }
  .timesheet-wrapper .timesheet-statistics {
    width: -webkit-fill-available !important;
    height: 280px !important;
    margin: 0px !important;
  }
  .full-card {
    width: unset !important;
    // width: 90% !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 20px !important;
  }
  .timesheet-wrapper .timesheet-calculations .timesheet-progress-wrapper {
    height: 580px !important;
  }
  .progress-shift {
    display: grid !important;
    width: 100%;
    gap: 5px !important;
    border-bottom: 1px solid #1d3445;
    padding: 5px 0;
  }
  .progress-shift .shift-person {
    width: 100% !important;
    font: normal normal 600 14px/30px Open Sans !important;
  }
  .progress-shift .progress-shift-bar {
    width: 98% !important;
    margin-left: 2px;
  }
  .progress-shift .total-hours {
    font: normal normal 600 14px/30px Open Sans !important;
    margin-left: 2px;
  }
  .progress-shift .clock-out-ind {
    margin-left: 2px;
  }
  .ant-picker-dropdown.ant-picker-dropdown-range.ant-picker-dropdown-placement-bottomLeft {
    .ant-picker-panel-container .ant-picker-panels {
      flex-wrap: wrap !important;
      width: 100% !important;
      .ant-picker-date-panel {
        width: 100% !important;
        table.ant-picker-content {
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 391px) {
  .timesheet-wrapper .timesheet-statistics {
    width: 100% !important;
  }
  .date-buttons-container .inactive-filter {
    width: 60px;
  }
}
