.menyrat-pageses {
  width: 100%;
  height: 100%;
}

.ant-modal.paisjet-per-orarin-modal {
  button.ant-btn.ruaj-btn {
    background-color: #1da193;
    border-radius: 5px;
    border: none;
    width: 150px;
    height: 32px;
    color: #fff;
    text-align: center;
  }
  button.ant-btn.Mbyll-btn {
    background: #ea3943;
    color: #fff;
    border-radius: 5px;
    border: none;
    width: 150px;
    height: 32px;
    text-align: center;
  }
}

@media (max-width: 391px) {
  .ant-col.responsive-col-grid-pagesa {
    flex-wrap: wrap;
    gap: 6px;
  }
}
