.admin-dashboard {
  width: 100%;
  // background: url("../../../assets/icons/admin_background.svg") no-repeat !important;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.clock-in-dashboard-card {
  width: 100% !important;
  height: 100% !important;
}

.link-kryefaqja-card-send {
  cursor: pointer;
  color: #1d3445;
}
.link-kryefaqja-card-send:hover {
  // AUTUMN
  color: #1d3445 !important;
  // color: #40a9ff;
}
//Link te numri i punonjesve ne total, me kohe te plote, kohe t e pjesshme

// @media (max-width: 450px) {
//   .admin-dashboard .blured-card-wrapper {
//     width: 400px !important;
//   }
// }

// @media only screen and (min-width: 745px) and (max-width: 851px) {
//   .admin-dashboard .total-small-card {
//     width: 100% !important;
//   }
//   .admin-dashboard .birthday-card-kryefaqja {
//     width: 100% !important;
//   }
//   .admin-dashboard .wrap {
//     width: 100% !important;
//     button.ant-btn.ant-btn-default.add-request {
//       width: 94%;
//     }
//   }
//   .admin-dashboard .blured-card-wrapper {
//     width: 100% !important;
//   }
//   .admin-dashboard .clock-in-dashboard-card {
//     width: 100% !important;
//   }
//   .admin-dashboard .clock-in-dashboard-card {
//     width: 100% !important;
//   }
//   .admin-dashboard .no-clockin-container {
//     width: 100% !important;
//   }
//   .admin-dashboard .active-users-wrapper {
//     width: 100% !important;
//   }
//   .admin-dashboard .statistikat-pagat-dashboard {
//     width: 100% !important;
//   }
//   .admin-dashboard .wage-history-dashboard {
//     width: 100% !important;
//   }
//   .admin-dashboard .njoftime {
//     width: 100% !important;
//     padding: 0px !important;
//   }
//   .ant-row.row-messages-njoftime {
//     display: flex !important;
//   }
//   .lamerimet-tab {
//     .ant-row.lajmerime-post-row {
//       .ant-col {
//         max-width: 100% !important;
//       }
//     }
//     .ant-row.lajmerime-post-row .permbajtja-lajmerimit {
//       width: 100% !important;
//     }
//     .notification-post-container {
//       height: 485px !important;
//     }
//     .ant-row.row-messages-njoftime {
//       height: 200px !important;
//       overflow: auto;
//     }
//     .notification-open-container .content {
//       max-width: 100%;
//       height: 200px !important;
//       overflow: auto;
//     }
//     .ant-row.row-container-open {
//       // height: 300px !important;
//       //Autumn
//       // border-bottom: 1px solid #1890ff;
//       border-bottom: 1px solid #ea3943;
//       padding-bottom: 10px !important;
//       margin-bottom: 10px;
//       .notification-open-container {
//         height: auto !important;
//       }
//     }
//   }
//   .ant-row.ditlindje-row {
//     width: 100%;
//     display: grid;
//     overflow: auto !important;
//     grid-template-columns: 556px !important;
//   }
//   .njoftime {
//     height: 1095px !important;
//   }
//   .ant-row.lajmerime-njoftime {
//     .notification-container {
//       height: 600px !important;
//     }
//     .ant-col.line-col {
//       display: none !important;
//     }
//   }
//   .ditlindje-mobile {
//     display: grid;
//   }
// }

@media (max-width: 450px) {
  .ant-row.lajmerime-post-row {
    width: 100%;
  }
  .admin-dashboard .total-small-card {
    width: 100% !important;
  }
  .njoftime {
    width: 100% !important;
    padding: 0px;
  }
  .content .employee-card-content {
    width: 100% !important;
    span.line-span {
      display: none !important;
    }
  }
  .admin-dashboard .wrap {
    width: 100% !important;
  }
  .admin-dashboard .ant-row.leave-request-row {
    width: 96% !important;
    padding-left: 5px !important;
    .ant-col-9 {
      font-size: 12px;
    }
    .ant-col.ant-col-7 {
      font-size: 12px;
    }
  }
  .admin-dashboard .delayed-clockouts-container {
    width: 100% !important;
    .dep-icon {
      border-bottom: 1px solid rgb(26, 51, 70);
    }
  }

  // span.header-dashbord-card-title {
  //   font-size: 13px !important;
  // }
  .admin-dashboard .no-clockin-container {
    width: 100% !important;
    .dep-icon {
      border-bottom: 1px solid rgb(26, 51, 70);
      .ant-col.ant-col-10::after {
        content: "-";
        margin-left: 5px;
      }
    }
  }
  span.line-for-clockin-vones {
    display: none !important;
  }
  .admin-dashboard .active-users-wrapper {
    height: 100% !important;
    width: 100%;
    .ant-list-item-meta-description {
      font-size: 11px;
    }
  }
  .admin-dashboard .statistikat-pagat-dashboard {
    width: 100% !important;
  }
  .admin-dashboard .wage-history-dashboard {
    width: 100% !important;
    // span{
    //   flex-wrap: wrap;
    // }
    .historiku-turn-name {
      margin: 0px !important;
      padding-bottom: 5px;
      // flex-wrap: wrap;
      display: flex !important;
      text-align: start;
      justify-content: space-between !important;
      font-size: 12px;
      width: 100%;
    }
    .line-historia-turneve-dashboard {
      display: none !important;
    }
    span.lable-orari-historiku-turn {
      width: fit-content !important;
      padding-right: 0px !important;
    }
  }
  .admin-dashboard .waage-pie-dashboard {
    width: 100% !important;
  }
  .admin-dashboard .waage-pie-dashboard {
    width: 100% !important;
  }
  .admin-dashboard .clock-in-dashboard-card {
    width: 100% !important;
  }
  .ant-tabs.ant-tabs-top.ant-tabs-mobile.ant-tabs-card {
    width: 100% !important;
    .ant-tabs-nav-list {
      display: grid !important;
    }
    .lamerimet-tab {
      .ant-row.row-container-open {
        margin-bottom: 10px !important;
      }
      .notification-container {
        height: 200px !important;
      }
      .notification-post-container {
        height: 550px !important;
      }
    }
    .ant-row.lajmerime-njoftime {
      .notification-container {
        height: 650px !important;
      }
    }
    .ant-col.line-col {
      display: none !important;
    }
  }
  .ditlindjet-container .ditlindjet-card-content .ditlindjet-card-info {
    font-size: 12px !important;
    align-items: center;
    .bold {
      font-size: 12px !important;
    }
    .calendar-footer {
      height: 100px !important;
    }
  }
  .calendar-container {
    height: 430px !important;
  }
  .birthday-card-kryefaqja {
    width: 100% !important;
    .event-card-title {
      width: 80% !important;
    }
  }
  .ant-row.lajmerime {
    width: 100%;
    grid-template-columns: 390px;
  }
  .dep-specifik-slection {
    display: grid;
    gap: 10px;
  }
  // .notification-open-container{
  //   height: 225PX;
  // }
}

@media (max-width: 390px) {
  .njoftime {
    padding: 0px !important;
    width: 100% !important;
  }
  .dep-specifik-slection {
    display: grid;
    gap: 10px;
  }
  .notification-open-container .content {
    max-width: 270px !important;
  }
  .ant-row.lajmerime-post-row {
    width: 100% !important;
  }
  .ant-row.lajmerime-post-row .permbajtja-lajmerimit {
    width: 260px !important;
  }
  .ant-tabs.ant-tabs-top.ant-tabs-mobile.ant-tabs-card {
    width: 100% !important;
  }
  .admin-dashboard .ant-row.leave-request-row {
    width: 95% !important;
  }
  .historiku-turn-name {
    margin: 0px !important;
    padding-bottom: 5px;
    // flex-wrap: wrap;
    display: flex !important;
    justify-content: space-between !important;
    font-size: 9px !important;
    width: 100% !important;
  }
  span.lable-orari-historiku-turn {
    width: fit-content !important;
    padding-right: 0px !important;
  }
  .line-historia-turneve-dashboard {
    display: none !important;
  }
  .admin-dashboard .active-users-wrapper {
    width: 100%;
    .ant-list-item-meta-description {
      font-size: 10px;
    }
  }
  .admin-dashboard .waage-pie-dashboard {
    width: 100% !important;
  }
  .admin-dashboard .clock-in-dashboard-card {
    width: 100% !important;
  }
  .admin-dashboard .birthday-card-kryefaqja {
    width: 100% !important;
  }
}
