.new-shift-edit {
  .foter-edit {
    background: #f1f7fb;
    border-radius: 10px;
    height: 70px;
    margin: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .wrapTable {
    height: 260px;
    overflow: auto;
  }

  .ant-modal-body {
    background-color: white;
    height: 700px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;s
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .ant-modal-footer {
    height: 52px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: space-between;
    .close-btn {
      width: 150px;
      height: 32px;
      background: #ea3943 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      text-decoration: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
    .start-btn {
      width: 150px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      text-decoration: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background: gray 0% 0% no-repeat padding-box;
        cursor: not-allowed;
      }
    }
  }
}
.header-edit-modal-aprrove {
  background: #f1f7fb;
  height: 30px;
  width: 130px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

.icon-ndrysho-modal-cell {
  background: #1da193;
  border-radius: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
}

.new-shift-wrapper {
  .warning-title {
    display: flex;
    justify-content: center;
    // margin-left: -110px;
    width: 100%;
    margin-top: -10px;
    align-items: center;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #ea3943;
    .warn-icon {
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  // Shortcuts
  .shortcuts {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .shortcut-btn {
      // width: 140px;
      display: flex;
      justify-content: center;
      padding: 0 10px 0 10px;
      border-radius: 5px;
      height: 25px;
      align-items: center;
      /* UI Properties */
      color: white;
      background: #7284a8 0% 0% no-repeat padding-box;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
  }

  .to-edit-fields {
    margin-top: 20px;
    display: flex;
    margin-left: -30px;
    flex-direction: row;
    align-items: center;
    .shift-field-start {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      label {
        margin-right: 10px;
        margin-left: 5px;
      }
      .ant-picker {
        width: 100%;
        background-color: #f1f7fb;
        border: none;
      }
    }
    .shift-field-end {
      margin-left: 70px;
      display: flex;
      flex-direction: column;
      .multiple-pick {
        width: 140px;
        height: 32px;
        /* UI Properties */
        background: #1da193 0% 0% no-repeat padding-box;
        margin-top: 20px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin-left: -20px;
        cursor: pointer;
      }
      .rmdp-input {
        height: 30px;
        background-color: #f1f7fb;
        border: none;
      }
      label {
        margin-right: 10px;
        margin-left: 5px;
      }
      .ant-picker {
        width: 100%;
        background-color: #f1f7fb;
        border: none;
      }

      .select-employee {
        .ant-select-clear {
          background-color: #f1f7fb !important;
        }
        .ant-select-selector {
          background-color: #f1f7fb !important;
          border: none !important;
        }
        // .ant-select {
        // }
      }
      .daytype-select {
        .ant-select-selector {
          width: 200px;
          background-color: #f1f7fb;
          border: none;
        }
      }
    }
  }
  .to-note {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-bottom: 20px;
    // justify-content: center;

    textarea.ant-input.to-note-textarea {
      margin-left: 0px;
      width: 780px;
      height: 81px;
      /* UI Properties */
      background-color: #f1f7fb;
      border-radius: 5px;
      border: none;
      opacity: 1;
    }
  }

  .hours-generation {
    display: flex;
    // align-items: center;
    margin-left: 0px;

    margin-top: 20px;
    flex-direction: column;
    strong {
      align-items: center;
    }
    .animated-hours {
      // margin-left: 10px;

      width: 100%;
      flex: 1;
      background-color: #f1f7fb;
      border-radius: 5px;
      height: 32px;
      .calculated-hours {
        background-color: #1da193 !important;
        // background: linear-gradient(90deg, hsla(175, 79%, 63%, 1) 0%, hsla(82, 96%, 57%, 1) 100%);
        border-radius: 5px;
        height: 32px;
        animation: chartAnimation 1.9s;
      }
    }
  }
  .status-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    strong {
      align-items: center;
    }
  }
}

.new-shift-container {
  .ant-modal-body {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
  }
}
.header-edit-modal-aprrove {
  background: #f1f7fb;
  height: 30px;
  width: 130px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

.icon-ndrysho-modal-cell {
  background: #1da193;
  border-radius: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
}

.new-shift-wrapper {
  .warning-title {
    display: flex;
    justify-content: center;
    // margin-left: -110px;
    width: 100%;
    margin-top: -10px;
    align-items: center;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #ea3943;
    .warn-icon {
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  // Shortcuts
  .shortcuts {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .shortcut-btn {
      // width: 140px;
      display: flex;
      justify-content: center;
      padding: 0 10px 0 10px;
      border-radius: 5px;
      height: 25px;
      align-items: center;
      /* UI Properties */
      color: white;
      background: #7284a8 0% 0% no-repeat padding-box;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
  }

  .to-edit-fields {
    margin-top: 20px;
    display: flex;
    margin-left: -30px;
    flex-direction: row;
    align-items: center;
    .shift-field-start {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      label {
        margin-right: 10px;
        margin-left: 5px;
      }
      .ant-picker {
        width: 100%;
        background-color: #f1f7fb;
        border: none;
      }
    }
    .shift-field-end {
      margin-left: 70px;
      display: flex;
      flex-direction: column;
      .multiple-pick {
        width: 140px;
        height: 32px;
        /* UI Properties */
        background: #1da193 0% 0% no-repeat padding-box;
        margin-top: 20px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin-left: -20px;
        cursor: pointer;
      }
      .rmdp-input {
        height: 30px;
        background-color: #f1f7fb;
        border: none;

        label {
          margin-right: 10px;
          margin-left: 5px;
        }
        .ant-picker {
          width: 100%;
          background-color: #f1f7fb;
          border: none;
        }

        .select-employee {
          .ant-select-clear {
            background-color: #f1f7fb !important;
          }
          .ant-select-selector {
            background-color: #f1f7fb !important;
            border: none !important;
          }
          // .ant-select {
          // }
        }
        .daytype-select {
          .ant-select-selector {
            width: 200px;
            background-color: #f1f7fb;
            border: none;
          }
        }
      }
    }
    .to-note {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      margin-left: 0px;
      margin-bottom: 20px;
      // justify-content: center;

      textarea.ant-input.to-note-textarea {
        margin-left: 0px;
        width: 780px;
        height: 81px;
        /* UI Properties */
        background-color: #f1f7fb;
        border-radius: 5px;
        border: none;
        opacity: 1;
      }
    }

    .hours-generation {
      display: flex;
      // align-items: center;
      margin-left: 0px;

      margin-top: 20px;
      flex-direction: column;
      strong {
        align-items: center;
      }
      .animated-hours {
        // margin-left: 10px;

        width: 100%;
        flex: 1;
        background-color: #f1f7fb;
        border-radius: 5px;
        height: 32px;
        .calculated-hours {
          background-color: #1da193 !important;
          // background: linear-gradient(90deg, hsla(175, 79%, 63%, 1) 0%, hsla(82, 96%, 57%, 1) 100%);
          border-radius: 5px;
          height: 32px;
          animation: chartAnimation 1.9s;
        }
      }
    }
    .status-wrapper {
      display: flex;
      align-items: center;
      margin-top: 10px;
      strong {
        align-items: center;
      }
    }
  }

  @keyframes chartAnimation {
    from {
      width: 0;
    }
    to {
      width: 1;
    }
  }
}

@media (max-width: 450px) {
  .new-shift-wrapper {
    .ant-modal-body {
      height: fit-content !important;
      .to-edit-fields {
        display: grid !important;
        gap: 5px !important;
        margin-left: -10px !important;
        .shift-field-start {
          margin-left: 20px !important;
        }
      }
    }
  }
  .new-shift-wrapper .new-shift .new-shift-wrapper .warning-title {
    width: 100% !important;
  }
}
@media (max-width: 390px) {
  .new-shift-wrapper .to-edit-fields {
    display: grid !important;
    gap: 5px !important;
    margin-left: -10px !important;
    .shift-field-start {
      margin-left: 20px !important;
    }
  }
  .new-shift-container .ant-modal-body {
    height: fit-content !important;
  }
}
