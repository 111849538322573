.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 60px;
  p {
    font-size: 15px;
    font-weight: 600;
  }
}

.headersLabel {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 35px;
  .headerTips {
    font-weight: 600;
    font-size: 15px;
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.userRequestsData {
  overflow: scroll;
  width: 100%;
  height: calc(100% - 35px);
}
.requests-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
