.employeeModal {
  .addEmployeeContainer {
    .typeOfWorkContainer {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 20px;
      .formContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        gap: 15px;
        width: 100%;
        .ant-form-item {
          width: 100%;
          .ant-form-item-row {
            width: 100%;
            display: grid;
            grid-template-columns: 2fr 3fr;
            .work-date {
              width: 100%;
            }
          }
        }
      }
      .contractContainer {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .ant-form-item-row {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 100%;
          .ant-radio-group {
            display: flex;
            flex-direction: column;
            gap: 5;
          }
          .ant-form-item-control {
            min-height: fit-content;
          }
          label {
            width: 100%;
          }
        }
      }
    }
  }

  .ant-form-item .ant-input-number-handler-wrap {
    display: none;
  }

  .input-container {
    font: normal normal 600 16px "Open Sans";
    letter-spacing: 0px;
    opacity: 1;
    display: flex;
  }

  .content {
    font: normal normal 600 16px "Open Sans";
    letter-spacing: 0px;
    opacity: 1;
    display: flex;
  }

  .input-container .ant-col.ant-form-item-label {
    flex: 0.4;
  }

  .input-container .ant-col.ant-form-item-control {
    flex: 0.6;
  }

  .contractContainer .ant-row.ant-form-item.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-radio-group.ant-radio-group-outline {
    display: flex;
    flex-direction: row;
  }

  .ant-picker.inputStartDate {
    width: 100%;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #d9d9d9 !important;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .ant-picker.input {
    width: 100%;
    height: 32px;
    border: none;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }
  .inputDuration .ant-select-selector {
    border: none !important;
  }
  .inputNumber {
    width: 100%;
    height: 32px;
    border-radius: 5px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
    border: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    align-items: center;
    // width: 345px;
    border: none;
    height: 32px;
    border-radius: 5px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
  }

  .dates .ant-row.ant-form-item.content {
    row-gap: 0px;
    display: flex;
    flex-direction: column;
  }

  // .dates .ant-form-item-label {
  //   display: inline-block;
  //   flex-grow: 0;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   text-align: left;
  //   vertical-align: middle;
  // }

  .ant-select-selection-placeholder {
    font: normal normal normal 13px "Open Sans";
    color: #bababe;
    letter-spacing: 0;
    opacity: 1;
  }

  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #f5f5f7 !important;
    background-color: #f5f5f7;
  }

  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-picker {
    border-color: #f5f5f7;
    background-color: #f5f5f7;
  }
}

@media (max-width: 1250px) {
  .employeeModal {
    .addEmployeeContainer {
      .typeOfWorkContainer {
      }
    }
  }
}

@media (max-width: 450px) {
  .employeeModal {
    .addEmployeeContainer {
      .typeOfWorkContainer {
        grid-template-columns: 1fr;
        .dates {
          .ant-form-item-row {
            display: grid;
            grid-template-columns: 2fr 3fr;
          }
        }
      }
    }
  }
}
