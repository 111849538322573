.shift-log-card-wrapper {
  .header {
    width: 521px;
    height: 32px;
    /* UI Properties */
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .header-title {
      display: flex;
      color: white;
      text-overflow: ellipsis;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  .content {
    width: 521px;
    height: 150px;
    /* UI Properties */
    background: #fafafa 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;

    .content-shifts {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      margin-top: 10px;
      .original-shift,
      .new-shift {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // width: 173px;
        .shift-format {
          //   background-color: red;
          color: white;
          display: flex;
          justify-content: center;
          //   padding: 5px;
          border-radius: 5px;
          width: 193px;
          height: 21px;
        }
      }
    }
    .content-note {
      background: #f1f7fb 0% 0% no-repeat padding-box; //   height: auto;
      margin-top: 40px;
      height: 68px;
      border-radius: 5px;
      display: flex;
      width: 521px;
      justify-content: center;
      flex-direction: column;
      .sticky-title {
        display: flex;
        // position: sticky;
        margin-top: -20px;
        margin-left: 37%;
        justify-content: center;
        width: 142px;
        border-radius: 5px;
        background-color: #1d3445;
        color: white;
      }

      .note-text {
        display: flex;
        justify-content: center;
        padding-right: 50px;
        padding-left: 50px;
        padding-bottom: 20px;
        margin-top: 0px;
      }
    }
  }
}
.ant-tooltip-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  height: max-content;
  width: max-content;
}
.ant-tooltip-inner > a {
  color: #fff;
  line-height: 5px;
}
.quill.note_input.quillTextEditor.to-note-textarea {
  width: 100%;
  border-radius: 5px;
  border: none;
  .quill.note_input.quillTextEditor.to-note-textarea {
    background-color: #f1f7fb;
  }
  .ql-container.ql-snow {
    background-color: #f1f7fb;
    border: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 70px;
  }
  .ql-toolbar.ql-snow {
    background-color: #f1f7fb;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .ql-toolbar.ql-snow {
    width: 100%;
  }
}

.ant-btn-group.ant-dropdown-button.approve-buttons {
  margin-left: 10px;
  margin-right: 10px;
  button.ant-btn {
    background: #1da193;
    color: #fff;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  button.ant-btn.ant-btn-icon-only.ant-dropdown-trigger {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.ant-space-compact.ant-space-compact-block.ant-dropdown-button.approve-buttons {
  margin-left: 10px;
  margin-right: 10px;
  button.ant-btn {
    background: #1da193;
    color: #fff;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 1px solid #fff;
  }
  button.ant-btn.ant-btn-icon-only.ant-dropdown-trigger {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
