$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
.details-edit-card {
  .details .wide-div {
    // margin-bottom: 5px !important;
    height: 100%;
  }

  .details .wide-div {
    .ant-select.detailsInfo {
      .ant-select-selector {
        height: 32px !important;
      }
    }
  }

  #supervisor.ant-checkbox {
    top: 0.5em;
  }
  #supervisor .ant-checkbox-inner {
    margin-left: 0px;
  }
  #supervisor .ant-checkbox-inner {
    background-color: $checkbox_color !important;
    padding: 8px !important;
  }

  .wide-div {
    .select-details {
      width: "100%";
      background: "#F5F5F7";
      border-radius: 5px;
    }
  }

  .details-edit-card {
    label.ant-form-item-no-colon {
      font-weight: 500;
      font-size: 16px;
    }
  }
  .details.label-show-details {
    margin-left: 20px;
  }
  // .ant-card.ant-card-bordered.info-card{
  .react-tel-input.phone-number {
    width: 100%;
    background: #f5f5f7 !important;
    border-radius: 5px;
    border: none;
  }
  .details .ant-form-item {
    margin: 0px 0px 10px;
  }
  .details.ant-form label {
    font-weight: 500 !important;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    color: #323338;
  }
  // }

  // .react-tel-input .form-control{
  //   background: transparent !important;
  //   border: 0px !important;
  // }
  // .react-tel-input .flag-dropdown{
  //   border-left: 0px;
  //   border-top: 0px;
  //   border-bottom: 0px;
  // }
}

@media (max-width: 391px) {
  .details-edit-card {
    textarea.ant-input {
      max-width: 185px !important;
      height: 32px !important;
    }
    .details
      .wide-div
      .ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      width: 185px;
    }
    .ant-select.ant-select-in-form-item {
      width: 185px !important;
    }
  }
}
