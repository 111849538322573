.createCandidateModal {
  width: 1000px !important;
  max-width: calc(100dvw - 50px);
  .ant-modal-content {
    .ant-modal-body {
      width: 100%;
    }
  }
}
.createCandidateModal.kantidatInfoModalSideContainer {
  width: 900px !important;
  right: 295px;
}
.cancelModal {
  .ant-modal-content {
    .ant-modal-confirm-body {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .ant-modal-confirm-title {
      text-align: center;
      padding-top: 10px;
      color: red !important;
      font-size: 25px !important;
    }
    .ant-modal-confirm-content {
      text-align: center;
      .text-bold {
        font-weight: 700;
      }
    }
    .ant-modal-confirm-btns {
      display: flex;
      justify-content: center;
    }
    .ant-btn-confirm {
      border-radius: 5px;
      border-color: #20ac9d;
      background-color: #20ac9d;
    }
    .ant-btn-confirm:hover {
      background-color: #20ac9ecc;
      border-color: #20ac9ecc;
    }
  }
}
.successModal {
  width: 660px !important;
  .ant-modal-content {
    .ant-modal-confirm-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-modal-confirm-title {
      color: rgb(32 172 157);
      text-align: center;
      padding-top: 10px;
      font-size: 25px;
    }
    .ant-modal-confirm-content {
      text-align: center;
      font-size: 16px;
    }
    .ant-modal-confirm-btns {
      text-align: center;
    }
  }
}
