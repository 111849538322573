.success-modal {
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;
    .ant-modal-close {
      color: white;
    }
  }

  .ant-modal-header {
    // height: 45px;
    // background: #1e8273 0% 0% no-repeat padding-box;
    // border-radius: 10px 10px 0px 0px;
    // color: white;
    // .ant-modal-title {
    //   color: white;
    //   font-size: 18px;
    //   font-family: "Open Sans", sans-serif;
    // }
    display: none;
  }

  .ant-modal-body {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
    flex-wrap: nowrap;
    .success-modal-text {
      margin-top: 20px;
      span {
        width: 279px;
        height: 34px;
        color: var(--unnamed-color-323338);
        text-align: left;
        font: normal normal 600 25px/39px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
      }
    }
    .okay-btn {
      justify-self: start;
      margin-top: 20px;
      width: 150px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      color: white;
      border: none;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    .common-confirm-modal-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .content {
        flex: 20;
        min-width: 770px;
        .title {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }

  .ant-modal-footer {
    display: none !important;
    // height: 52px;
    // background: #f5f5f7 0% 0% no-repeat padding-box;
    // border-radius: 0 0 10px 10px;
    // .close-btn {
    //   width: 150px;
    //   height: 32px;
    //   background: #ff4e00 0% 0% no-repeat padding-box;
    //   border-radius: 5px;
    //   opacity: 1;
    //   color: white;
    //   text-decoration: none;
    //   border: none;
    //   &:hover {
    //     cursor: pointer;
    //   }
    // }
  }
}
