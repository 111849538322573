.hidden-cards-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-list-header {
        border: none;
        h4 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 19.07px;
          color: #1d3445;
        }
      }
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          padding: 5px;
          .ant-list.ant-list-sm.ant-list-split.ant-list-bordered {
            border: none !important;
          }
        }
      }
    }
  }
}
