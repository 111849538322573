.weatherCard {
	width: 100%;
	height: 100%;
	.header-div {
		display: flex;
		justify-content: center;
		column-gap: 30px;
		padding: 15px 60px 15px 60px;
		.item-weater {
			//Autumn Theme
			background: #cd1818;
			border-radius: 5px;
			padding-left: 30px;
			padding-right: 30px;
			color: #fff;
			cursor: pointer;
		}
		.item-weater-active {
			background: #f5f5f7;
			border-radius: 5px;
			padding-left: 30px;
			padding-right: 30px;
			color: #323338;
			cursor: pointer;
		}
	}
	.weather-content {
		height: 173px;
		.content-single {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			.Tr-Div {
				padding: 20px;
				width: 50%;
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: center;
				.icon-content {
					display: grid;
					.icon {
						display: flex;
						justify-content: center;
						align-items: flex-end;
						width: 100%;
						height: 65px;
						svg {
							width: 40px;
							height: 40px;
						}
					}
					.temp {
						font: normal normal 600 43px/105px Open Sans;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						height: 104px;
					}
				}
				.content {
					margin-left: 20px;
					height: 120px;
					.contry {
						font: normal normal 600 24px/59px Open Sans;
						span {
							font: normal normal normal 18px/59px Open Sans;
						}
					}
				}
			}
			.Ny-Div {
				padding: 20px;
				width: 50%;
				display: flex;
				width: 100%;
				align-items: center;
				.icon-content {
					display: grid;
					.icon {
						display: flex;
						justify-content: center;
						align-items: flex-end;
						width: 100%;
						height: 65px;
						svg {
							width: 40px;
							height: 40px;
						}
					}
					.temp {
						font: normal normal 600 43px/105px Open Sans;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						height: 104px;
					}
				}
				.content {
					margin-left: 20px;
					height: 120px;
					.contry {
						font: normal normal 600 24px/59px Open Sans;
						span {
							font: normal normal normal 18px/59px Open Sans;
						}
					}
				}
			}
		}
	}
	.content-multiple {
		width: 100%;
		height: 100%;
		display: flex;
		.Tr-Div {
			padding: 20px;
			width: 50%;
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;
			column-gap: 10px;
			.item {
				background: #fbfbfb;
				border-radius: 10px;
				width: 110px;
				height: 130px;
				.icon-headr {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: 70px;
					font: normal normal 600 20px Open Sans;
					.celcius {
						height: 30px;
					}
				}
			}
		}
		.Ny-Div {
			padding: 20px;
			width: 50%;
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;
			column-gap: 10px;
			.item {
				background: #fbfbfb;
				border-radius: 10px;
				width: 110px;
				height: 130px;
				.icon-headr {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: 70px;
					font: normal normal 600 20px Open Sans;
					.celcius {
						height: 30px;
					}
				}
			}
		}
	}
	.line-weather {
		border-right: 1px solid #edf1f5;
		height: 120px;
		margin-top: 30px;
	}
}

@media (max-width: 450px) {
	.weatherCard .weather-content .content-single {
		display: grid !important;
	}
	.weatherCard .line-weather {
		display: none !important;
	}
	.weatherCard .weather-content .content-single .Tr-Div {
		height: 87px;
		.temp {
			align-items: center !important;
		}
		.icon {
			align-items: center !important;
		}
		.icon-content {
			display: flex;
			align-items: center;
		}
	}
	.weatherCard .header-div {
		display: flex;
		justify-content: center;
		column-gap: 10px;
		padding: 10px 10px 10px 10px;
	}
	.weatherCard .weather-content .content-single {
		.Ny-Div .content {
			height: 100px !important;
		}
		.Tr-Div .content {
			height: 100px !important;
		}
	}
	.weatherCard .header-div .item-weater {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.weatherCard .header-div .item-weater-active {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.weatherCard .weather-content .content-single .Ny-Div {
		height: 87px;
		.temp {
			align-items: center !important;
		}
		.icon {
			align-items: center !important;
		}
		.icon-content {
			display: flex;
			align-items: center;
		}
	}
	.weatherCard .content-multiple {
		display: block !important;
	}
}
