.oraret-shift-cont {
	.ag-grid-shifts {
		background: #ffffff;
		width: 1596px;
		height: 742px;
		margin-left: 8px;
		box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 16%);
		border-radius: 15px;
		margin-top: 0px !important;
		padding-left: 10px;
	}
}
