@import "../../index.scss";

.ant-row.department-card-resp-row {
  .drag-container {
    margin-top: -20px;
  }
}
.title {
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background-toggle {
  width: 100%;
  height: 40px;
  background: #e8e9ea;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  .Border-toggle {
    margin-right: 20px;
    background-color: #71cf48;
    width: 98px;
    height: 32px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .off-div {
      width: 78px;
      display: flex;
      height: 23px;
      border-radius: 5px;
      cursor: pointer;
      .logo {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background: #fff;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 50%;
      }
      .des {
        font-size: small;
        color: #fff;
        font-weight: 500;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 50%;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: #8fd96f;
      }
    }
  }
  .Border-toggle-on {
    margin-right: 20px;
    background-color: #fe4c4a;
    width: 98px;
    height: 32px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .on-div {
      width: 78px;
      display: flex;
      height: 23px;
      border-radius: 5px;
      cursor: pointer;
      .logo {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: #fff;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 50%;
      }
      .des {
        font-size: small;
        color: #fff;
        font-weight: 500;
        background: #fe7270;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 50%;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
  .Border-toggle-on:hover {
    background-color: #eb4345;
  }
  .Border-toggle:hover {
    background-color: #68c142;
  }
}

.role-title {
  font-size: 16px;
  font-weight: bold;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.all-cards {
  width: 100%;
  .ant-col.ant-col-6 {
    max-width: 35%;
    display: flex;
  }
  .ant-card {
    width: 100%;
    height: 275px;
    background-color: #ffffff;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
  }
  .ant-card-head {
    width: 100%;
    height: 45px;
    background-color: #1d3445;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    color: #ffffff;
  }
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .header {
    font-size: 18px;
    cursor: pointer;
  }
}
.addDepartment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addDepartmentIcon {
  margin-left: 0;
  fill: transparent;
}
.card-data {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.supervisorTitle {
  margin-top: 10px;
  font-weight: 600;
  color: #323338;
}
.supervisorName {
  margin-top: -15px;
  font-size: 16px;
  font-weight: normal;
}
.employee-number {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 26px;
  border-radius: 5px;
  font-family: Open Sans;
  background-color: #1da193;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.roles {
  max-height: 185px;
  width: 244px;
  border-radius: 5px;
  background-color: #f1f7fb;
  overflow: auto;
}
.roleNames {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  margin-top: -12px;
  margin-bottom: 7px;
  gap: 10px;
}
.roleNumber {
  font-weight: 600;
  margin-left: 10px;
}
.main-text {
  margin-top: 10px;
  width: 182px;
  text-align: center;
  font-size: 16px;
  font-family: Open Sans;
  font-weight: normal;
  letter-spacing: 0;
  color: #323338;
  opacity: 1;
}
.ant-btn.ant-button {
  margin-top: 10px;
  background: #1da193 0% 0% no-repeat padding-box;
  color: #ffffff;
  border-radius: 5px;
}
.ant-btn.ant-button:hover {
  background: #1da193;
  color: #ffffff;
}
.headerIcons {
  display: flex;
  gap: 15px;
}
.departmentHeaderIcons {
  display: flex;
  gap: 10px;
}
.deleteDepartmentIcon {
  background-color: #ea3943;
  justify-self: flex-end;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  cursor: pointer;
  @include flex(center, center);
  &:hover {
    background-color: #ea3943;
  }
}
.deleteDepartmentIconD {
  width: 20px;
  height: 20px;
}
.editIconE {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: #fff;
}
.editIcon {
  margin: 6px;
  width: 15px;
  cursor: pointer;
}
// .dragCardData {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-evenly;
// }
.dragDepartmentRoles {
  max-height: 185px;
  height: 180px;
  width: 244px;
  border-radius: 5px;
  background-color: #f1f7fb;
  overflow: auto;
}
.dragDepartmentRoleTitle {
  font-size: 16px;
  font-weight: bold;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dragRoleNames {
  font-size: 16px;
  margin-top: -12px;
  margin-bottom: 7px;
}
.departmentDragContainer {
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.departmentDrawer {
  .ant-drawer-header {
    height: 45px;
    background: #1d3445;
  }
  .ant-drawer-title {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;

    color: #1d3445;
  }
  .ant-drawer-close {
    width: 17px;
    height: 17px;
    color: #ffffff;
  }
  .ant-drawer-close:focus,
  .ant-drawer-close:hover {
    color: #ffffff;
  }
  .ant-form.ant-form-horizontal .departmentForm {
    margin-top: 20px;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
  }
  .input-title {
    border: 1px solid #d9d9d9;
    width: 270px;
    height: 32px;
    background-color: #f1f7fb !important;
  }
  .select-data.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #f1f7fb !important;
    border-radius: 5px;
  }
  .ant-input .input-title {
    background: #f1f7fb !important;
  }
  .drawer-btn {
    display: flex;
    position: absolute;
    bottom: 350px;
    right: 100px;
    gap: 10px;
  }
  .ant-btn.anulo-btn {
    width: 95px;
    height: 32px;
    background: #ea3943;
    color: #fff;
    border-radius: 5px;
  }
  .save-btn {
    width: 138px;
    height: 32px;
    background: #1da193;
    border-radius: 5px;
  }
  .save-btn:hover {
    background: #1da193;
  }
  .save-btn:focus {
    background: #1da193;
  }
}
.cardsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardHeaderIcons {
  display: flex;
  align-items: center;
  gap: 15px;
}
.editButtons {
  margin-top: 25px;
  margin-right: -15px;
}

.closeEditCardIcon {
  background-color: #f00000;
  border-radius: 5px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.saveEditCardIcon {
  background-color: #1da193;
  border-radius: 5px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
button.ant-btn.ant-btn-default.saveEditCardButton {
  background-color: #1d3445;
  border: none;
}
.editSelect {
  display: flex;
  flex-direction: column;
}
.editLabel {
  font-weight: 600;
}
.editDepartmentName {
  background-color: #f1f7fb !important;
}
.editSupervisor .ant-select-selector {
  background-color: #f1f7fb !important;
  border-radius: 5px !important;
}
.editRoles .ant-select-selector {
  background-color: #f1f7fb !important;
  border-radius: 5px !important;
}
.ant-drawer.ant-drawer-right.ant-drawer-open.departmentDrawer {
  .ant-drawer-content-wrapper {
    width: 500px !important;
  }
  .ant-form-item-label > label {
    font: normal normal 600 16px "Open Sans";
    letter-spacing: 0;
    opacity: 1;
    display: flex;
  }
}
.ant-row.department-card-resp-row {
  .drag-container {
    margin-top: -20px;
  }
}

@media (max-width: 1366px) {
  .department-card-resp-row {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    .ant-col {
      max-width: 100%;
      .editSelect {
        .ant-form-item {
          margin-bottom: 20px;
        }
      }
    }
  }
}

//* responsive DepartmentCard for iPad Air
@media (max-width: 821px) {
  .department-card-resp-row {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .departmentDrawer {
    width: 400px !important;
  }
  .departmentDrawer .ant-form.ant-form-horizontal .departmentForm {
    margin-left: -24px;
  }
  .all-cards .header {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .departmentDrawer .save-btn {
    width: 170px;
  }
}

@media (max-width: 768px) {
  // .department-card-resp-row {
  //   display: grid !important;
  //   grid-template-columns: 335px 335px !important;
  //   .ant-col {
  //     max-width: 100%;
  //     margin-bottom: 20px;
  //   }
  // }
  .all-cards .header {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .all-cards .ant-card {
    height: 100% !important;
    .card-data {
      display: grid;
    }
  }
}

//* responsive DepartmentCard for iPhone
@media (max-width: 450px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.departmentDrawer
    .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .department-card-resp-row {
    display: grid !important;
    grid-template-columns: auto !important;
    .ant-col {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  .all-cards .header {
    font-size: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .department-card-resp-row .delBtn {
    font-size: 10px !important;
    width: 100px !important;
  }
  .all-cards .ant-card {
    height: 100% !important;
    .card-data {
      display: grid;
    }
  }
  .ant-drawer.ant-drawer-right.ant-drawer-open.departmentDrawer
    .ant-drawer-content-wrapper {
    width: 100% !important;
    .departmentpopover .ant-popover-message-title {
      margin-top: 0px;
      font: normal normal normal 10px "Open Sans";
      letter-spacing: 0;
      color: #323338;
      opacity: 1;
    }
  }
}

//* responsive DepartmentCard for iPhone 12 Pro
@media (max-width: 391px) {
  .department-card-resp-row {
    display: grid !important;
    grid-template-columns: auto !important;
    .ant-col {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  .ant-drawer.ant-drawer-right.ant-drawer-open.departmentDrawer
    .ant-drawer-content-wrapper {
    width: 310px !important;
  }
  .departmentDrawer .ant-form.ant-form-horizontal .departmentForm {
    display: flex;
    align-items: baseline;
  }
  .newDepartmentName {
    width: 175px !important;
    input#basic_departmentName {
      width: 220px;
    }
  }
  .ant-drawer.ant-drawer-right.ant-drawer-open.departmentDrawer
    .ant-form-item-label
    > label {
    font-size: 14px;
  }
  .all-cards .header {
    font-size: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .departmentDrawer
    .select-data.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    width: 220px;
  }
  .ant-select.ant-select-in-form-item.select-data.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
    width: 220px !important;
  }
  .ant-select.ant-select-in-form-item.select-data.ant-select-multiple.ant-select-show-search {
    width: 220px !important;
  }
  .department-card-resp-row .delBtn {
    font-size: 10px !important;
    width: 100px !important;
  }
  button.ant-btn.ant-btn-primary.save-btn {
    width: 130px;
  }
  .departmentHeaderIcons {
    gap: 5px;
  }
  .headerIcons {
    gap: 5px;
  }
  span.deleteDepartmentIcon {
    width: 20px;
  }
  .deleteDepartmentIconD {
    width: 12px !important;
  }
  .editIconE {
    width: 20px;
  }
  .editIcon {
    width: 12px;
  }
  .closeEditCardIcon {
    width: 20px;
  }
  .saveEditCardIcon {
    width: 20px;
    margin-left: -10px !important;
  }
  .editButtons {
    margin-left: 3px;
  }
}
