.createCandidateModal {
  .generalInfo {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 15px;
    .personalInfo {
      display: flex;
      flex-direction: column;
      .gridInfos {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;
        .ant-form-item {
          .ant-picker,
          input.form-control {
            width: 100%;
          }
          .react-tel-input {
            width: 100%;
            .selected-flag {
              background-color: #f1f1f1;
            }
            .flag-dropdown {
              background-color: #f1f1f1;
              border: none;
              border-right: 1px solid #cacaca;
            }
            .form-control {
              background-color: #f1f1f1;
              border: none;
              width: 100%;
            }
            .country-list {
              width: 181px;
            }
          }
        }
      }
      .textareaInfos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 3fr 1fr;
        gap: 15px;
        .ant-form-item {
          .custom-quill {
            display: flex;
            flex-direction: column;
            .radio-group {
              margin: -20px 0 5px 0;
              text-align: end;
            }
            .notAllowed {
              cursor: not-allowed;
              .ql-container {
                .ql-editor {
                  opacity: 0;
                }
              }
            }
            .quill-editor {
              height: 140px;
              .ql-container {
                padding: 5px 0;
                border: none;
                background-color: #f1f1f1;
                border-radius: 5px;
                .ql-editor {
                  max-height: 140px;
                }
              }
            }
          }
        }
      }
    }
    .sidebarInfos {
      .ant-form-item {
        .ant-picker {
          width: 100%;
        }
      }
    }
  }
}
// @media (max-width: 1200px) {
//   .createCandidateModal {
//     .generalInfo {
//       grid-template-columns: 6fr 2fr;
//       .gridInfos {
//         grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
//       }
//     }
//   }
// }
@media (max-width: 450px) {
  .createCandidateModal {
    .generalInfo {
      grid-template-columns: 1fr;
      .gridInfos {
        grid-template-columns: 1fr !important;
      }
    }
  }
}
