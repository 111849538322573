.detailsOvertimeModal {
  width: 100%;
  .ant-modal-header {
    display: flex;
    width: 903px;
    height: 45px;
    background: #1d3445 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 10px 10px 0px 0px;
  }
  .ant-modal-content {
    // height: calc(100vh - 20px) !important;
    // height: calc(100dvh - 20px) !important;
    max-height: 700px;
    width: 903px;
    height: 321px;
  }

  .ant-modal-footer {
    position: absolute;
    bottom: 0;
    width: 903px;
    height: 52px;
    background: #fff;
    opacity: 1;
    border: none;
    border-radius: 10px;
    .turnBackBtn {
      width: 150px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #ffffff;
    }
  }
}

.detailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .employeeName {
    width: fit-content;
    height: 22px;
    text-align: center;
    font: normal normal 600 16px/19px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .dateTimeDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 330px;
    height: 26px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }
  .descriptionDetail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 15px;
    gap: 1rem;
    .requestDescription {
      height: 19px;
      text-align: center;
      font: normal normal normal 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #323338;
    }
    .shenim {
      width: 55px;
      height: 19px;
      text-align: center;
      font: normal normal 600 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #323338;
    }
  }
}
