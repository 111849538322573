.employeeModal {
  width: 925px !important;
  max-width: calc(100dvw - 100px);
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px;
    overflow: hidden;
    .ant-modal-body {
      .addEmployeeContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .generalInfoContainer {
          display: flex;
          justify-content: center;
          font-size: 16px;
          gap: 20px;
          font: normal normal 600 14px/22px "Open Sans";
          .form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 15px;
            width: 100%;
            .ant-form-item {
              width: 100%;
              .ant-form-item-row {
                width: 100%;
                display: grid;
                grid-template-columns: 2fr 3fr;
              }
            }
          }
        }
      }
    }
  }
  .input-label {
    font: normal normal 600 16px "Open Sans";
    letter-spacing: 0;
    opacity: 1;
    display: flex;
    .ant-input-affix-wrapper {
      border: none;
    }
  }
  .input-form {
    // width: 345px;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    opacity: 1;
  }

  .inputAddress {
    // width: 345px;
    border-radius: 5px;
    border: none;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .ant-picker.birthdate {
    width: 100%;
    border: none;

    // border: 1px solid #d9d9d9 !important;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }
  .ant-picker.work-date {
    // width: 345px;
    border: none;
    height: 32px;
    background-color: #f5f5f7;
    border-radius: 5px;
    opacity: 1;
  }
  .showName {
    position: absolute;
    top: 166px;
    width: 75px;
    left: 680px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    text-align: center;
  }
  .react-tel-input .form-control {
    width: 100%;
    height: 32px;
    border-radius: 5px;
    background-color: #f5f5f7;
    border: none;
    opacity: 1;
  }
  .react-tel-input .flag-dropdown {
    border: none !important;
  }
  .images {
    width: 207px;
    height: 292px;
  }
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-top: 1px;
  }
  .ant-form-item-explain-error {
    color: #ff4e00;
    font-size: 13px;
  }
  .ant-form-item-has-error {
    border-color: #ff4e00;
  }
  ::placeholder {
    font: normal normal normal 13px "Open Sans";
    color: #bababe;
    letter-spacing: 0;
    opacity: 1;
  }
  .ant-form-item-has-error .ant-picker {
    background-color: #f5f5f7;
    border-color: #f5f5f7;
  }
  .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
  .ant-form-item-has-error .ant-picker:not([disabled]):hover {
    background-color: #f5f5f7;
  }
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-number-affix-wrapper-disabled):not(
      .ant-input-number-affix-wrapper-borderless
    ).ant-input-number-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error
    :not(.ant-input-number-affix-wrapper-disabled):not(
      .ant-input-number-affix-wrapper-borderless
    ).ant-input-number-affix-wrapper:hover {
    background-color: #f5f5f7;
    border-color: #f5f5f7;
  }
}

@media (max-width: 1250px) {
  .employeeModal {
    .span {
      display: none;
    }
    .ant-modal-content {
      .ant-modal-body {
        .addEmployeeContainer {
          .generalInfo {
            .form {
              .ant-form-item {
                width: 100%;
                .ant-form-item-row {
                  width: 100%;
                  display: grid;
                  grid-template-columns: 1fr 2fr;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .employeeModal {
    max-width: calc(100dvw - 16px);
    .span,
    .images {
      display: none;
    }
    .generalInfo {
      .form {
        .ant-form-item {
          width: 100%;
          .ant-form-item-row {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 2fr;
          }
        }
      }
    }
  }
}
