@import "../../index.scss";
:root {
  --color: #1da193;
  --baseColor: #efefef;
  --processColor: #71cf4871;
  --completedColor: #1da193;
}
.antCustomStepper {
  $completed-color: #1da193;
  $process-color: #71cf4871;
  $base-color: #efefef;
  $active-color: #1f2a44;
  $completed-inner-triangle-border: 18px solid var(--completedColor);
  $active-inner-triangle-border: 18px solid var(--color);
  $process-inner-triangle-border: 18px solid var(--processColor);
  $waiting-inner-triangle-border: 18px solid var(--baseColor);
  $outer-triangle-border: 25px solid #fff;
  width: 100%;
  display: flex;
  justify-content: center;

  .innerTriangle {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 0;
    top: 0;
    left: 100%;
    margin: 0;
    border-left: 18px solid var(--color);
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    z-index: 1;
    transform: unset;
  }

  .ant-steps-item-title {
    font: normal normal normal 600 16px "Open Sans";
  }

  // .ant-steps-navigation {
  //   margin-top: -10px;
  //   margin-bottom: 15px;
  // }

  .outerTriangle {
    content: "";
    position: absolute;
    width: 0 !important;
    height: 0;
    border: 0;
    top: -20%;
    left: 100% !important;
    margin: 0;
    background-color: transparent;
    border-left: 25px solid #fff;
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
    z-index: 1;
    transform: unset;
    transition: none;
  }

  .ant-steps-item {
    background-color: var(--baseColor);
    position: relative;
    &:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    &:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    &::before {
      background-color: transparent !important;
    }
  }

  .ant-steps-navigation {
    .ant-steps-item {
      display: flex;
    }
    .ant-steps-item-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      padding: 6px;

      .ant-steps-item-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        align-self: center;
      }
    }

    .ant-steps-item-icon {
      display: none !important;
    }

    .ant-steps-item-wait {
      &::before {
        @extend .outerTriangle;
      }
      &::after {
        @extend .innerTriangle;
        border-left: $waiting-inner-triangle-border;
      }
    }

    .ant-steps-item-finish {
      background-color: var(--completedColor);
      .ant-steps-item-title {
        color: #fff !important;
      }
      &::before {
        @extend .outerTriangle;
      }
      &::after {
        @extend .innerTriangle;
      }
    }

    .ant-steps-item-active {
      background-color: $active-color;
      .ant-steps-item-title {
        color: #fff !important;
      }
      &::before {
        @extend .outerTriangle;
      }

      &::after {
        @extend .innerTriangle;
        border-left: $active-inner-triangle-border;
      }
    }

    .ant-steps-item-process {
      background-color: var(--processColor);
      .ant-steps-item-title {
        color: #fff !important;
      }
      &::before {
        @extend .outerTriangle;
      }
      &::after {
        @extend .innerTriangle;
        border-left: $process-inner-triangle-border;
      }
    }
  }
}

@media (max-width: 450px) {
  .antCustomStepper {
    overflow-x: hidden;
    .ant-steps-navigation {
      width: 80%;
      gap: 10px;
      .ant-steps-item {
        width: 100%;
        border-radius: 25px;
        &::before,
        &::after {
          display: none !important;
        }
        .ant-steps-item-container {
          width: 100%;
          height: unset;
          margin-inline-start: 0;
          .ant-steps-item-content {
            min-height: unset;
            .ant-steps-item-title {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
