.siderModal {
  // width: calc(100vw - 20px) !important;
  // width: calc(100dvw - 20px) !important;
  // height: calc(100vh - 20px) !important;
  // height: calc(100dvh - 20px) !important;
  max-width: 315px !important;
  // max-height: 700px !important;

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-width: 810px;
    height: 700px;
    border-radius: 10px;
    .ant-modal-close {
      height: 35px;
      width: 35px;
      margin-right: 12px;

      .ant-modal-close-x {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #e7eef2;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      // height: calc(100vh - 97px);
      // max-height: 600px;
      width: 100%;
      height: 100%;

      .uploadedFilesSection {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: auto;
        .fileCard {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 275px;
          height: 47px;
          border-radius: 5px;
          background-color: #f1f1f5;
          .uploadedFile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 10px;
          }
          .uploadedFileIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 15px;
          }
          .uploadFileName {
            display: flex;
            align-items: center;
            width: 100%;
            // padding: 10px 0;
            cursor: pointer;
          }
          .fileName {
            font-family: Open Sans;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 5px;
            max-width: 210px;
            overflow: hidden;
            text-wrap: nowrap;
          }
          .closeIconContainer {
            background-color: transparent;
            border: none;
            height: fit-content;
            width: fit-content;
          }
          .closeIcon {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .ant-modal-footer {
      // height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border: none;
      width: 100%;
      border-radius: 5px;
      // padding: 20px;
      .decline {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #ea3943;
        border: none;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        color: #fbfbfb;
      }
    }
  }
}
.siderProblemsModal {
  width: fit-content;

  left: calc(50% + 420px) !important;
  right: 50% !important;
}

@media (max-width: 1450px) {
  .siderProblemsModal {
    left: auto !important;
    right: auto !important;
    width: 100%;
  }
}
