@import "../../index.scss";

.row-gap-10 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.column-gap-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  .permission-title {
    margin-top: 5px;
    @include text(14px, 600, 0, #111);
  }
  .permissions-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    max-height: 200px;
    &::-webkit-scrollbar {
      width: 6px;
    }
  }
  .permission-item {
    background-color: #f7f8fa;
    border-radius: 5px;
    padding: 10px;
    animation: fadeInDown 1000ms both;
    width: 100%;
    .ant-list-item-meta-title {
      margin-bottom: 0;
    }
    .ant-list-item-meta-description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .deletePermissionIcon {
      position: absolute;
      background-color: #fe4c4a;
      width: 18px;
      height: 18px;
      top: 5px;
      right: 5px;
      border-radius: 5px;
      cursor: pointer;
      @include flex(center, center);
      .closeIcon {
        width: 12px;
      }
      &:hover {
        background-color: #eb4345;
      }
    }
  }
}
