.workBeforeShiftData {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  flex-direction: row;
  // gap: 3rem;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px;
  width: 100%;
  .header-item-tip {
    font-weight: 600;
    font-size: 15px;
    height: 35px;
    width: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.buttonFooterOvertime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  .infoBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.8rem;
    width: 221px;
    height: 32px;
    // Autumn theme
    background: #ea3943 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #fff;
  }
  .createBtn {
    width: 224px;
    height: 32px;
    // Autumn theme
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #fff;
  }
}

.item-overtime {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 20px 0px 20px;
}

.containerOvertime {
  overflow: auto;
  height: calc(100% - 72px);
  padding-bottom: 10px;
}
.overtimePeriod {
  display: flex;
  flex-direction: column;
}

.overtimePeriodContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.workBeforeShiftModal {
  .ant-modal-header {
    display: flex;
    justify-self: center;
    align-items: center;
    width: 651px;
    height: 45px;
    background: #1d3445 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 10px 10px 0px 0px;
  }
  .ant-modal-content {
    width: 651px;
    height: 457px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
    overflow: auto;
    overflow-x: hidden;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    height: 47px;
    font-size: 20px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 277px;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .ant-modal-footer {
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    bottom: 0;
    width: 651px;
    height: 52px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .anulo {
    width: 150px;
    height: 32px;
    border-radius: 5px;
    background: #ea3943 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #ffffff;
  }
  .krijoOreShtese {
    width: 150px;
    height: 32px;
    border-radius: 5px;
    background: #1da193 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #ffffff;
  }
}

.noOvertimeUser {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 45px;
  .noOvertime {
    display: flex;
    justify-content: center;
    width: 284px;
    height: 22px;
    font: normal normal 600 16px/19px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    margin-top: 10px;
  }
  .noOvertime-greet {
    width: 400px;
    height: 19px;
    text-align: center;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 5px;
  }
  .noOvertime-actionButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin-top: 35px;
    .infoBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 0.8rem;
      width: 221px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #fff;
    }
    .createBtn {
      width: 224px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #fff;
    }
  }
}

.formOverwork {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .formFields {
    width: 277px;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
  }
}
@media (max-width: 450px) {
  .noOvertimeUser .noOvertime-actionButtons {
    flex-direction: column;
    gap: 5px;
    margin-top: 25px;
  }
}
