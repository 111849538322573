$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
.inner-modal .ant-modal-content {
  border-radius: 20px 20px 10px 10px;
  width: 544px;
  height: 229px;
  margin-top: 250px;
  margin-left: 250px;
  margin-right: 300px !important;
  // overflow-y: scroll;
}
.inner-modal .ant-modal-body {
  overflow-y: scroll;
}

.inner-modal .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
  background-color: $card_blue;
  font-weight: bolder;
  height: 45px;
  display: flex;
}
.inner-modal .ant-modal-title {
  color: white;
  margin-top: -5px;

  font-weight: 600;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.inner-modal .ant-modal-close {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-top: -5px;
}

.ant-modal.inner-modal {
  width: 1095px !important;
}

.employee-name {
  // margin: 0px 122px 0px;
  margin-top: -10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font: normal normal 600 14px/22px "Open Sans", sans-serif;
}
.shift-time {
  justify-content: center;
  font: normal normal 600 14px/22px "Open Sans", sans-serif;
  #red {
    color: #ea3943;
    margin-right: 5px;
  }
  #green {
    color: #1da193;
    margin-left: 5px;
  }
}
.note-container {
  margin-top: 30px;
  position: relative;
  .note-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 165px;
    padding-top: 0px;
    justify-content: center;
    background-color: #1d3445;
    padding: 0px 3px;
    width: 152px;
    height: 22px;
    color: #fff;
    border-radius: 3px;
    text-align: center;
    align-items: center;
    position: absolute;
    // bottom: 60px;
    margin-top: -10px;
  }
  .note {
    margin: 0px;
    justify-content: center;
    text-align: center;
    padding: 15px 34px 10px;
    height: 70px;
    border-radius: 5px;
    background-color: #f1f7fb;
  }
}
