.div-content {
  display: flex;
  align-items: center;
  margin-top: 0px;
  height: 100% !important;
  text-align: center;
  justify-content: center;
  .icon-cont {
    svg {
      fill: #1da193;
    }
  }
  .number {
    font-family: Open Sans;
    font-size: 50px;
    font-weight: 600;
    line-height: 68.09px;
    text-align: center;
    > a {
      color: #1d3445;
    }

    // font: normal normal bold 30px/47px "Open Sans", sans-serif;
    // Autumn theme
    // color: #1D3445;
    // color: #1d3445;
  }
  .text-div {
    font: normal normal 600 16px/25px "Open Sans", sans-serif;
    color: #323338;
  }
}
//STYLE: 3RD ROW CARDS
.card-title {
  margin: 10px;
  text-align: center;
  font: normal normal 600 16px/12px "Open Sans", sans-serif;
}
.person-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  //   margin: 0px;
  span {
    margin-left: 20px;
    margin-right: 20px;
    padding: 12px;
    font: normal normal normal 16px/12px "Open Sans", sans-serif;
  }
  #details {
    width: 71px;
    height: 26px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background: #1da193 0% 0% no-repeat padding-box;
    margin-left: 3px;
    color: #ffffff;
    margin-right: 20px;
  }
  #date {
    color: #323338;
  }
}

.ska-dtl-muaj {
  width: 100%;
  // height: 160px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  font-weight: 500;
}

//Event Card Style
.event-card-title {
  margin: 9px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  .title-text {
    font: normal normal 600 16px "Open Sans", sans-serif;
  }
  .month {
    padding: 3px 33px;
    background-color: #1d3445;
    border-radius: 2px;
    color: #fff;
    font: normal normal normal 12px;
  }
}

.anniversary {
  margin: 10px 30px;
  // white-space:
  span {
    font: normal normal normal 16px/22px "Open Sans", sans-serif;
    color: #323338;
  }
}

@mixin icon-cards($icon_url) {
  // margin-left: 20px;
  margin-top: 0px;
  width: 100%;
  // height: 280px;
  // cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  opacity: 1;
  background: linear-gradient(
      rgba(255, 255, 255, 0.97),
      rgba(255, 255, 255, 0.97)
    ),
    url($icon_url) no-repeat !important;
}

.total-small-card {
  @include icon-cards("../../../../assets/icons/punonjesit_icon.svg");
  background-size: 145% !important;
  background-position: 50% -400% !important;
}
.fulltime-small-card {
  @include icon-cards("../../../../assets/icons/full_time_icon.svg");
  background-size: 110% !important;
  background-position: 30% -45% !important;
}
.parttime-small-card {
  @include icon-cards("../../../../assets/icons/part_time_icon.svg");
  background-size: 80% !important;
  background-position: 50% -40% !important;
}
.abstance-small-card {
  @include icon-cards("../../../../assets/icons/absence_icon.svg");
  background-size: 45% !important;
  background-position: 120% 150% !important;
}
