.container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
}
.breakContent {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	position: relative;
	top: 6px;
}

.wrapperIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 91px;
	height: 91px;
	background: #f5f5f7 0% 0% no-repeat padding-box;
	opacity: 1;
	border-radius: 50%;
	margin-top: 15px;
	position: relative;
	top: 15px;
}

.breakText {
	width: 126px;
	height: 22px;
	text-align: center;
	font: normal normal 600 16px/19px Open Sans;
	letter-spacing: 0px;
	color: #323338;
	opacity: 1;
}

.oret {
	// autumn theme
	color: #244431;
	font-size: 17px;
	font-weight: 600;
}

.text {
	color: rgb(234, 57, 67);
	font-weight: 500;
	font-size: 15px;
}
