.forgotModal {
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;
    .ant-modal-close {
      color: white;
      background: #ea3943 0% 0% no-repeat padding-box;
    }
  }
  .ant-modal-footer {
    .forgot-btn {
      width: 150px;
      height: 32px;
      /* UI Properties */
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      border: none;
      color: white;
      cursor: pointer;
    }
  }
  .ant-modal-header {
    height: 45px;
    background: #ea3943 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    color: white;
    display: flex;
    align-items: center;
    .ant-modal-title {
      color: white;
      font-size: 18px;
      font-family: "Open Sans", sans-serif;
    }
  }
  .forgot-modal-content {
    // background-color: #ff4343;
    .warnng-forgot-intro {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      .warning-title {
        font: normal normal normal 14px/19px Open Sans;
        letter-spacing: 0px;
        opacity: 1;
        color: #ea3943;
        margin-left: 20px;
      }
    }

    .forgot-form {
      .first-row-forgot {
        display: flex;
        margin-left: 0px;
        gap: 30px;
        .ant-picker.ant-picker-disabled {
          background: #f5f5f500;
          border-color: #d9d9d900;
          color: rgb(196, 0, 0) !important;
          width: 160px;
        }
        .ant-picker.ant-picker-disabled .ant-picker-suffix {
          color: #ea3943;
        }
        .ant-picker-input > input[disabled] {
          // color: rgba(0, 0, 0, 0.25);
          color: #ea3943;
          fill: #ea3943;
        }
        .start-shift-forgot {
          display: flex;
          gap: 10px;
        }
        .end-shift-forgot {
          display: flex;
          // gap: 60px;
          .ant-picker.ant-picker {
            background: #f5f5f7;
            border-color: #d9d9d900;
            border-radius: 5px;
            color: rgb(196, 0, 0) !important;
            width: 160px;
            margin-left: 25px;
          }
          .ant-picker.ant-picker-disabled .ant-picker-suffix {
            color: #ea3943;
          }
        }
      }
      .note-forgot {
        margin-left: -540px;
      }
      .after-midnight-checkbox {
        margin-left: 40px;
        width: 100px;
      }
      .forgot-submit-btn {
        width: 114px;
        border: none;
        height: 32px;
        cursor: pointer;
        background: #1da193 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
        color: white;
        margin-left: -45px;
      }
    }
  }
  textarea#shenimi {
    width: 600px;
    border: none;
    background-color: #f5f5f7;
    border-radius: 5px;
  }
}
