.menaxhimi-orarit {
  // margin-left: 30px;
  // margin-top: 30px;
  width: 100%;
}

.header-icons {
  path#Path_7433 {
    fill: #323338;
  }
}

.ag-grid-menaxhimi-orarit {
  background: #fff;
  width: 97%;
  height: 832px;
  margin-left: 7px;
  box-shadow: 1px 1px 2px 1px #00000029;
  border-radius: 15px;
  margin-top: 10px;
  .menaxhimi-orarit-header {
    padding: 10px;
    display: flex;
    align-items: center;
    .header-search {
      background: #f1f7fb;
      display: flex;
      width: 295px;
      margin-left: 30px;
      border-radius: 5px;
      height: 32px;
    }
    button.pastro-btn {
      background: #ea3943;
      border-radius: 5px;
      margin-left: 0px;
      height: 23px;
      width: 80px;
      margin-top: 5px;
      border: none;
      color: #fff;
      cursor: pointer;
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
      left: -163px !important;
      background: #f1f7fb;
    }
    input.ant-input {
      margin-left: 30px;
      border: none;
      background: transparent;
    }
    input.ant-input::placeholder {
      color: black !important;
    }
    .punojsit-div {
      display: flex;
      background: #f1f7fb;
      height: 32px;
      margin-left: 3%;
      border-radius: 5px;
      padding-left: 10px;
      .ant-select-selector {
        background: transparent;
      }
      .icon-punojsit {
        margin-top: 5px;
        margin-right: 10px;
        path#Path_6861_00000147205192370109036890000003473032641409739905_ {
          fill: #323338;
        }
      }
      input.ant-input::placeholder {
        color: black !important;
        background: transparent;
      }
    }
    button.ant-btn.ant-btn-icon-only.ant-input-search-button {
      border: none;
      color: black !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none !important;
    }
  }

  button.ant-btn.ant-btn-icon-only.ant-input-search-button {
    border: none;
    color: black !important;
    background: transparent;
  }
  .gird-icons-edit {
    width: 26px;
    height: 26px;
    background: #0288d2;
    border-radius: 5px;
    padding: 6px;
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
  }
  .ag-grid-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -7px;
  }
  .gird-icons-delete {
    width: 26px;
    height: 26px;
    background: #ea3943;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
  }
  button.ant-btn.krijo-orar-btn {
    background: #1da193;
    color: #fff;
    border-radius: 5px;
    margin-left: 22%;
  }
}

button.ant-btn.krijo-orar-ri-btn {
  margin-left: 47%;
  background: #1da193;
  color: #fff;
  border-radius: 5px;
}

.ag-grid-menaxhimi-orarit .ag-theme-alpine .ag-header {
  background: #1d3445;
  border: none;
}

.ag-grid-menaxhimi-orarit .ag-theme-alpine .ag-root-wrapper {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.ag-grid-menaxhimi-orarit .ag-theme-alpine .ag-header-row.ag-header-row-column {
  color: #ffffff !important;
  font-weight: 400;
  font-size: medium;
}

.ag-grid-menaxhimi-orarit .ag-theme-alpine .ag-header-cell-resize {
  // display: none;
}

.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
  background: #fff !important;
  border: 1px solid #e9e9e9 !important;
}
.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
  border: none !important;
}

//hover cells
.ag-grid-menaxhimi-orarit
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}
.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}
.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}
.ag-grid-menaxhimi-orarit
  .ag-row-even.ag-row-position-absolute.ag-after-created.ag-row-focus {
  background: #fff;
  border: none;
}
.ag-grid-menaxhimi-orarit
  .ag-row-odd.ag-row-position-absolute.ag-after-created.ag-row-focus {
  background: #f6f6f6;
  border: none;
}
.ag-grid-menaxhimi-orarit
  .ag-row-even.ag-row-position-absolute.ag-after-created.ag-row-focus:hover {
  background: #e1e1e6;
}
.ag-grid-menaxhimi-orarit
  .ag-row-odd.ag-row-position-absolute.ag-after-created.ag-row-focus:hover {
  background: #e1e1e6;
}
.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  background: #1d3445;
}
.ag-grid-menaxhimi-orarit .ag-row-even.ag-after-created.ag-row-selected:hover {
  background: #e1e1e6 !important;
}

.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-focus.ag-row-selected:hover {
  background: #e1e1e6 !important;
}
.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-selected.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-menaxhimi-orarit .ag-theme-alpine .ag-checkbox .ag-input-wrapper {
  background: #1d3445;
  width: 20px;
  height: 18px;
}
.ag-grid-menaxhimi-orarit .ag-theme-alpine .ag-checkbox-input-wrapper::after {
  color: #1d3445 !important;
}

.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-checkbox-input-wrapper.ag-checked::after {
  color: #1d3445 !important;
  background: #ffffff !important;
}

.ag-grid-menaxhimi-orarit .ag-theme-alpine .ag-checkbox-input-wrapper {
  font-size: 20px !important;
}

.ag-grid-menaxhimi-orarit .ag-theme-alpine .ag-row-selected {
  background: #cdd3df !important;
}

.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-focus.ag-row-selected {
  background: #cdd3df !important;
}

.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-selected.ag-row-no-focus {
  background: #cdd3df !important;
}

.ag-grid-menaxhimi-orarit .ag-theme-alpine .ag-paging-panel {
  border: none !important;
}

.ag-grid-menaxhimi-orarit
  .ag-theme-alpine
  span.ag-header-icon.ag-header-cell-menu-button {
  color: #fff !important;
}
.ag-grid-menaxhimi-orarit .ag-theme-alpine span.ag-icon.ag-icon-asc {
  color: #fff !important;
}
.ag-grid-menaxhimi-orarit .ag-theme-alpine span.ag-icon.ag-icon-desc {
  color: #fff !important;
}
