.react-calendar-timeline {
  width: 100%;
  background-color: #f1f7fb !important;
  border: none !important;
  height: 300px !important;
  // overflow: scroll;

  .rct-dateHeader {
    background-color: #f1f7fb;
    border: none !important;
    border-bottom: 1px solid rgb(231, 231, 231) !important;
  }
  .rct-calendar-header {
    border-left: none !important;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid rgb(231, 231, 231);
    span {
      color: #1d3445;
    }
  }
  .rct-outer {
    width: 100%;
    overflow-y: auto !important;
    height: 100% !important;
    overflow-x: hidden;
  }
  .rct-vertical-lines .rct-vl {
    border: none !important;
  }
  .rct-horizontal-lines .rct-hl-odd {
    // background-color: #f1f7fb;
    border: 1px solid rgb(231, 231, 231);
  }
  .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    // background-color: #f1f7fb;
    font: normal normal 600 14px/19px "Open Sans", sans-serif;
  }
  .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    // background-color: #f1f7fb;
    font: normal normal 600 14px/19px "Open Sans", sans-serif;
    border-top: 1px solid rgb(231, 231, 231);
  }
  .rct-sidebar .rct-sidebar-row {
    border-bottom: 1px solid rgb(231, 231, 231);
  }
  .rct-dateHeader {
    font: normal normal normal 14px/12px "Open Sans", sans-serif;
  }
  .rct-item {
    // background-color: #1DA193 !important;
    border: none !important;
    border-radius: 5px !important;
    height: 22px !important;
    text-align: center;
    overflow: hidden !important;
    &:hover {
      overflow: visible !important;
    }
  }

  .rct-item-content {
    // color: #000000D9;
    font: normal normal normal 13px/13px "Open Sans", sans-serif;
    padding: 4px 8px !important;
    text-align: center !important;
    white-space: nowrap;
  }
  .rct-horizontal-lines .rct-hl-even,
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border: 1px solid rgb(231, 231, 231);
  }
}
.react-calendar-timeline .rct-header-root {
  background-color: #f1f7fb !important;
  border: none !important;
}
.react-calendar-timeline .rct-scroll {
  background-color: #f1f7fb;
  width: 100%;
  overflow-x: hidden;
}
.react-calendar-timeline .rct-dateHeader-primary {
  color: #323338;
  font: normal normal bold 16px/39px "Open Sans", sans-serif;
}
.weekend {
  background-color: #363f1b;
}
.percentage {
  font: normal normal 14px/30px "Open Sans", sans-serif;
  color: rgb(255, 255, 255);
  align-items: center;
  padding: 0px 33px 0px 37px;
  background-color: #1d3445;
  position: absolute;
  top: 30px;
  width: 90%;
}

.react-calendar-timeline .rct-item.item-weekend {
  background-color: #f1f7fb !important;
}
.departments-list {
  height: 270px !important;
  overflow-y: scroll !important;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  // background-color: #f1f7fb !important;
  // border-bottom: #323338 !important;
  z-index: -10;
}
