$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;

.editableCard {
  display: flex;
  flex-direction: column;
  height: 470px;
  .ant-card-head {
    background-color: $card_blue;
    border-radius: 10px 10px 0 0;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 18px;
    .editableCardIcons {
      display: flex;
      gap: 10px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 5px;
        padding: 0;
        border: none;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
        &#close {
          background-color: #ea3943;
          &:hover {
            opacity: 0.9;
          }
        }
        &#tick {
          background-color: #1da193;
          :hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
  .ant-card-body {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .ant-form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .labeledInputContainer {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        .labeledInputLabel {
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          color: #323338;
          overflow-wrap: break-word;
          font-weight: 400;
        }
        .ant-form-item {
          margin: 0;
          .flag-dropdown {
            border: none;
          }
          input {
            background-color: #f5f5f7;
            border: none;
          }
          .ant-checkbox-wrapper {
            display: flex;
            align-items: center;
            .ant-checkbox {
              top: 0;
            }
            span {
              font-size: 14px;
              font-family: "Open Sans", sans-serif;
              color: #323338;
              overflow-wrap: break-word;
              font-weight: 400;
            }
            .ant-checkbox-inner {
              border: 3px solid #1da193;
              background-color: #1da193;
              padding: 8px;
            }
          }
        }
      }
    }
    .viewMode {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .viewModeRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        span {
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          color: #323338;
          overflow-wrap: break-word;
        }
      }
    }
  }
}
