.pagat-fitimet-setings {
  width: 100%;
  height: 100%;
  overflow: auto;
  .toogleDiv {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    margin-right: 20px;
    button.ant-btn.ant-btn-default {
      width: 130px;
      border-radius: 5px;
      border: none;
      background: #1da193;
      color: #fff;
    }
    button.ant-btn.ant-btn-default:hover {
      box-shadow: 2px 2px 2px 2px #0c428f;
    }
  }

  .ska-fitime-data {
    // width: 100%;
    margin: 20px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-radius: 10px;
    background-color: #1d3445;
    color: #fff;
  }

  .pagat-fitimet-header {
    background: #fff;
    display: flex;
    align-items: center;
    margin: 20px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px #00000029;
  }
  .pagat-fitimet-search {
    width: 212px;
    margin-left: 20px;
    background: #f1f7fb;
    border-radius: 5px;
    display: flex;
    input.ant-input {
      background: #f1f7fb;
      border: none;
    }
    .search-icon {
      width: 30;
      margin-left: 10px;
      margin-right: 10px;
      justify-content: center;
      display: flex;
      align-items: center;
      background: #f1f7fb;
      path#Path_6858_00000086666139294581268680000013212754614146113725_ {
        fill: #1d3445;
      }
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
      left: -180px !important;
    }
    button.ant-btn.ant-btn-icon-only.ant-input-search-button {
      background: #f1f7fb !important;
      border: none;
    }
    svg {
      color: #1d3445;
    }
  }
  button.ant-btn.krijo-btn {
    background: #1da193;
    margin-left: 30px;
    color: #fff;
    border-radius: 5px;
  }
}
// modal
.ant-drawer.ant-drawer-right.ant-drawer-open.krijo-modal {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-drawer-header {
    height: 45px;
    background: #1d3445;
    padding: 10px 24px;
    .ant-drawer-title {
      color: #1d3445;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
    }
  }
  button.ant-drawer-close {
    height: 45px;
  }
  button.ant-drawer-close {
    color: #fff;
  }
  .ant-modal {
    top: 0 !important;
    height: 100%;
    padding: 0px;
    left: 710px !important;
  }
  .ant-modal-content {
    height: 100%;
  }
  .ant-modal-body {
    height: 88%;
  }
}
// modal content
.krijo-modal-content {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  h3 {
    font-weight: 600;
    text-align: center;
    font-size: larger;
  }
  .input-krijo {
    input {
      background: #f1f7fb;
    }
  }
  // DROPDOWN KRIJO MODAL
  .dropdown-krijo {
    display: grid;
    align-items: center;
    margin-bottom: 10px;
    span.star-color {
      color: #ff4e00;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: #f1f7fb !important;
      border-radius: 5px;
    }
    span.anticon.anticon-caret-down {
      color: #bababe;
    }
    svg {
      width: 15px;
      height: 15px;
    }
  }
  // CHECKBOX KRIJO MODAL
  label.ant-checkbox-wrapper.krijo-checkbox {
    display: grid;
    justify-content: center;
    align-items: center;
    span.ant-checkbox {
      margin-left: 100px;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 25px;
    }
    span.ant-checkbox-inner {
      background: #f1f7fb;
      width: 25px;
      height: 25px;
    }
    .ant-checkbox-checked:after {
      border: none;
    }
    .ant-checkbox-inner:after {
      width: 7px;
      height: 13px;
      color: #1da193;
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
      position: absolute;
      display: table;
      border: 2px solid #1da193;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
  }

  .modal-footer {
    margin-top: 30px;
    button.ant-btn.anullo-modal-btn {
      background: #ea3943;
      border-radius: 5px;
      color: #fff;
      margin-right: 20px;
    }
    button.ant-btn.ruaj-modal-btn {
      background: #1da193;
      color: #fff;
      border-radius: 5px;
    }
  }
}

// card fitimet
.card-fitimet {
  margin-bottom: 25px;
  // margin-right: 20px;
  box-shadow: 1px 1px 2px 1px #00000029;
  border-radius: 10px;
  .ant-card-head {
    background: #1d3445;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .ant-card-body {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
  }
}
// VIEW CARD fitimet
.Fitimet-view-card {
  justify-content: center;
  align-items: center;
  display: grid;
  text-align: center;
  h3 {
    margin: 0px;
    font-weight: 600;
    font-size: large;
  }
  label.ant-checkbox-wrapper.festat-zyrtare-checkbox {
    display: grid;
    h3 {
      font-size: medium;
    }
  }
  span.ant-checkbox {
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 105px;
    width: 25px;
  }
  span.ant-checkbox-inner {
    width: 25px;
    height: 25px;
    background: #f1f7fb;
  }
  .ant-checkbox-inner:after {
    width: 7px;
    height: 14px;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    position: absolute;
    display: table;
    border: 2px solid #1da193;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
  }

  .festimer-view-card-footer {
    display: flex;
    justify-content: center;
    button.ant-btn.ndrysho-card-btn {
      background: #1da193;
      border-radius: 5px;
      color: #fff;
      width: 105px;
      height: 32px;
    }
    .icon-foter {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1da193;
      height: 32px;
      width: 32px;
      margin-right: 15px;
      margin-left: 15px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
//edit fitimet cardd
.fitimet-edit-card {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    margin-bottom: 2px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .edit-festat-dropdown {
    height: 70px;
    display: grid;
    align-items: center;
    margin-bottom: 5px;
    span.star-color {
      color: #ff4e00;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: #f1f7fb !important;
      border-radius: 5px;
    }
    span.anticon.anticon-caret-down {
      color: #bababe;
    }
    svg {
      width: 15px;
      height: 15px;
    }
  }
  .input-edit-festat {
    height: 75px;
    input {
      background: #f1f7fb;
    }
  }
  label.ant-checkbox-wrapper.edit-festat-checkbox {
    margin-top: 5px;
    display: grid;
    height: 80px;
    span.ant-checkbox {
      margin-left: 105px;
      width: 23px;
      margin-bottom: 5px;
    }
    span.ant-checkbox-inner {
      width: 23px;
      height: 23px;
      background: #f1f7fb;
    }
    .ant-checkbox-inner:after {
      width: 7px;
      height: 14px;
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
      position: absolute;
      display: table;
      border: 2px solid #1da193;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
  }

  .footer-edit-card {
    display: flex;
    width: 100%;
    .icon-del {
      width: 32px;
      border-radius: 5px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ea3943;
      margin-right: 15px;
      cursor: pointer;
    }
    .icon-goBack {
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      margin-right: 15px;
      border-radius: 5px;
      height: 32px;
      background: #f1f7fb;
      cursor: pointer;
    }
    button.ant-btn.ruaj-foter-card {
      background: #1da193;
      border-radius: 5px;
      color: #fff;
      height: 32px;
      width: 130px;
    }
    path#Path_7433 {
      fill: #fff !important;
    }
  }
}

.Fitimet-view-card {
  .pagat-fitimet-excel-container {
    display: none !important;
  }
}

button#fitimet-table-xls-button {
  border: none;
  width: 32px;
  margin-right: 15px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #1da193;
  cursor: pointer;
}

@media (max-width: 1700px) {
  .pagat-fitimet-setings {
    .ant-col {
      width: 33% !important;
    }
  }
}
@media (max-width: 1220px) {
  .pagat-fitimet-setings {
    .ant-col {
      width: 49% !important;
    }
  }
}
@media (max-width: 1000px) {
  .pagat-fitimet-setings {
    .ant-col {
      width: 97% !important;
    }
  }
}
@media screen and (min-device-width: 900px) and (max-device-width: 1181px) and (orientation: landscape) {
  .pagat-fitimet-setings .ant-col {
    width: 33% !important;
  }
  .ag-grid-punojsit {
    width: 96% !important;
  }
  .ant-drawer-content-wrapper {
    width: 920px !important;
  }
  .single-notif .notif-content {
    flex: 5.5 1;
  }
  .te-dhenat-akses .hedaer-forth-col button.ant-btn.te-gjith-btn {
    margin-top: 20px !important;
    margin-bottom: 7px !important;
  }
  button.ant-btn.fshij-btn {
    width: 155px !important;
  }
}
@media screen and (min-device-width: 900px) and (max-device-width: 1024px) and (orientation: landscape) {
  .switch-div {
    height: 35px;
  }
  .konfigurimet-detjat .body-kryefaqja {
    display: block !important;
  }
  .te-dhenat-akses {
    .ant-card {
      height: 100% !important;
    }
  }
  .konfigurimet-detjat .body-kryefaqja h3 {
    justify-content: flex-start !important;
    margin-right: 10px;
  }
  .ant-select.ant-select-borderless.ant-select-single.ant-select-show-arrow {
    width: 185px !important;
  }
}

@media (max-width: 450px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.krijo-modal {
    .ant-drawer-content-wrapper {
      width: 390px !important;
    }
  }
  .pagat-fitimet-setings {
    .pagat-fitimet-header {
      // height: 100px !important;
      display: grid;
      grid-template-columns: auto auto;
      button.ant-btn.krijo-btn {
        margin-left: 0px;
        margin-right: 15px;
      }
      .toogleDiv {
        display: none;
      }
    }
    .ant-row {
      // margin-left: 10px !important;
      padding-right: 10px;
      grid-template-columns: 100% !important;
      .card-fitimet {
        width: 100% !important;
      }
      .ant-col {
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}
