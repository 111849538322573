aside.ant-layout-sider.ant-layout-sider-dark {
  // background: #1D3445;
  // Autumn theme
  background: #fbfbfb;
  width: 270px;
  max-width: 270px;
  min-width: 71px;
  display: flex;
  max-height: -webkit-fill-available;
  min-height: 100vh;
  box-shadow: 0px 1px 4px 0px #00000029;
}

.ant-layout-sider-trigger {
  // background: #1D3445 !important;
  // Autumn theme
  background: #1d3445 !important;
  transition: width 1s;
  position: absolute !important;
}
.ant-layout-sider {
  transition: width 1.8s !important;
}

.main-sidebar {
  // path#Path_7748 {
  //   fill: #fff !important;
  // }
  // path#Path_7749 {
  //   fill: #fff;
  // }
  // path#Path_7750 {
  //   fill: #fff;
  // }
  // path#Path_7751 {
  //   fill: #fff;
  // }
  // path#Path_7752 {
  //   fill: #fff;
  // }
  // path#Path_7753 {
  //   fill: #fff;
  // }
  svg {
    fill: #1d3445 !important;
    color: #1d3445 !important;
  }
  li.ant-menu-item.ant-menu-item-active.sigurt {
    background: #fbfbfb !important;
  }
  .ant-menu-item {
    background-color: #fbfbfb;
    display: flex;
    width: 270px;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px !important;
    // font open sans
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: #1d3445;

    a {
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      color: #1d3445;
    }
    // active
    &.ant-menu-item-active {
      background-color: #1d3445 !important;
    }
    &.ant-menu-item-active {
      background-color: #dce4e9 !important;
      color: #1d3445 !important;
      a {
        color: #1d3445 !important;
      }
      // svg {
      //   fill: #1d3445 !important;
      //   color: #1d3445 !important;
      // }
    }
    &.ant-menu-item-selected {
      background-color: #dce4e9 !important;
      color: #1d3445 !important;
      svg {
        fill: #1d3445;
      }
    }
  }
}
// span.anticon.anticon-left {
// 	background: #fff;
// 	color: #EA3943;
// 	width: 32px;
// 	height: 32px;
// 	margin-left: 252px;
// 	border-radius: 5px;
// 	padding-top: 10px;
// }

// span.anticon.anticon-right {
// 	background: #fff;
// 	color: #239281;
// 	width: 32px;
// 	height: 32px;
// 	margin-left: 62px;
// 	border-radius: 5px;
// 	padding-top: 10px;
// }

.sidebar-logo {
  width: 160px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // border-radius: 50%;
  margin-left: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.sidebar-logo img {
  border-radius: 50%;
}

.side-bar .sidebar-icona {
  padding-right: 10px;
}

.side-bar ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  // background: #1D3445;
  //Autunm theme
  background: #fbfbfb !important;
  border: none;
  font-size: larger;
  transition: width 1.8s;
}

// .side-bar li.ant-menu-item.ant-menu-item-selected {
//   // background: #1D3445 !important;
//   // Autumn Theme
//   background: white;
//   color: #1d3445 !important;
//   width: 270px;
//   svg {
//     // fill: black !important;
//     // color: black !important;
//     path {
//       fill: # !important;
//     }
//   }
//   span {
//     a {
//       color: #1d3445 !important;
//     }
//     svg {
//       fill: #1d3445 !important;
//       color: black !important;
//     }
//   }
// }

// .side-bar .ant-menu-inline.ant-menu-root .ant-menu-item,
// .ant-menu-inline.ant-menu-root {
//   color: #fff;
//   height: 50px;
//   margin-top: 3px !important;
//   margin-bottom: 3px !important;
//   a {
//     color: #fff;
//   }
//   &:hover,
//   &:focus,
//   &:active {
//     // background: #1D3445;
//     // Autumn Theme
//     background: #1d3445;
//     color: black !important;
//     border: none;
//     width: 270px;
//   }
// }

.side-bar .ant-menu-inline .ant-menu-item,
.ant-menu-inline {
  width: 270px;
}

.side-bar .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  width: 0;
  height: 0;
  margin-top: auto;
  margin-bottom: auto;
  border-top: 10px solid transparent !important;
  border-right: 10px solid #1d3445 !important;
  border-bottom: 10px solid transparent !important;
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
  .sidebar-logo {
    width: 80px;
    height: 180px;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
    // background: url(../../assets/images//flexicon-07.png);
    // background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    svg#Group_6130 {
      display: none;
    }
  }
  .sidebar-user-name {
    width: 100%;
    display: flex;
    padding-left: 0px;
    justify-content: center;
    align-items: center;
    h3 {
      display: none;
    }
  }
}

.side-bar
  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed {
  background-color: #1d3445;
  border: none;
  color: #fff;
  height: 50px;
  &:hover,
  &:focus,
  &:active {
    color: #f5f5f7;
  }
  .side-bar li.ant-menu-item.ant-menu-item-selected {
    width: 79px;
    color: #f5f5f7;
  }
}

.side-bar .ant-menu-light .ant-menu-item:hover,
.ant-menu-light,
//  .ant-menu-item-active
.ant-menu-light .ant-menu:not(.ant-menu-inline),
.ant-menu-light,
.ant-menu-light {
  color: #f5f5f7;
  //   background: #1d3445;
}

.Focus-Show {
  color: #f5f5f7;
  //   background: #1d3445;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
  .ant-layout-sider-trigger {
    width: 100% !important;
    height: 70px !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: 15px;
    background: transparent !important;
    span.anticon.anticon-left {
      background: #fff;
      border-radius: 5px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 4px #00000029;
      svg {
        background: url("../../assets/icons/sidebar_iconClose.svg") no-repeat;
        background-position: center;
        background-size: contain;
        width: 14px !important;
        height: 14px !important;
        z-index: 200;
      }
    }
    span.anticon.anticon-right {
      background: #fff;
      border-radius: 5px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 4px #00000029;
      padding: 0px !important;
      svg {
        background: url("../../assets/icons/sidebar_iconOpen.svg") no-repeat;
        background-position: center;
        background-size: contain;
        width: 14px !important;
        height: 14px !important;
        z-index: 200;
      }
    }
  }
}

// aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
//   li.ant-menu-item.sigurt {
//     // padding-left: 32px !important;
//   }
// }

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed {
  width: 79px;
  flex: 0 0 80px;
  max-width: 80px;
  min-width: 80px;
  width: 80px;
  height: 50px;
  padding-top: 5px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.side-bar {
  .ant-menu.ant-menu-inline-collapsed {
    .ant-menu-item {
      padding: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .iconWrapper > * {
      display: flex;
      align-items: center;
    }
  }
}

// .ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
//   .ant-tooltip-inner {
//     background: #1d3445 !important;
//     height: 50px;
//     justify-content: flex-start !important;
//     padding-right: 15px !important;
//     align-items: center;
//     display: flex;
//     font-size: medium;
//     margin-left: -15px;
//     border-bottom-right-radius: 5px !important;
//     border-top-right-radius: 5px !important;
//     box-shadow: none !important;
//   }
//   .ant-tooltip-arrow {
//     display: none !important;
//   }
// }

.side-bar {
  svg#Layer_1 {
    fill: #1d3445 !important;
  }
}

.sidebar-user-name {
  // width: 100%;
  height: 50px;
  h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    margin-left: 5px;
    font-variant: tabular-nums;
    font-feature-settings: "tnum";
  }
  display: flex;
  justify-content: flex-start;
  padding-left: 23px;
  align-items: center;
}
// .sidebar-user-name:hover {
//   background: #1d3445;
// }

.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
  .ant-tooltip-content {
    margin-left: -4px;
  }
}
// .Focus-Show {
//   color: #f5f5f7;
//   background: #1d3445;
// }
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #1d3445;
  color: #1d3445 !important;
  fill: #1d3445 !important;

  // width: 100%;
  // height: 100%;
  svg {
    width: 20px;
    height: 20px;
    color: #1d3445 !important;
    fill: #1d3445 !important;
  }
}
