.timesheet-wrapper {
  // width: 1200px;
  //   border: 1px solid black;
  display: flex;
  // width: 100%;
  height: 100%;
  padding: 20px;
  // flex-direction: row;
  gap: 10px;

  .timesheet-calculations {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0px;
    gap: 20px;
    // margin-left: 20px;
    flex: 11;
    // border: 1px solid red;
    .timesheet-header {
      width: 100%;
    }

    .timesheet-progress-wrapper {
      // margin: 20px;
      display: flex;
      flex-direction: column;
      // max-height: 705px;

      // min-width: 100%;
      overflow-y: scroll;
    }
  }
  .timesheet-statistics {
    flex: 2.5;
    // border: 1px solid green;
    margin-top: 20px;
    margin-right: 20px;
    max-height: 500px;
    height: 430px;
    margin-top: 60px;
    .statistic-name {
      // width: 478px;
      flex: 1;
      height: 32px;
      background: #1d3445 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      font: normal normal 600 18px/24px Open Sans;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }
  }
}

.time-sheet-header-comp {
  // margin: 20px;
  display: flex;
  gap: 20px;
  overflow-x: auto;
  .search-input {
    width: 205px;
    .ant-select-clear {
      background-color: #f1f7fb !important;
    }
    .ant-select-selector {
      background-color: #f1f7fb !important;
      border: none !important;
      border-radius: 5px !important;
    }
    .ant-select {
      width: 200px;
      border-radius: 5px !important;
    }
  }
  .department-select {
    width: 205px;
    .ant-select-clear {
      background-color: #f1f7fb !important;
    }
    .ant-select-selector {
      background-color: #f1f7fb !important;
      border: none !important;
      border-radius: 5px !important;
    }
    .ant-select {
      width: 200px;
      border-radius: 5px !important;
    }
  }
  .month-filter-btns {
    .active-filter {
      height: 32px;
      background: #f1f7fb 0% 0% no-repeat padding-box;
      color: #1d3445;
    }
    .inactive-filter {
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      color: white;
    }
  }
  .filter-date-range {
    min-width: 300px;
    .ant-picker-clear {
      background-color: #f1f7fb !important;
      padding: 5px;
      margin-top: 2px;
    }
    .ant-picker {
      background-color: #f1f7fb !important;
      border: none !important;
      border-radius: 5px !important;
    }
  }
}

.progress-shift {
  display: grid;
  grid-template-columns: 4fr 20fr 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
  // display: flex;
  // justify-content: baseline;
  // align-items: center;
  // gap: 20px;
  // margin-bottom: 0px;
  .mobileViewTimeSheet {
    display: "flex";
    align-items: "center";
    margin-bottom: 10;
    column-gap: 15;
  }
  .approved-ind-icons {
    height: 43px;
    background-color: grey;
    width: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }
  .clock-out-ind {
    height: 43px;
    margin-left: -10px;
    background-color: #2274a5;
    width: 43px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .total-hours {
    // width: 150px !important;
    text-overflow: ellipsis;
    display: flex;
    margin-left: -10px;
    font: normal normal 600 14px/56px Open Sans;
  }
  .shift-person {
    // width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; // flex: 1;
    font: normal normal 600 14px/56px Open Sans;
    // cursor: pointer;
    // &:hover {
    //   color: #FCC94A;
    // }
  }
  .progress-shift-bar {
    display: flex;
    height: 43px;
    position: relative;
    // background-color: red;
    // background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    // position: relative;
    opacity: 1;
    // animation: chartAnimation 1.9s;
    cursor: pointer;

    .load-progress {
      display: flex;
      justify-content: center;
      // position: absolute;
      background: #f5f5f7 0% 0% no-repeat padding-box;
      overflow: hidden;
      align-items: center;
      color: white;
      opacity: 1;
      animation: chartAnimation 1.9s;
      .time-label {
        animation: appearSoftly 2s;
        white-space: nowrap;
      }
    }
  }
}

.search-input {
  .ant-select {
    width: 200px;
    background-color: #f1f7fb !important;
  }
}

.total-hours {
  font: normal normal 600 14px/56px Open Sans;
}

// Modal for displaying hours per each progress Shift
.progress-shift-modal {
  .ant-modal-body {
    height: 800px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .content-label {
      display: flex;
      justify-content: center;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 600;
    }
    .static-generated-shift {
      background-color: #fcc94a;
      width: 33%;
      height: 40px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 600;
    }
    .dynamic-generated-shift {
      background-color: #7d9223;
      width: 90%;
      height: 40px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 600;
    }
    .static-shift-tellers {
      display: flex;
      // justify-content: space-between;
      width: 100%;
      // height: 130px;
      label {
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 600;
      }
      .google-visualization-tooltip > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: max-content !important;
      }
    }

    .more-details-cont {
      display: flex;
      justify-content: flex-end;
      .more-details-shift {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        color: white;
        width: 130px;
        height: 22px;
        background: #1da193 0% 0% no-repeat padding-box;
      }
    }
    .shift-cards-wrapper {
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: center;
      flex-wrap: wrap;
      animation: appearSoftly 2s;
      flex: 1;
      overflow-y: scroll;
    }
  }
}

.ant-tooltip-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  height: max-content;
  width: max-content;
}

.shift-card {
  width: 236px;
  height: 75px;
  background: #f1f7fb 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  .shifts-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;

    .shift-card-start,
    .shift-card-end {
      display: flex;
      margin-top: 7px;
      flex-direction: column;
      justify-content: center;

      .label-card {
        font: normal normal 600 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        text-align: center;
      }
      .shift-indicator {
        margin-top: 3px;
        width: 103px;
        height: 21px;
        border-radius: 2px;
        text-align: center;
        opacity: 1;
        color: white;
        font: normal normal normal 14px/19px Open Sans;
      }
    }
  }
  .shift-card-total-hours {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

// .timesheet-wrapper .timesheet-statistics {
//   flex: 2.5 1;
//   margin-top: 20px;
//   margin-right: 20px;
//   max-height: 500px;
//   height: 430px;
//   margin-top: 20px;
// }

@keyframes chartAnimation {
  from {
    width: 0;
  }
  to {
    width: 1;
  }
}

@keyframes appearSoftly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1366px) {
  .progress-shift {
    display: grid;
    grid-template-columns: 4fr 8fr 1fr 1fr 2fr;
    align-items: center;
    gap: 10px;
  }
}

@media (max-width: 450px) {
  .progress-shift {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    .mobileViewTimeSheet {
      grid-area: 2 / 1 / 3 / 3;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
    }
  }
  .timesheet-wrapper .timesheet-calculations .timesheet-progress-wrapper {
    margin: 10px !important;
  }
  .timesheet-progress-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    cursor: pointer !important;
  }
  .time-sheet-header-comp {
    justify-content: space-around;
    .department-select {
      margin-left: 0px !important;
    }
  }
  .progress-shift .clock-out-ind {
    width: 60px !important;
  }
  .progress-shift .approved-ind-icons {
    width: 60px !important;
  }
  .progress-shift-modal .ant-modal-body .shift-cards-wrapper {
    height: 225px !important;
    overflow: auto;
  }
  .time-sheet-header-comp .search-input {
    width: 120px;
  }
  .time-sheet-header-comp .search-input .ant-select {
    width: 120px !important;
  }
  .time-sheet-header-comp .department-select {
    width: 120px !important;
  }
  .time-sheet-header-comp .department-select .ant-select {
    width: 120px !important;
  }
  .time-sheet-header-comp .month-filter-btns .inactive-filter {
    width: 120px !important;
  }
  .time-sheet-header-comp .month-filter-btns .active-filter {
    width: 100px !important;
  }
  .time-sheet-header-comp .month-filter-btns {
    margin-left: 0px !important;
  }
  .time-sheet-header-comp .filter-date-range {
    margin-left: 0px !important;
  }
}
