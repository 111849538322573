.te-dhenat-akses {
  width: 100%;
  height: fit-content;
  margin-left: 20px;
  .ant-row.ant-form-item {
    margin: 0px;
  }
  .card-te-dhenat {
    margin-right: 20px;
  }
  .te-dhenat-detajet-header {
    margin: 20px 20px 20px 0px;
    height: 200px;
    border-radius: 10px;
    background: #fff;
    display: flex;
  }
  .hedaer-first-col {
    text-align: center;
    margin-top: 30px;
    h1 {
      font-size: medium;
      font-weight: 400;
    }
    h3 {
      margin: 0px;
      font-size: large;
    }
    button.ant-btn.konfirmo-button {
      border-radius: 5px;
      background: #f1f7fb;
      border: none;
      margin-top: 20px;
      width: 150px;
    }
    button.ant-btn.konfirmo-succes-button {
      border-radius: 5px;
      background: #1da193;
      color: #fff;
      border: none;
      margin-top: 20px;
      width: 150px;
    }
  }
  .hedaer-second-col {
    margin-top: 30px;
    text-align: center;
    h1 {
      font-size: medium;
      font-weight: 400;
    }
    h3 {
      margin: 0px;
      font-size: large;
    }
  }
  .hedaer-third-col {
    margin-top: 30px;
    text-align: center;
    h1 {
      font-size: medium;
      font-weight: 400;
    }
    h3 {
      margin: 0px;
      font-size: large;
    }
  }
  .hedaer-forth-col {
    margin-top: 30px;
    h1 {
      font-size: medium;
      font-weight: 400;
      color: #1da193;
      margin-left: 30px;
    }
    h3 {
      margin: 0px;
      font-size: large;
    }
    button.ant-btn.te-gjith-btn {
      margin-top: 55px;
      background: #1da193;
      color: #fff;
      border-radius: 5px;
      margin-right: 10px;
      width: 155px;
    }
    button.ant-btn.fshij-btn {
      background: #ea3943;
      color: #fff;
      border-radius: 5px;
      width: 130px;
    }
  }
}

.konfigurimet-detjat {
  width: 100%;
  height: 100%;
  .body-kryefaqja {
    display: flex;
    h3 {
      width: 200px;
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
    }
  }
}
.switch-div {
  background-color: #f7f7f7;
  border-radius: 25px;
  width: 100px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.ant-switch {
  height: 1px;
  background-color: #dedede;
  width: 60px;
}
.ant-switch-handle {
  margin-top: -15px;
}

button.ant-switch {
  .ant-switch-handle::before {
    // background: url(../../../../../assets/icons/dislike_icon.svg) no-repeat;
    // width: 25px;
    // height: 25px;
    margin-top: 4px;
    width: 19px;
    height: 19px;
    background-position: center;
    border-radius: 50%;
    z-index: 3;
    background-color: red !important;
  }
}

path#Path_8110 {
  fill: black;
}
button.ant-switch.ant-switch-checked {
  .ant-switch-handle::before {
    // background: url(../../../../../assets/icons/like_icon.svg) no-repeat;
    // width: 25px;
    // height: 25px;
    margin-top: 4px;
    width: 19px;
    height: 19px;
    background-position: center;
    border-radius: 50%;
    z-index: 3;
    background-color: #1da193 !important;
  }
}

.konfigurimet-detjat-fotter {
  // background: #dedede;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  // height: 50px;
  button.ant-btn.ant-btn-default.ruaj-ndrryshimet-btn {
    background: #1da193;
    color: #fff;
    border-radius: 5px;
    border: none;
    margin-left: 20px;
  }
  button.ant-btn.ant-btn-default.te-gjith-perdorusit {
    color: #fff;
    border-radius: 5px;
    border: none;
    background: #1da193;
  }
}

@media (max-width: 391px) {
  .te-dhenat-akses {
    .te-dhenat-detajet-header {
      height: auto;

      display: grid;
      img {
        width: 200px;
        border-radius: 10px;
        margin-left: 35px;
        margin-top: 10px;
      }
    }
  }
  .ant-row.te-dhenat-row {
    margin-left: 0px !important;
    padding: 0px !important;
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    .ant-col.ant-col-6.gutter-row {
      max-width: 100% !important;
    }
    .hedaer-first-col {
      width: 100% !important;
    }
    .hedaer-forth-col {
      width: 100%;
      justify-content: center;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .te-dhenat-akses .hedaer-forth-col button.ant-btn.fshij-btn {
    width: 120px;
    text-align: center !important;
    padding: 0px;
  }
  .te-dhenat-akses .hedaer-forth-col button.ant-btn.te-gjith-btn {
    width: 120px;
    text-align: center !important;
    padding: 0px;
  }
  .te-dhenat-akses {
    .card-te-dhenat {
      .card {
        height: 520px;
      }
      .ant-card {
        height: 100% !important;
      }
      .ant-row.ant-row-center {
        display: grid;
        grid-template-columns: auto auto;
        .ant-col.ant-col-6.gutter-row {
          max-width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 821px) {
  .konfigurimet-detjat {
    .ant-row.kryefaqja-detajet-row {
      height: 170px !important;
    }
  }

  .konfigurimet-detjat {
    .ant-row.route-acces-konfigurimet {
      height: 950px;
    }
  }
  .konfigurimet-detjat {
    .ant-row.konfigurimet-details-card-row {
      height: 560px;
    }
  }
}

@media (max-width: 450px) {
  .te-dhenat-akses {
    padding-left: 10px;
    margin-left: 0px !important;
    .te-dhenat-detajet-header {
      height: 100% !important;
      margin: 20px 10px 20px 0px;
      img {
        display: none;
      }
      .ant-row.te-dhenat-row {
        padding-left: 5px !important;
        display: grid;
        grid-template-columns: auto auto;
        .ant-col-6 {
          max-width: 100% !important;
          width: 100% !important;
        }
        .hedaer-first-col {
          margin-top: 10px;
          max-width: 100% !important;
          width: 100% !important;
          h1 {
            background: #1da193;
            color: #fff;
            font-size: 15px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          h3 {
            font-size: 17px !important;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background: #1da193;
            color: #fff;
          }
        }
        .hedaer-second-col {
          margin-top: 10px;
        }
        .hedaer-third-col {
          margin-top: 10px;
        }
        .hedaer-forth-col {
          margin-top: 10px;
          margin-left: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .card-te-dhenat {
    margin-right: 10px !important;
    .ant-row.kryefaqja-detajet-row {
      height: 330px !important;
      display: grid !important;
      grid-template-columns: 160px 160px !important;
      h3 {
        font-size: 10px !important;
      }
    }
  }

  .konfigurimet-detjat .ant-row.route-acces-konfigurimet {
    height: 1500px !important;
    display: grid !important;
    grid-template-columns: 160px 160px;
    .ant-col.ant-col-6.gutter-row-route-acces-konfigurimet {
      height: 60px !important;
      h3 {
        font-size: 10px !important;
      }
    }
  }
  .konfigurimet-detjat {
    .ant-row.konfigurimet-details-card-row {
      height: 900px !important;
      display: grid !important;
      grid-template-columns: 160px 160px;
      h3 {
        font-size: 10px !important;
      }
    }
  }
}

.no-photo {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  border: #1d3445 solid 2px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
