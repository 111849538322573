.info-rreth-kompanis {
  width: 100%;
  height: 100%;
  // padding-top: 30px;.
  // .sortable-list-container {
  //   height: calc(100vh - 195px);
  //   overflow: auto;
  //   margin-right: 15px !important;
  // }
  // .sortable-item-style {
  //   height: 380px !important;
  // }
  .ant-row.ant-form-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  // .ant-form-item-explain.ant-form-item-explain-connected{
  //     display: none;
  // }
  .info-rreth-kompanis-footer {
    background: #fff;
    height: 50px;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    gap: 30px;
    margin-right: 100px;
    .ant-form-item {
      margin: 0px !important;
    }
  }
}

.Informacione-Kontakti-Div {
  .card {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
}

.Detajet-Kompania-Card-Div {
  .card {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    // height: 333px !important;
    margin: 0px;
  }
}

.detajet-kompania-card {
  width: fit-content;
}

.input-dropdown {
  display: flex;
  width: 600px;
}

.konfigurimet-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 70px;
  background: #fff;
  margin-top: 30px;
  button.ant-btn.shiko_ndryshimet_btn {
    width: 150px;
    height: 32px;
    border-radius: 5px;
    color: #fff;
    background: #1da193;
    margin-right: 20px;
  }
  button.ant-btn.ruaj_ndryshimet_btn {
    width: 150px;
    height: 32px;
    border-radius: 5px;
    color: #fff;
    background: #1da193;
    margin-right: 50px;
  }
}

.cards-konfig {
  margin-top: 35px;
}

// css for inputs in cards
.input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  span.star-color {
    color: #ff4e00;
  }
  h3 {
    font-weight: 600;
    text-align: right;
    font-size: larger;
    width: 125px;
    margin-right: 10px;
  }
  input.ant-input {
    background: #f5f5f7;
    border: 1px solid transparent;
    border-radius: 5px;
  }
  span.card-input-icon {
    background: #1da193;
    margin-left: 15px;
    width: 23px;
    height: 23px;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: flex;
    // border-radius: 5px;
    svg {
      color: #fff;
    }
  }
  .ant-input-group-addon {
    border: 0px !important;
    background: #f5f5f7;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// scss for dropdown
.dropdown {
  display: flex;
  align-items: center;
  span.star-color {
    color: #ff4e00;
  }
  h3 {
    font-weight: 600;
    text-align: right;
    font-size: larger;
    // width: 100%;
    margin-right: 10px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f5f5f7 !important;
    border-radius: 5px;
  }
  span.card-input-icon {
    background: #1da193;
    margin-left: 15px;
    width: 23px;
    height: 23px;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: flex;
    border-radius: 5px;
  }
  span.anticon.anticon-caret-down {
    color: #000;
  }
  svg {
    width: 15px;
    height: 15px;
  }
  .ant-select-selection__placeholder {
    color: #bababe !important;
  }
}

.cards-konfig {
  .react-tel-input.number-input {
    background: #f5f5f7;
    border-radius: 5px;
    input.form-control {
      background: transparent;
      border: none;
    }
    .flag-dropdown {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}

.ant-select-dropdown.dropdown-konfiguro.ant-select-dropdown-placement-bottomLeft {
  background: #f5f5f7 !important;
  padding: 0px;
  border-radius: 5px;
  .ant-select-item.ant-select-item-option {
    border-bottom: 2px solid #efefef;
  }
  .ant-select-item.ant-select-item-option:hover {
    background: #dfdfde;
  }
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background: #f5f5f7 !important;
  }
}

// dynamic component scss for checkbox
.dynamic-checkbox {
  label.ant-checkbox-wrapper {
    align-items: center;
    display: flex;
    height: 25px;
    margin-top: 20px;
    margin-left: 20px;
  }
  .ant-checkbox-inner {
    background: #1da193 !important;
    width: 25px;
    height: 25px;
  }
  .ant-checkbox-inner:after {
    width: 6px;
    height: 15px;
  }
  h5 {
    margin-top: 10px;
    font-size: medium;
    font-weight: 500;
  }
}

.cards-konfig .ant-form-item-explain.ant-form-item-explain-connected {
  display: none;
}

.cilsimet_perzgjedhura_checkbox {
  margin-left: 40px;
  .ant-form-item-explain.ant-form-item-explain-connected {
    display: flex;
    margin-left: 40px;
  }
}

// .cards-konfig .ant-form-item{
//     margin-bottom: 0px !important;
// }

.detajet-kompania-card-responsive {
  .ant-picker.responsive-date-picker {
    width: 300px !important;
  }
}

.detajet-kompania-card {
  .input {
    .ant-form-item-explain.ant-form-item-explain-connected {
      margin: 0px;
      padding: 0px;
    }
    .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
      margin: 0px;
      padding: 0px;
      height: 43px;
    }
  }
}

.info-kontakti {
  // margin-top: 20px;
  input.form-control {
    background: #f5f5f7;
    border: none;
    width: 100%;
  }
  .input {
    height: 47px;
  }
}
.text-area {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  textarea.ant-input {
    background: #f5f5f7 !important;
    width: 500px;
    border-radius: 5px;
    border: none;
    resize: none;
  }
  span.card-input-icon {
    background: #1da193;
    margin-left: 15px;
    width: 23px;
    height: 23px;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: flex;
    border-radius: 5px;
    svg {
      color: #fff;
    }
  }
  span.star-color {
    color: #ff4e00;
  }
  h3 {
    margin-right: 10px;
  }
  .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
    margin: 0px;
    padding: 0px;
  }
  .ant-form-item-explain.ant-form-item-explain-connected {
    margin: 0px;
    padding: 0px;
  }
}

.info-rreth-kompanis {
  .ant-form-item-label > label {
    font: normal normal 600 16px/22px Open Sans;
  }
}

.emp-month-container {
  height: 283px;
  width: 100%;
  display: flex;
  .items-emp-month {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 20px;
    .div-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      button.ant-btn.ant-btn-default.publikoBtn {
        width: 150px;
        height: 32px;
        border: 0px;
        border-radius: 5px;
        background: #1da193;
        color: #fff;
      }
    }
  }
  .img-emp-month {
    width: 30%;
    height: 100%;
    display: flex;
    .img-div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

@media (max-width: 1800px) {
  .cards-konfig {
    .ant-col {
      display: contents;
    }
  }
}

@media (max-width: 1680px) {
  .info-rreth-kompanis {
    .ant-col {
      display: contents;
    }
    .responsive-row {
      width: 100%;
      display: grid;
      .ant-col {
        width: 100%;
      }
      .input {
        width: 700px !important;
        float: right;
        justify-content: flex-end !important;
        // margin-left: 10px;
        padding-right: 70px;
      }
      h3 {
        width: 180px !important;
      }
    }
    .detajet-kompania-card {
      padding-left: 50px;
    }
  }
  .info-kontakti {
    padding-left: 50px;
  }
  .info-rreth-kompanis .info-rreth-kompanis-footer {
    margin-top: 50px;
    margin-bottom: 20px;
    width: auto;
  }
}

@media (max-width: 900px) {
  .info-kontakti {
    padding-left: 0px;
  }
  .info-rreth-kompanis .detajet-kompania-card {
    padding-left: 0px;
  }
}
@media (max-width: 840px) {
  .info-rreth-kompanis .responsive-row {
    width: 250px;
    display: grid;
  }
  .info-kontakti {
    width: 630px;
  }
  .info-rreth-kompanis .responsive-row .input {
    width: 644px !important;
  }
  .info-rreth-kompanis .text-area textarea.ant-input {
    width: 446px;
  }
  .info-rreth-kompanis .ant-card {
    width: 700px !important;
  }
  .info-rreth-kompanis .ant-picker {
    width: 100% !important;
  }
  .info-rreth-kompanis .responsive-row input.ant-input {
    width: 66%;
  }
}
@media (max-width: 820px) {
  .info-rreth-kompanis {
    .sortable-list-container {
      display: grid;
      .sortable-item-style {
        width: 700px;
      }
      .Informacione-Kontakti-Div {
        width: 100% !important;
      }
      .Detajet-Kompania-Card-Div {
        width: 100% !important;
      }
    }
  }
  .ant-row.responsive-info {
    height: 940px !important;
    .info-kontakti {
      .input {
        width: 500px !important;
      }
    }
  }
  // .ant-row.responsive-row {
  //   .ant-row.ant-form-item {
  //     width: 100% !important;
  //   }
  // }
  .ant-row.responsive-row {
    h3.data-label {
      width: 380px !important;
    }
    .ant-picker {
      width: 315px !important;
    }
  }
  .info-kontakti {
    .ant-input.info-kontatki-input {
      width: 335px !important;
    }
    .react-tel-input.number-input {
      width: 335px !important;
    }
  }
}
@media (max-width: 771px) {
  .info-rreth-kompanis {
    .sortable-list-container {
      .sortable-item-style {
        width: 650px;
      }
      .Informacione-Kontakti-Div {
        width: 100% !important;
      }
      .Detajet-Kompania-Card-Div {
        width: 100% !important;
      }
    }
  }
  // textarea.ant-input {
  //   width: 400px !important;
  // }
  .info-rreth-kompanis {
    .responsive-row {
      .input {
        width: 450px !important;
      }
    }
  }
  .info-kontakti {
    width: 579px;
    .input input.ant-input {
      width: 300px !important;
    }
    .react-tel-input.number-input {
      width: 300px !important;
    }
  }
  .info-rreth-kompanis .ant-card {
    width: 650px !important;
  }
  .info-rreth-kompanis .responsive-row .input {
    width: 597px !important;
  }
}
@media (max-width: 716px) {
  .info-kontakti {
    width: 400px;
    .input input.ant-input {
      width: 200px !important;
    }
    .react-tel-input.number-input {
      width: 200px !important;
    }
  }
  .detajet-kompania-card {
    .input {
      width: 380px !important;
    }
  }
  .info-rreth-kompanis {
    .card {
      height: 100%;
    }
    .ant-card {
      height: 100% !important;
    }
    .detajet-kompania-card {
      width: 400px;
      height: 470px !important;
    }
    textarea.ant-input {
      width: 250px !important;
    }
  }
}

@media (max-width: 450px) {
  .info-rreth-kompanis .sortable-list-container {
    height: 98% !important;
  }
  .info-rreth-kompanis .ant-card {
    width: 100% !important;
  }
  .info-rreth-kompanis .sortable-item-style {
    height: 100% !important;
    width: 400px !important;
  }
  .ant-layout-header {
    height: 70px !important;
    .ant-col.ant-col-16.col-header-name {
      height: 70px !important;
    }
    .header-app .user-info {
      font-size: 11px !important;
      margin-left: 20px;
    }
    .header-app {
      display: grid;
      .ant-col.ant-col-3 {
        height: 70px !important;
        margin-left: -27px !important;
      }
      .ant-col.ant-col-5 {
        margin-left: 180px;
        margin-top: -42px !important;
      }
      .ant-col.ant-col-16.konfig-header {
        height: 70px !important;
        .user-title {
          height: 70px !important;
          font-size: xx-small !important;
          padding-left: 15px;
          h1 {
            padding-left: 5px;
            height: 70px !important;
            font-size: x-small !important;
          }
        }
      }
    }
  }
  .pagat-fitimet-setings .pagat-fitimet-search {
    width: 120px !important;
  }
  .info-kontakti {
    .ant-card-body {
      padding: 5px !important;
    }
    width: 280px;
    .input input.ant-input {
      display: grid !important;
      width: 150px !important;
    }
    .react-tel-input.number-input {
      width: 150px !important;
    }

    .input {
      h3 {
        width: 70px;
        float: left;
        font-size: small;
        margin-right: 5px;
        padding: 0px;
      }
    }
  }
  .info-rreth-kompanis {
    .ant-card-body {
      padding: 5px !important;
    }
  }

  .info-rreth-kompanis {
    .card {
      height: 100%;
    }
    h3 {
      font-size: small;
    }
    .ant-card {
      height: 100% !important;
    }
    .detajet-kompania-card {
      width: 280px;
      height: 455px !important;
      margin-top: 15px;
    }
    textarea.ant-input {
      width: 165px !important;
    }
  }
}
@media (max-width: 450px) {
  .emp-month-card {
    width: 100% !important;
  }
  .emp-month-container .img-emp-month {
    display: none !important;
  }
  .emp-month-container .items-emp-month {
    width: 100% !important;
  }
  .info-kontakti {
    .input {
      width: 295px !important;
    }
  }
  .ant-col.ant-col-3 {
    img {
      width: 65px;
    }
  }
  .ant-layout-header .header-app .ant-col.ant-col-16.konfig-header {
    width: 250px !important;

    .user-title {
      padding-left: 0px !important;
      width: 250px !important;
    }
    .user-title h1 {
      width: max-content !important;
      font-size: 9px !important;
    }
  }

  .ant-row.responsive-info {
    height: 100% !important;
  }
  .ant-row.responsive-info .info-kontakti .input {
    width: 290px !important;
  }
  .ant-picker.ant-picker-status-success.responsive-date-picker {
    width: 110px !important;
  }

  .detajet-kompania-card-responsive {
    .kodi-postar {
      width: 135px !important;
    }
    .kodi-postar-label {
      width: 90px !important;
    }
    .ant-row.res-kompania-row {
      padding-top: 10px;
      display: grid !important;
      .ant-col {
        max-width: 94%;
      }
    }
  }

  .detajet-kompania-card-responsive .ant-picker.responsive-date-picker {
    width: 110px !important;
  }
  .detajet-kompania-card-responsive {
    h3.data-label {
      width: 120px !important;
    }
    h3.data-label-email {
      width: 120px !important;
    }
    .email-kompanis-input {
      width: 110px !important;
    }
  }
}
