@import "../../../index.scss";

.normalSizedModal {
  .ant-modal {
    min-width: 50% !important;
    width: fit-content !important;
  }
  .ant-modal-content {
    border-radius: 7px;
    overflow: hidden;
    width: fit-content;
    margin: auto;
  }
  //* styles modal header align-items:center, justify-content:center
  .ant-modal-header {
    @include flex(center, center);
    background-color: #f8f8f8;
    padding: 22px;
  }
  //* styles modal title with font-size:22px, font-weight:bold, letter-spacing:0.41px, and text color
  .ant-modal-title {
    font-family: Helvetica;
    @include text(22px, bold, 0.41px, #383554);
    background-color: #f8f8f8;
  }
  .ant-modal-body {
    padding-bottom: 33px;
  }
  //* styles subtitle text with font-family:15px, font-weight:600, letter-spacing:0.24 and text color
  .subtitle {
    @include text(15px, 600, 0.24px, #929292);
    margin-bottom: 23px;
  }
  //* styles footerContainer align-items:center, justify-content:flex-end
  .footerContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 10px 40px;
    background-color: #f8f8f8;
    @include flex(center, flex-end);
  }
  .cancelFooterButton {
    background-color: #f3f3f3;
    box-shadow: 2px 2px 2px #00000012;
    border: 1px solid #bfbfbf;
    border-radius: 7px;
    color: #383554;
    margin-right: 15px;
  }
  .secondaryFooterButton {
    letter-spacing: 0.24px;
    color: #1da193;
    border: 1px solid #1da193;
    border-radius: 2px;
    margin-right: 15px;
  }
  .primaryFooterButton {
    background-color: #4791ff;
    color: #fff;
    font-family: Helvetica;
    box-shadow: 2px 2px 2px #00000012;
    border: 1px solid #4791ff;
    border-radius: 7px;
  }
}
//* styles text font-size:16px, font-weight:400, letter-spacing:0, and text color
.question {
  @include text(16px, 400, 0, #1f2a44);
  margin-bottom: 20px;
}
.ant-modal-wrap.cardLayoutModal.documentationTemplatesModal.ant-modal-centered {
  .ant-modal-header {
    height: 45px;

    //* styles documentationTemplatesModal title text font-size:16px, font-weight:600, letter-spacing:0, and text color
    .ant-modal-title {
      @include text(16px, 600, 0, #323338);
    }
  }

  .ant-modal-body {
    padding: 20px;
  }
}
//* styles documentationTemplatesModal question text font-size:16px, font-weight:400, letter-spacing:0, and text color
.question {
  @include text(16px, 400, 0, #1f2a44);
  margin-bottom: 20px;
}
//* styles buttonContainer align-items:center, justify-content:center
.buttonContainer {
  width: 100%;
  @include flex(center, center);
  //* except last element of the div give every other element margin-right:15px
  .ant-btn {
    border-radius: 5px;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

//* modal responsive for iPad Air
@media (max-width: 821px) {
  .normalSizedModal .ant-modal-content {
    max-width: 100%;
  }
  .ant-steps-item-title {
    padding-right: 0px;
    padding-left: 30px;
  }
}
