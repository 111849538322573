.InfoOvertimeModal {
  .ant-modal-content {
    width: 434px;
    height: 273px;
    background: #ffffff 0% 0% no-repeat padding-box;
    // background: red;
    // background: #1DA193;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 5px;
    opacity: 1;
  }
  .lejeRequestOvertime {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .questionRequest {
      width: 202px;
      height: 22px;
      font: normal normal 600 16px/19px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
      margin-top: 12px;
    }
    .greetRequest {
      width: 203px;
      height: 19px;
      text-align: center;
      font: normal normal normal 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #000000;
      margin-top: 10px;
    }
  }
  .footerActionBtn {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 75px;
    .mbyllFaqen {
      width: 150px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      opacity: 1;
      border-radius: 5px;
      color: #ffffff;
    }
    .createBtn {
      width: 180px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #ffffff;
    }
  }
}
