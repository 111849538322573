@use "ag-grid-community/styles" as ag;
@include ag.grid-styles(
  (
    theme: alpine,
  )
);
@import "../../index.scss";
// @import "ag-grid-community/styles/ag-grid.scss";
// @import "ag-grid-community/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

//* styles documentationPageWrapper justify-content:center, align-items:flex-start, and flex-direction:column
.problematikatPageWrapper {
  background-color: #f4f5f6;
  height: 100%;
  width: 100%;
  padding: 20px;
  width: calc(100vw - 275px);
  @include flex(center, flex-start, column);

  .docGridCard {
    margin: 0;
    // margin-top: 20px;
    flex: 10;
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto;

    .childrenContainer {
      height: 100%;
    }
  }
  .agGridSearchInput {
    background-color: #f1f7fb;
    margin-left: 10px;
    border: none;
  }
  .headerSearch {
    background-color: #f1f7fb;
    display: flex;
    // margin-top: -40px;
    // margin-bottom: 10px;
    margin-left: 20px;
    border-radius: 5px;
    height: 32px;
  }

  .headerSearchContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: -40px;
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .headerButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .pdfButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #ea3943 !important;
    border-radius: 5px;
  }

  .iconSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    path#Path_6858_00000086666139294581268680000013212754614146113725_ {
      fill: #1d3445;
    }
  }
  button.ant-btn.ant-btn-default.clearButton {
    background-color: #ea3943;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    width: 80px;
    border-radius: 5px;
    margin-right: 20px;
    margin-left: 20px;
    color: #fff;
    cursor: pointer;
  }
  .documentationsTable {
    width: 100%;
    height: 600px;

    .ag-body-horizontal-scroll {
      min-height: 5px !important;
    }

    .ant-btn {
      background-color: #1264a3;
      border: 0;
      border-radius: 5px;

      &:hover {
        background-color: #0f5c97;
      }
    }
  }

  //* styles statusCard width:fit-content, height:30px, border-radius:6px, and background-color:unset,and justify-content:center, and align-items:center
  .statusCard {
    @include square(fit-content, 30px, 6px, unset);
    @include flex(center, center);
    min-width: 200px;
    letter-spacing: 0px;
    text-transform: uppercase;
    padding: 0 10px;
    margin-top: 6px;
  }

  .centeredCell {
    justify-content: center;

    //* styles ag-react-container justify-content:center, align-items:center
    .ag-react-container {
      @include flex(center, center);
      margin-top: 6px;
    }
  }

  a {
    color: #1264a3;
    font-weight: 600;
  }
}

.light-ag-theme [class^="ag-"],
.light-ag-theme [class^="ag-"]:focus,
.light-ag-theme [class^="ag-"]:after,
.light-ag-theme [class^="ag-"]:before {
  border: none !important;
}

.light-ag-theme .ag-header-cell-resize {
  display: none !important;
}

.light-ag-theme {
  // @include ag.ag-theme-alpine(
  //   (
  //     border-radius: 0px,
  //   )
  // );

  .ag-header-row {
    background-color: #1d3445;
  }

  .ag-header-cell {
    //* styles ag-header-cell-text font-size:16px, font-weight:600, letter-spacing:0, and text color
    .ag-header-cell-text {
      @include text(16px, 600, 0, #fff);
    }
    &:hover {
      background-color: #1d3445;
    }
  }

  .ag-row-odd {
    background-color: #f5f5f7 !important;
  }

  .ag-cell {
    border-right-color: #f5f5f7 !important;
  }

  .ag-cell-value {
    border-right-color: #f5f5f7 !important;
  }

  .ag-roow-wrapper {
    border: 0;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .ag-ltr .ag-side-bar-right {
    border: 0;
    color: #757575;
    fill: #757575;
  }

  .ag-column-drop {
    background-color: #fff;
    border-bottom: 0;
  }

  .ag-column-drop-horizontal-title-bar {
    margin-right: 10px;
  }

  .ag-checkbox .ag-input-wrapper,
  .light-ag-theme .ag-radio-button .ag-input-wrapper {
    background: #1d3445;
    width: 20px;
    height: 18px;
    font-size: 20px;
  }
  .ag-header-icon {
    color: #fff;
  }
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    color: #fff;
  }
}

.dark-ag-theme {
  // @include ag-theme-alpine(
  //   (
  //     border-radius: 0px,
  //   )
  // );

  .ag-header-cell-resize {
    &:after {
      width: 0px !important;
    }
  }

  .ag-row {
    background-color: #292b3a;
    color: #f5f5f5;
    border-color: #fff;
  }

  .ag-row-even {
    background-color: #1f212d;
    color: #f5f5f5;
    border-color: #4a4a4a;
  }

  .ag-header-row {
    border-top: 1px solid #4a4a4a;
    height: 100% !important;
  }

  .ag-header {
    background-color: #1264a3 !important;
    border-color: #4a4a4a;
    height: 120px !important;
  }

  .ag-header-cell {
    color: #f5f5f5;
    padding: 0 !important;

    .hederDivContainer {
      width: 100%;
      height: 100%;
      font-size: 16px;
      overflow: hidden;

      .letterContainer {
        width: 100%;
        height: 36px;
        padding: 5px 0;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 2px solid #4a4a4a;
        border-right: 2px solid #4a4a4a;
      }

      .textContainer {
        width: 100%;
        padding-top: 3px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 2px solid #4a4a4a;
      }

      .textContainerStretched {
        width: 100%;
        height: 50px;
        padding-top: 3px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #4a4a4a;
      }

      .actionsContainer {
        width: 100%;
        height: 26px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 2px solid #4a4a4a;
      }
    }

    &:hover {
      background-color: #4a4a4a;
    }
  }

  .ag-cell-value {
    justify-content: center !important;
  }

  .ag-root-wrapper {
    border: 0;
  }

  .ag-ltr {
    .ag-cell {
      border-right: 1px solid #4a4a4a !important;
      font-size: 16px !important;
      font-weight: 450 !important;
    }

    .ag-side-bar-right {
      border: 0;
      color: #757575;
      fill: #757575;
    }
  }

  .ag-column-drop {
    background-color: #fff;
    border-bottom: 0;
  }

  .ag-column-drop-horizontal-title-bar {
    margin-right: 10px;
  }

  .ag-center-cols-viewport {
    background: #1f212d;
  }

  .ag-body-horizontal-scroll-viewport {
    background: #1c1c1c;
  }

  ::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 5px;
    &:hover {
      background-color: #35415e;
    }
  }
}
button#problematika-table-xls-button {
  background-color: #1da193;
  cursor: pointer;
  border: none;
  height: 32px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grey-ag-theme {
  // @include ag-theme-alpine(
  //   (
  //     border-radius: 0px,
  //   )
  // );

  .ag-header-cell-resize {
    &:after {
      width: 0px !important;
    }
  }

  .ag-header-row {
    background-color: #fff;
    height: 100% !important;
  }

  .ag-row-odd {
    background-color: #f5f5f7 !important;
  }

  .ag-row-even {
    background-color: #f5f5f7 !important;
  }

  .ag-header {
    border-color: #4a4a4a;
    height: 120px !important;
  }
  .ag-paging-panel .ag-unselectable {
    height: 45px !important;
  }

  .ag-header-cell {
    color: #757575;
    padding: 0 !important;

    .headerDivContainer {
      width: 100%;
      height: 100%;
      font-size: 16px;
      overflow: hidden;

      .letterContainer {
        width: 100%;
        height: 36px;
        padding: 5px 0;
        font-size: 16px;
        font-weight: 500 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #dcdfe3;
        border-right: 1px solid #dcdfe3;
        border-top: 1px solid #dcdfe3;
        color: black;
        background: #cfcece;
      }

      .textContainer {
        width: 100%;
        padding-top: 3px;
        font-size: 16px;
        font-weight: 500 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #cfcece;
        color: black;
        background: #e7e7e7;
      }

      .textContainerStretched {
        width: 100%;
        height: 50px;
        padding-top: 3px;
        font-size: 16px;
        font-weight: 500 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #cfcece;
        color: black;
        background: #e7e7e7;
      }

      .actionsContainer {
        width: 100%;
        height: 26px;
        font-size: 16px;
        font-weight: 500 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #cfcece;
        color: black;
        background: #e7e7e7;
      }
    }

    &:hover {
      background-color: #f2f2f2;
    }
  }

  .ag-cell {
    border-right: 0 !important;
    justify-content: center !important;
    font-size: 16px !important;
    font-weight: 450 !important;
  }

  .ag-cell-value {
    border: 0;
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-ltr {
    .ag-cell {
      border-right: 1px solid #dcdfe3 !important;
    }

    .ag-side-bar-right {
      border: 0;
      color: #757575;
      fill: #757575;
    }
  }

  .ag-paging-panel {
    border: 0;
  }

  .ag-column-drop {
    background-color: #fff;
    border-bottom: 0;
  }

  .ag-column-drop-horizontal-title-bar {
    margin-right: 10px;
  }
}

//======================================================================

.dark-ag-theme-details {
  // @include ag-theme-alpine(
  //   (
  //     border-radius: 0px,
  //   )
  // );

  //! ag-header-row ag-header-row-column-group
  .ag-header-row {
    background-color: #fff;
  }

  .ag-header-row-column-group {
    background-color: black !important;
    border-bottom: 1px solid #525151 !important;

    .ag-icon {
      color: #f2f2f2;
    }
  }

  .ag-header-cell {
    background: black;
    border-bottom: 1px solid #525151 !important;

    &:hover {
      background: darkgray !important;
    }

    &:active {
      background: darkgray !important;
    }

    .ag-header-cell-text {
      @include text(16px, 600, 0, #f2f2f2);
    }
    &:hover {
      background-color: #f2f2f2;
    }
  }

  .ag-center-cols-viewport {
    background: #1c1c1c;
  }

  .ag-body-horizontal-scroll-viewport {
    background: #1c1c1c;
  }

  ::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 5px;
    &:hover {
      background-color: #35415e;
    }
  }

  .ag-ltr {
    .ag-cell {
      border-right: 1px solid #525151 !important;
    }

    .ag-side-bar-right {
      border: 0;
      color: #757575;
      fill: #757575;
    }
  }

  .ag-header-viewport {
    background-color: black !important;
  }

  .ag-row {
    background: #262c2e !important;
    color: #f5f5f5;
    border-color: #4a4a4a;
  }

  .ag-row-even {
    background: #343738 !important;
    color: #f7f8fa;
    border-color: #4a4a4a;
  }

  .ag-cell-value {
    border-right-color: #dde2eb !important;
  }

  .ag-root-wrapper {
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .ag-ltr .ag-side-bar-right {
    border: 0;
    color: #757575;
    fill: #757575;
  }

  .ag-paging-panel {
    border: none;
  }

  .ag-column-drop {
    background-color: #fff;
    border-bottom: 0;
  }

  .ag-column-drop-horizontal-title-bar {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .problematikatPageWrapper {
    width: calc(100% - 100px);
    margin-left: 100px;
    margin-top: 110px;
    height: calc(100vh - 110px);
  }
}

.ag-root-wrapper.ag-layout-normal.ag-ltr {
  border-radius: 0 !important;
  box-shadow: 0px 2px 10px 5px rgb(0 0 0 / 16%);
}

//* responsive DocumentationPage for Pc monitor
@media (max-width: 1025px) {
  .problematikatPageWrapper {
    width: auto;
  }
}

//* responsive DocumentationPage for iPad Air
@media (max-width: 821px) {
  .problematikatPageWrapper {
    width: calc(124vw - 275px);
  }
}

//* responsive DocumentationPage for iPad mini
@media (max-width: 768px) {
  .problematikatPageWrapper {
    width: auto;
    margin-top: 0;
    margin-left: 0;
  }
}

//* responsive DocumentationPage for iPhone 12 pro
@media (max-width: 391px) {
  .problematikatPageWrapper {
    margin-top: 0;
    margin-left: 0;
  }
  .light-ag-theme .ag-paging-panel > * {
    margin: 0 10px;
  }
}
