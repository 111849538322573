.createCandidateModal {
  .previewCandidate {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .container-content {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: row;
      gap: 10px;
      &::-webkit-scrollbar {
        width: 5px; /* Set the width of the scrollbar */
      }
      .left-side-content {
        flex: 2 1 0;
        .first-content-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 10px;
          .left-side-container {
            display: flex;
            flex-direction: column;
            // gap: 5px;
            .first-row-first-container {
              display: flex;
              flex-direction: row;
              gap: 5px;
              font-size: 1.8rem;
              font-weight: 600;
              margin-bottom: 10px;
              p {
                margin: 0;
                font-size: 25px;
                font-weight: 600;
                font-family: Open Sans;
              }
            }
            span {
              font-weight: 600;
              font-family: Open Sans;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              margin: 0 0 0 0;
              font-family: Open Sans;
            }
          }
          .right-side-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .custom-status-style {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 150px;
              height: 25px;
              padding: 5px 0;
              margin: 7px 0;
              border-radius: 5px;
              color: white;
              font-weight: 400;
              font-size: 14px;
            }
            .input-row-style {
              display: flex;
              align-content: center;
              gap: 5px;
              text-align: center;
              span {
                font-weight: 600;
                font-family: Open Sans;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                margin: 0px;
                font-family: Open Sans;
              }
            }
          }
        }
        .second-content-container {
          span {
            font-family: Open Sans;

            font-weight: 600;
          }
          p {
            font-family: Open Sans;

            font-size: 14px;
            font-weight: 400;
            margin: 0px;
          }
          .input-password-type {
            display: flex;
            gap: 20px;
            align-items: center;
            .eyeicon-style {
              cursor: pointer;
            }
          }
          .ant-typography {
            color: #2e5d9e;
            font-size: 14;
            font-weight: 600;
          }
          .diviner-line {
            margin: 10px 5px 10px 0;
            width: 200px;
            border-top: 1.5px dashed black;
          }
          .quill-content-container {
            display: flex;
            flex-direction: column;
            .quill-container {
              width: calc(100% - 5px);
              // height: 120px;
              display: flex;
              gap: 5px;
              flex-direction: column;
              .ql-container {
                height: 150px;
                max-height: 150px;
                overflow-y: scroll;
                border: none;
                .ql-editor {
                  background-color: #f1f1f1;
                  border-radius: 5px;
                  width: 100%;
                  // padding: 5px 10px 5px;
                }
              }
            }
          }
        }
      }
      .right-side-content {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .upload-kandidati-button {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px dashed #1d3445;
          border-radius: 5px;
          padding: 10px;
          gap: 10px;
          .upload-kandidati-button-text-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .ant-btn-confirm {
            border-radius: 5px;
            border-color: #20ac9d;
            background-color: #20ac9d;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            .upload-kandidati-button-text {
              font-family: Open Sans;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: center;
            }
          }
        }

        .uploadedFilesSection {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          max-height: 100% !important;
          gap: 5px;
          cursor: pointer;
          .fileCard {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100%);
            // max-width: 275px;
            height: 47px;
            border-radius: 5px;
            background-color: #f1f1f5;
            padding-left: 10px;
            padding-right: 10px;
            .uploadedFileIcon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 12px;
              height: 15px;
            }
            .uploadFileName {
              display: flex;
              align-items: center;
              overflow: hidden;
            }
            .fileName {
              font-family: Open Sans;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              letter-spacing: 0em;
              text-align: left;
              margin-left: 5px;
              max-width: 210px;
              overflow: hidden;
              text-wrap: nowrap;
            }

            .closeIcon {
              // width: 15px;
              // height: 19px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
.kantidatInfoModalSideContainer {
  width: fit-content !important;
  right: 295px !important;
}
@media (max-width: 450px) {
  .createCandidateModal {
    .previewCandidate {
      .container-content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
// @media (max-width: 1500px) {
//   .kantidatInfoModalSideContainer {
//     right: auto !important;
//   }
// }

// @media (max-width: 770px) {
//   .kantidatInfoModal {
//     .ant-modal-content .ant-modal-body .createCandidateModal {
//       gap: 10px;
//       .container-content .left-side-content {
//         .first-content-container {
//           .right-side-container {
//             align-items: end;
//           }
//         }
//         .second-content-container .quill-content-container {
//           .quill-container {
//             width: calc(100% - 5px);
//           }
//         }
//       }
//     }
//   }
// }

// @media (max-width: 637px) {
//   .kantidatInfoModal {
//     .ant-modal-content .ant-modal-body .createCandidateModal {
//       gap: 5px;
//       .container-content .left-side-content {
//         .first-content-container {
//           margin-bottom: 10px;
//           flex-direction: column;
//           .left-side-container {
//             width: 100%;
//           }
//           .right-side-container {
//             width: 100%;
//             align-items: unset;
//           }
//         }
//         .second-content-container .quill-content-container {
//           .quill-container {
//             width: calc(100% - 5px);
//           }
//         }
//       }
//     }
//   }
// }

// @media (max-width: 530px) {
//   .kantidatInfoModal {
//     .ant-modal-content {
//       .ant-modal-body {
//         padding: 10px;
//         height: calc(100vh - 210px);
//         .createCandidateModal {
//           height: calc(100vh - 210px);
//           // height: calc(100% - 5px);
//         }
//       }
//       .ant-modal-footer .flex-between {
//         flex-direction: column;
//         justify-content: center;
//         gap: 5px;
//         .footer-buttons {
//           display: flex;
//           gap: 5px;
//           flex-direction: column;
//           .ant-btn-confirm {
//             margin: 0;
//           }
//         }
//       }
//     }
//     // border: 1px solid black;
//   }
// }
