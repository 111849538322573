@import "../../../../index.scss";

//* styles configDocumentationsContainers justify-content:flex-start, and align-items:space-evenly
.configDocumentationsContainers {
  width: 100%;
  @include flex(flex-start, space-evenly);
  flex-wrap: wrap;

  .newButton {
    @include mondayButton(#71cf48, #68c142);

    .mondayButtonIcon {
      height: 18px;
      width: 18px;
    }
  }

  //* styles gridContainer justify-content:center, align-items:flex-start, and flex-direction:column
  .gridContainer {
    @include flex(center, flex-start, column);
    min-width: 475px;
  }

  //* styles newDocPanel justify-content:center, align-items:center, and flex-direction:column
  .newDocPanel {
    @include flex(center, center, column);
    margin-top: 20px;
    .ant-input {
      background-color: #fff;
      border: 0 !important;
      border-radius: 5px !important;
    }
  }

  //* styles newCatLabel text font-size:18px, font-weight:600, letter-spacing:0, and text color
  .newCatLabel {
    @include text(18px, 600, 0, #323338);
  }

  //* styles newCatButtons justify-content:center, and align-items:center
  .newCatButtons {
    @include flex(center, center);

    .ant-btn:first-child {
      margin-right: 15px;
    }
  }

  //* styles valueContainer justify-content:flex-start, and align-items:space-between
  .valueContainer {
    @include flex(flex-start, space-between);
  }

  .infoContainer {
    display: grid;
    grid-template-columns: [full-start] auto auto [full-end];
    grid-auto-rows: min-content;
    column-gap: 15%;
    row-gap: 14px;
    height: 320px;
    overflow-y: auto;
  }

  .deleteIcon {
    height: 18px;
    cursor: pointer;
    margin: 0 10px;
    &:hover {
      filter: brightness(0.9);
    }
  }

  span.docNumber {
    margin-left: 400px;
    margin-bottom: -55px;
    margin-top: 15px;
  }

  .infoContainer-splash {
    grid-column: full;
  }

  //* styles docName text font-size:16px, font-weight:500, letter-spacing:0, and text color
  .docName {
    @include text(16px !important, 500 !important, 0, #0073ea);
    cursor: pointer;
  }
}

//* responsive DocumentationsPanel for Pc monitor
@media (max-width: 1025px) {
  .configDocumentationsContainers .docName {
    font-size: 23px !important;
  }
  .configDocumentationsContainers {
    gap: 20px;
  }
}

//* responsive DocumentationsPanel for iPad Air
@media (max-width: 821px) {
  .configDocumentationsContainers .valueContainer {
    display: block;
  }
  span.docNumber {
    margin-bottom: -70px !important;
  }
}

@media (max-width: 450px) {
  .documentationStatusesModalContent {
    .addStatusBtn {
      width: 120px !important;
      margin-right: 10px;
      .mondayButtonText {
        padding-left: 5px;
      }
    }
    .documentStatus {
      display: grid !important;
    }
    .configDocumentationsContainers {
      padding: 10px !important;
      span.docNumber {
        margin-left: 90%;
      }
      .gridContainer {
        min-width: 100% !important;
      }
    }
    .documentationCategoryContainer .backButton {
      top: 20px !important;
    }
    .documentationCategoryContainer {
      padding-top: 10px !important;
    }
    .documentationConfigurationContainer {
      padding-top: 10px !important;
    }
    .documentationPreviewContainer .categoryTitle {
      font-size: 40px;
    }
    .documentationCategoryContainer .categoryTitle {
      font-size: 40px !important;
    }
    .documentationCategoryContainer .header {
      margin-bottom: 20px !important;
    }
    .docConfigNewCategoryPanel {
      width: 100%;
      align-items: center;
      margin-top: 20px;
    }
    .docConfigLogoHeader {
      margin-left: 20px !important;
      justify-items: center;
    }
  }
}
//* styles DocumentationsPanel for iPhone 12 Pro
@media (max-width: 391px) {
  .documentationStatusesModalContent {
    .configDocumentationsContainers .gridContainer {
      min-width: 260px;
      margin-left: -80px;
    }
    .configDocumentationsContainers .docName {
      font-size: 14px !important;
    }
    .configDocumentationsContainers span.docNumber {
      margin-bottom: -65px !important;
      margin-top: 15px;
      margin-left: 240px;
    }
    .configDocumentationsContainers .newDocPanel {
      margin-top: 0;
    }
    .configDocumentationsContainers .valueContainer {
      font-size: 14px;
    }
    .mondayButtonContainer .mondayButtonStyle {
      margin-top: 10px;
      width: 180px;
    }
    button.ant-btn.ant-btn-default.mondayButtonStyle.newButton:first-child {
      width: 150px;
    }
  }
}
