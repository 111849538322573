$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
.shift-modal .ant-modal-content {
  border-radius: 20px 20px 10px 10px;
  // width: 1258px;
  // height: 906px;
  // margin-top: 200px;
  // margin-right: 300px !important;
  // overflow-y: scroll;
}
//  .shift-modal .ant-modal-body{
//   // overflow-y: scroll;
//  }

.shift-modal .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
  background-color: $card_blue;
  font-weight: bolder;
}
.shift-modal .ant-modal-title {
  color: white;
  font-weight: 600;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.shift-modal .ant-modal-close {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.ant-modal.shift-modal {
  width: max-content !important;
}

.shift-modal .ant-modal-footer {
  padding: 0px 40px;
  border: none !important;
  margin-top: 0px;
  margin-bottom: 20px;
  .close.ant-btn {
    background-color: #ea3943;
    border: none;
    // padding: 5px 15px;
    margin-bottom: 10px;
    color: white;
    border-radius: 5px;
    float: left;
    width: 150px;
    height: 32px;
  }
  .save.ant-btn {
    background-color: #1da193;
    border: none;
    padding: 5px 15px;
    color: white;
    border-radius: 5px;
    width: 150px;
    height: 32px;
  }
}
