.intervistatMainTabs {
  // min-width: 1630px;
  // width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  animation: intervistatMainTabsAnim 1s;
  // overflow-y: auto;
  .intervistatTabs {
    height: 100%;
    gap: 15px;
    background-color: #f1f7fb;

    .ant-tabs-nav {
      margin: 0 !important;
      background: #fff !important;
      border: none !important;
      border-radius: 10px;
      overflow: hidden;
      padding: 5px;
      .ant-tabs-nav-wrap {
        background: #fff !important;
        .ant-tabs-nav-list {
          gap: 24px;
          overflow: hidden;
          .ant-tabs-tab {
            position: relative;
            top: 2px;
            background: #fff !important;
            font-size: medium;
            font-weight: 400;
            font-size: 16px;
            align-self: center !important;
            justify-self: center !important;
            display: flex;
            padding-right: 10px;
            padding-left: 10px;
            width: 100%;
            animation: animateTab 0.5s;
            margin: 0;
          }
        }
      }
      &::before {
        border: none;
      }
    }
    .ant-tabs-content {
      height: 100%;
      > :nth-child(1) {
        height: 100%;
      }
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
      border: 0;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      display: flex;
      gap: 30px;
      width: 100%;
      overflow-y: hidden;
    }
    .ant-tabs-tab:hover {
      color: #58647a;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #323338;
    }

    .ant-tabs-ink-bar {
      background: #323338;
      padding-bottom: 5px;
      border-radius: 5px 5px 0px 0px;
      width: 100%;
      animation: inkBar 0.5s;
    }
  }
  .kandidadiTabs {
    width: 100%;
    .tabs {
      .ant-tabs-nav-wrap {
        background: unset !important;
      }

      .ant-tabs-nav {
        background: unset !important;
        border: unset !important;
      }

      .ant-tabs-tab {
        position: unset;
        top: unset;
        background: unset !important;
        font-size: unset;
        font-weight: unset;
        font-size: unset;
        align-self: unset !important;
        justify-self: unset !important;
        display: unset;
        padding-right: unset;
        padding-left: unset;
        width: unset;
        animation: unset;
      }

      .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
      .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        border: unset;
      }

      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
      .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
        width: unset;
      }
      .ant-tabs-tab:hover {
        color: unset;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: unset;
      }

      .ant-tabs-ink-bar {
        background: unset;
        padding-bottom: unset;
        border-radius: unset;
        width: unset;
        animation: unset;
      }
    }
  }
  .buttons {
    padding-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    animation: buttonAnimate 0.5s;
    .applicantButtons {
      display: flex;
      gap: 10px;
      .shikoNdryshimetBtn {
        border-radius: 5px;
        background-color: #20ac9d;
        border: none;
        color: #ffffff;
      }
      .ndryshoniBtn {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #2e5d9e;
        border: none;
        color: #ffffff;
      }
    }
    .shtoKandidadBtnContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      .shtoKandidadBtn {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #20ac9d;
        border: none;
        color: #ffffff;
      }
    }
    .exportKandidateBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      border: none;
      padding: 0;
      background-color: #1da193;
      border-radius: 5px;
    }
  }
  .kanbanFilters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin: 0;
    animation: filtersContentKanban 0.5s;
    .inputSearchFilter {
      height: 32px;
      border: none;
      border-radius: 5px;
      background-color: #f5f5f7;
    }
    .ant-input-affix-wrapper > .ant-input:not(textarea) {
      padding-left: 5px !important;
      background-color: #f5f5f7 !important;
    }
    .filterBtnKanban {
      width: 98px;
      height: 32px;
      border: none;
      border-radius: 5px;
      background-color: #2e5d9e;
      color: #ffffff;
    }
    .clearFilterBtn {
      height: 32px;
      width: 32px;
      border-radius: 5px;
      background-color: #ea3943;
      border: none;
      .centerIcon {
        display: flex;
      }
    }
  }
}

@media (max-width: 1200px) {
}
@media (max-width: 768px) {
  .intervistatMainTabs {
    .ant-tabs-nav {
      flex-direction: column;
      gap: 5px;
      .ant-tabs-nav-list {
        gap: 5px;
      }
      .ant-tabs-extra-content {
        width: 100%;
        .buttons {
          justify-content: space-evenly;
        }
      }
    }
  }
}

@keyframes animateTab {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes buttonAnimate {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes filtersContentKanban {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes intervistatMainTabsAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes inkBar {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
