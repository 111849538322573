.hiringCharts {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: "a a b c" "d e f f";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  > :nth-child(1) {
    grid-area: a;
  }
  > :nth-child(2) {
    grid-area: b;
  }
  > :nth-child(3) {
    grid-area: c;
  }
  > :nth-child(4) {
    grid-area: d;
  }
  > :nth-child(5) {
    grid-area: e;
  }
  > :nth-child(6) {
    grid-area: f;
  }
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  // overflow: hidden !important;

  .charts {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 10px;
    animation: hiringCharts 0.5s;
    // height: 394.5px;
    .headerChart {
      display: flex;
      justify-content: space-between;
      .topTitle {
        font-weight: 600;
      }
      .selectContent {
        display: flex;
        gap: 5px;
      }
      .selectYear {
        width: 90px !important;
        height: 32px !important;
        .ant-select-selector {
          border-radius: 5px !important;
          border: none !important;
          background-color: #f5f5f7;
          .ant-select-selection-search-input {
            height: 32px !important;
          }
        }
      }
      .selectOptions {
        width: 138px !important;
        height: 32px !important;
        .ant-select-selector {
          border-radius: 5px !important;
          border: none !important;
          background-color: #f5f5f7;
          .ant-select-selection-search-input {
            height: 32px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .hiringCharts {
    display: grid;
    grid-template-areas: "a a b" "c d e" "f f f";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}
@media (max-width: 1250px) {
  .hiringCharts {
    display: grid;
    grid-template-areas: "a a" "b c" "d e" "f f";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 450px) {
  .hiringCharts {
    display: grid;
    grid-template-areas: "a" "b" "c" "d" "e" "f";
    grid-template-columns: 1fr;
    // grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}

// @keyframes hiringCharts {
//   0% {
//     opacity: 0;
//     transform: scale(0.9);
//   }
//   100% {
//     opacity: 1;
//     transform: scale(1);
//   }
// }
