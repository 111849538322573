.generate-modal {
  .input-form {
    width: 345px;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;

    .ant-modal-body {
      background-color: white;
      height: calc(100dvh - 200px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-left: 20px;
      .generate-modal-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px;
        .pick-month {
          display: flex;
          justify-content: center;
          height: 200px;
          align-items: center;
          gap: 30px;

          .ant-picker {
            background-color: #f1f7fb;
            border: none;
          }
        }
      }
    }

    .ant-modal-footer {
      .finished-gen {
        display: flex;
        gap: 5px;
      }
    }
  }
}

.pick-employee {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: none;
    background-color: #f1f7fb;
    border: none;
  }
}

@media (max-width: 450px) {
  .generate-modal {
    .generate-modal-container {
      .pick-employee {
        display: flex;
        flex-direction: column;
      }
      .pick-month {
        flex-direction: column;
        > div {
          display: flex;
          flex-direction: column;
          width: 70%;
        }
      }
    }
  }
}
