.ant-modal.filter-modal {
  width: 300px !important;
  border-radius: 5px;
  .date-filter.ant-picker {
    background-color: #f1f7fb;
    border: none;
    border-radius: 5px;
  }
  .ant-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .button {
    background-color: #1da193;
    width: 100%;
    height: 32px;
    border: none;
    border-radius: 5px;
    color: #fff;
    font: normal normal normal 14px/19px "Open Sans", sans-serif;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  .clear-button {
    background-color: #fcc94a;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-picker-separator {
    color: #1d3445;
  }
}
