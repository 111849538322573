.req-drawer .ant-drawer-content-wrapper {
  max-width: 800px !important;

  .req-drawer .ant-drawer-content {
    height: 100% !important;
    overflow-y: scroll;
    border-radius: 3px;
  }
  .req-drawer .ant-drawer-content-wrapper {
    height: 100% !important;
  }
  .ant-table {
    .ant-table-row {
      cursor: pointer;
    }
    .ant-table-thead {
      .ant-table-cell {
        height: 36px !important;
        padding: 4px 16px !important;
        background-color: #f1f1f5 !important;
        border-bottom: none;
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        // height: 25px !important;
        padding: 4px 16px !important;
        border-right: 1px solid #dee2eb;
        border-bottom: none;
      }
      .ant-table-cell:last-child {
        border-right: none;
      }
      .ant-table-row:nth-child(odd) {
        background-color: #f1f1f5 !important;
      }
    }
    .problemsDescriptionCell > * {
      margin: 0px !important;
      height: 28px !important;
      text-wrap: wrap;
      flex-wrap: wrap;
      overflow: hidden;
      background-color: transparent !important;
    }
    .problemsDescriptionCell > * > img {
      display: none;
    }
    .problemsDescriptionCell > * > * {
      background-color: transparent !important;
    }
    .problemsDescriptionCell {
      max-height: 30px !important;
      flex-wrap: wrap;
      overflow: hidden;
    }
  }
  //Stilizimi i filtrave
  .filters-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5px;
    .name-filter-input {
      background-color: white !important;
      border: 1px solid #d9d9d9 !important;
      width: 180px;
      border-radius: 6px;
    }
    .status-filter-select.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      width: 180px;
      border-radius: 6px;
    }
    .status-filter-select {
      .ant-select-arrow {
        right: -53px !important;
      }
    }
    .date-filter {
      border-radius: 6px;
      width: 180px;
    }
    .filter-button.ant-btn {
      border: 1px solid #1da193 !important;
      color: #1da193;
      border-radius: 5px;
      &:hover {
        background-color: #1da19310;
      }
    }
    .clear-filter.ant-btn {
      border: 1px solid #ea3943 !important;
      color: #ea3943;
      border-radius: 5px;
      &:hover {
        background-color: #ea394310;
      }
    }
  }
}
