.swal2-styled.swal2-confirm {
  background: rgb(24, 170, 115) !important;
}

.paysub-btn {
  border: none;
  border: none;
}

.wages-cont {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  .shift-approvals {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
}

.wages-header {
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 150px;
  -webkit-user-select: none;
  user-select: none;
  // margin-left: 10px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 16%);
  border-radius: 10px;
  opacity: 1;
  gap: 0;
  justify-content: row;
  .header-wrapper {
    // margin-left: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

.wage-header__card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 13px 10px;
  height: 104px;
  // min-width: 255px;
  // margin-right: 23px;
  border-radius: 10px;
  flex-grow: 1;
  flex-basis: 0px;
  background-color: red;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .statusValue {
    font-size: 56px;
    font-weight: 600;
    opacity: 1;
    text-transform: none;
    letter-spacing: 0;
    color: #fff;
    line-height: 1;
  }

  .statusName {
    font-size: 17px;
    font-weight: 400;
    opacity: 1;
    text-transform: none;
    letter-spacing: 0;
    color: #fff;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
}
.paymentTypeIcons {
  svg {
    fill: red;
  }
}

@media (max-width: 450px) {
  .base-mobile-div {
    .header-paga {
      margin-bottom: 15px !important;
      .wages-header .header-wrapper {
        display: grid;
      }
    }
  }
  .row-mob-with-fix {
    width: 100% !important;
  }
}
