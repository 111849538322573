@import "../../../index.scss";

.punonjesitPage {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  .punonjesit-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    .card-puno-prog {
      width: 100%;
      height: 225px;
      cursor: pointer;
      padding: 0;
    }
    .ant-card.ant-card-bordered {
      border-radius: 10px;
      box-shadow: 1px 1px 1px 1px #00000029;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .ant-progress-inner {
      width: 200px !important;
      height: 200px !important;
      margin-top: 40px;
    }
    span.anticon.anticon-idcard svg {
      margin-left: 100px;
      width: 6rem;
      height: 6rem;
      color: #239281;
    }
    button.ant-btn {
      background: #1da193;
      border-radius: 5px;
      margin-left: 0px;
      color: #fff;
    }
    .ant-progress-circle .ant-progress-text {
      font-size: 19px;
    }
  }
  .punonjesit-ag-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    // height: 520px !important; // TO BE FIXED
    background: #ffffff;
    width: -webkit-fill-available;
    box-shadow: 1px 1px 2px 1px #00000029;
    border-radius: 15px;
    // padding-top: 10px;
    .ag-grid-header {
      padding: 10px 20px;
      display: flex;
      gap: 20px;
      background: transparent;
      input.ant-input {
        background: transparent !important;
        border: none;
      }
      input.ant-input::placeholder {
        color: black !important;
      }
      .icon-search-header {
        display: flex;
        justify-content: center;
        align-items: center;
        path#Path_6858_00000086666139294581268680000013212754614146113725_ {
          fill: #1d3445;
        }
      }
    }
    .ag-header {
      background: #1d3445;
      border: none;
    }
    button.ant-btn.ant-btn-icon-only.ant-input-search-button {
      border: none;
      color: black !important;
      background: #f1f7fb;
    }
    button.ant-btn.pastro-btn {
      background: #ea3943;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
      width: 80px;
      border-radius: 5px;
      margin-right: 20px;
      margin-left: 20px;
      color: #fff;
      cursor: pointer;
    }

    .header-search {
      background: #f1f7fb;
      display: flex;
      margin: 0;
      border-radius: 5px;
      height: 32px;
    }
    // dropdwon departamenti
    .punojsit-dropdown {
      margin-left: 3% !important;
      border: none;
      .ant-select-selector {
        border-radius: 5px !important;
        background: transparent !important;
      }
      span.ant-select-arrow {
        color: #323338;
      }
    }
    .punojsit-div {
      display: flex;
      background: #f1f7fb;
      height: 32px;
      border-radius: 5px;
      padding-left: 10px;
      .icon-punojsit {
        margin-top: 7px;
      }
      input.ant-input::placeholder {
        color: black !important;
      }
    }
    button.ant-btn.ant-btn-icon-only.ant-input-search-button {
      border: none;
      color: black !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none !important;
    }
  }
}

.ant-card.ant-card-bordered.add-punojsi-card {
  .ant-card-body {
    text-align: center !important;
  }
}

// punojsit page CARDS PUNOJSTI STYLE

//ICONA per cards
.punonjesit {
  svg#Layer_1 {
    fill: transparent !important;
  }
  path#Path_6861_00000147205192370109036890000003473032641409739905_ {
    fill: #323338;
  }
}

.add-icon {
  margin-left: 0px;
  path#Path_6861_00000147205192370109036890000003473032641409739905_ {
    fill: #1da193;
  }
}

.punonjesit {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  path#Path_7433 {
    fill: #323338;
  }
}

.header-icons svg {
  margin: 0 !important;
  background: #f1f7fb;
  width: 31px;
  height: 31px;
  border-radius: 5px;
  fill: #323338;
  padding: 7px;
  cursor: pointer;
}

.header-icons {
  gap: 20px;
  button {
    padding: 0;
  }
  svg#Layer_1 {
    fill: #fff;
  }
  path#Path_7431_00000060022719262200020110000009623688766172120195_ {
    fill: #323338;
  }
  path#Path_7428_00000028307989207194211430000003663479962056332434_ {
    fill: #323338;
  }
  path#Path_6546_00000109745927328506804000000014354080646234951053_ {
    fill: #323338;
  }
  path#Path_7427_00000027570199658901481330000001697806187456729770_ {
    fill: #323338;
  }
}

path#Path_7429_00000029032457934157017360000010140754146058612150_ {
  fill: #323338;
}

// style per iconat e header te ag grid
.header-icons {
  display: flex;
  height: 30px;
  .ant-picker {
    overflow: hidden;
    padding: 0 0 0 11px;
    width: 190px;
    svg {
      margin: 0;
    }
  }
}

.switchCards {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  .span {
    font-size: 15px;
    font-weight: 600;
  }
}

.header-icons {
  svg#Layer_1 {
    fill: #fff;
  }
  path#Path_7431_00000060022719262200020110000009623688766172120195_ {
    fill: #323338;
  }
  path#Path_7428_00000028307989207194211430000003663479962056332434_ {
    fill: #323338;
  }
  path#Path_6546_00000109745927328506804000000014354080646234951053_ {
    fill: #323338;
  }
  path#Path_7427_00000027570199658901481330000001697806187456729770_ {
    fill: #323338;
  }

  path#Path_7429_00000029032457934157017360000010140754146058612150_ {
    fill: #323338;
  }
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  height: 32px !important;
  border-bottom: 2px solid #efefef;
  padding-top: 19px;
  padding-bottom: 19px;
}
.ant-dropdown-menu {
  background: #f5f5f7;
  padding: 0px 0px;
}

.ant-dropdown.ant-dropdown-placement-bottomCenter
  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
  background: #cdd3df;
}

// ag grid  punojsit
.ag-grid-punojsit .ag-theme-alpine .ag-header {
  background: #1d3445;
  border: none;
}

.ag-grid-punojsit .ag-theme-alpine .ag-root-wrapper {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-header-row.ag-header-row-column {
  color: #ffffff !important;
  font-weight: 400;
  font-size: medium;
}

.ag-grid-punojsit .ag-theme-alpine .ag-header-cell-resize {
  // display: none;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
  background: #f5f5f7 !important;
  border: none !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
  border: none !important;
}

//hover cells ag grid
.ag-grid-punojsit
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit
  .ag-row-even.ag-row-position-absolute.ag-after-created.ag-row-focus {
  background: #fff;
  border: none;
}
.ag-grid-punojsit
  .ag-row-odd.ag-row-position-absolute.ag-after-created.ag-row-focus {
  background: #f6f6f6;
  border: none;
}
.ag-grid-punojsit
  .ag-row-even.ag-row-position-absolute.ag-after-created.ag-row-focus:hover {
  background: #e1e1e6;
}
.ag-grid-punojsit
  .ag-row-odd.ag-row-position-absolute.ag-after-created.ag-row-focus:hover {
  background: #e1e1e6;
}
.ag-grid-punojsit
  .ag-theme-alpine
  .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  background: #1d3445;
}
.ag-grid-punojsit .ag-row-even.ag-after-created.ag-row-selected:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-focus.ag-row-selected:hover {
  background: #e1e1e6 !important;
}
.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-selected.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-checkbox .ag-input-wrapper {
  background: #1d3445;
  width: 20px;
  height: 18px;
}
.ag-grid-punojsit .ag-theme-alpine .ag-checkbox-input-wrapper::after {
  color: #1d3445 !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-checkbox-input-wrapper.ag-checked::after {
  color: #1d3445 !important;
  background: #ffffff !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-checkbox-input-wrapper {
  font-size: 20px !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-row-selected {
  background: #cdd3df !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-focus.ag-row-selected {
  background: #cdd3df !important;
}

.ag-grid-punojsit
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-selected.ag-row-no-focus {
  background: #cdd3df !important;
}

.ag-grid-punojsit .ag-theme-alpine .ag-paging-panel {
  border: none !important;
}

.ag-grid-punojsit .ag-theme-alpine a {
  color: #1da193;
  font-weight: 600;
}

.ag-grid-punojsit
  .ag-theme-alpine
  span.ag-header-icon.ag-header-cell-menu-button {
  color: #fff !important;
}
.ag-grid-punojsit .ag-theme-alpine span.ag-icon.ag-icon-asc {
  color: #fff !important;
}
.ag-grid-punojsit .ag-theme-alpine span.ag-icon.ag-icon-desc {
  color: #fff !important;
}

.punonjesit {
  .Perdoruesit-print {
    visibility: collapse;
  }
  svg#Layer_1 {
    z-index: 3;
  }
}

button#test-table-xls-button {
  border: none;
  background: none;
  height: 20px !important;
  z-index: 3;
  g#Path_6548_00000013187882280958664000000008011740233821948811_ {
    fill: #fff;
  }
  path#Path_6563_00000078767317401371559660000015817487648738779056_ {
    fill: #fff;
  }
  path#Path_6564_00000067212381517402767670000013906634329061144984_ {
    fill: #fff;
  }
  path#Path_6570_00000123413101868059526180000014942413600741555390_ {
    fill: #fff;
  }
}
.statusi-per-punojsit {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font: normal normal 600 20px/25px "Open Sans", sans-serif;
}
.costumize-stat-card {
  height: 60px;
  .numer {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 500;
    color: #323338;
  }
  .emri {
    font-weight: 400;
    color: #323338;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Perdoruesit-print {
  margin-left: 30px !important;
  padding-top: 20px;

  table {
    width: 100%;
  }
  thead {
    width: 50%;
  }
  th {
    justify-content: flex-start;
    text-align: start;
    width: 100px !important;
  }

  td {
    width: 200px;
  }

  tr {
    width: 90%;
  }
}

.ant-tooltip.global-icon-tooltip.ant-tooltip-placement-top {
  .ant-tooltip-inner {
    background-color: #1d3445 !important;
    border-radius: 5px !important;
  }
}

//responsive
@media (max-width: 1250px) {
  .punonjesitPage {
    .punonjesit-cards {
      padding: 0;
    }
    .punonjesit-ag-grid {
      .ag-grid-header {
        overflow-x: auto;
        gap: 10px;
      }
    }
  }
}
@media (max-width: 1050px) {
  .punonjesit-cards {
    display: grid;
    // grid-template-columns: auto auto;
    gap: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 821px) {
  .punonjesit-cards {
    grid-template-columns: 330px 330px !important;
  }
}

@media (max-width: 769px) {
  .punonjesit-cards {
    grid-template-columns: 300px 300px !important;
  }
}

@media (max-width: 450px) {
  .ant-modal.global-filter-modal {
    margin-right: 210px;
    .ant-row {
      display: grid;
      grid-template-columns: auto;
      .ant-col.ant-col-6.coln-1 {
        max-width: 100%;
      }
      .ant-col.ant-col-6.coln-2 {
        max-width: 100%;
      }
      .ant-col.ant-col-6.coln-3 {
        max-width: 100%;
      }
      .ant-col.ant-col-6.coln-4 {
        max-width: 100%;
      }
    }
    .ant-modal-content {
      width: 300px !important;
    }
  }
  .punonjesit-cards {
    grid-template-columns: 300px !important;
    gap: 0px !important;
  }
  .ag-grid-punojsit {
    width: 300px !important;
    margin-left: 10px !important;
  }
  .ag-grid-punojsit .ag-grid-header {
    .header-search {
      input.ant-input {
        width: 100px !important;
      }
    }
  }
  .punonjesit {
    width: 320px !important;
    height: fit-content;
  }
}

@media (max-width: 450px) {
  .ant-modal.global-filter-modal {
    margin-right: 210px;
    .ant-row {
      display: grid;
      grid-template-columns: auto;
      .ant-col.ant-col-6.coln-1 {
        max-width: 100%;
      }
      .ant-col.ant-col-6.coln-2 {
        max-width: 100%;
      }
      .ant-col.ant-col-6.coln-3 {
        max-width: 100%;
      }
      .ant-col.ant-col-6.coln-4 {
        max-width: 100%;
      }
    }
    .ant-modal-content {
      width: 300px !important;
    }
  }
  .punonjesit-cards {
    grid-template-columns: 275px !important;
    gap: 0px !important;
  }
  .ag-grid-punojsit {
    width: 280px !important;
  }
}

@media (max-width: 390px) {
  .punonjesit {
    width: 300px !important;
    height: fit-content;
  }
}
