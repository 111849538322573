@import "../../../../index.scss";

.documentationCategoryContainer {
  width: 100% !important;
  padding-top: 60px;
  position: relative;

//* styles header justify-content:center, and align-items:center
  .header {
    @include flex(center, center);
    flex-wrap: wrap;
    margin-bottom: 60px;
  }

  .backButton {
    position: absolute;
    top: 120px;
    left: 28px;
  }

//* styles categoryTitle text font-size:100px, font-weight:bold, letter-spacing:0, text color, and text-transform:uppercase
  .categoryTitle {
    @include text(100px, bold, 0, #323338, uppercase);
    font-family: "Open Sans";
  }

  .headerDivider {
    height: 140px;
    margin: 0 50px;
    border-left-width: 2px;
  }
}

//* responsive CategoryView for iPad Air
@media (max-width: 821px) {
  .documentationCategoryContainer {
    overflow-x: hidden;
  }
}

//* responsive CategoryVirw for iPhone 12 Pro
@media (max-width: 391px) {
  .documentationCategoryContainer .backButton {
    position: absolute;
    top: 80px;
    left: 10px;
}
  .documentationCategoryContainer {
    width: 350px !important;
    padding: 0;
  }
  .documentationCategoryContainer .categoryTitle {
    font-size: 20px;
  }
  .documentationCategoryContainer .headerDivider {
    display: none;
  }
  .documentationCategoryContainer .header {
    display: flex;
    flex-direction: column;
  }
}