.ant-breadcrumb {
  .ant-breadcrumb-link {
    cursor: pointer;
  }
}
.dynamic-punojsit-tabs {
  height: 100%;
  .punojsit-tab {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        height: 100%;
        // padding-left: 18px;
      }
    }
  }
  .ant-tabs-nav-wrap {
    background: #fff !important;
  }

  .ant-tabs-nav {
    background: #fff !important;
    border: none !important;
  }
  .ant-tabs-nav-wrap {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ant-tabs-tab {
    background: #fff !important;
    font-size: medium;
    font-weight: 500;
    align-self: center !important;
    justify-self: center !important;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: 0;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab:hover {
    color: #58647a;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #323338;
  }

  .ant-tabs-ink-bar {
    background: #323338;
    padding-bottom: 5px;
    border-radius: 50px;
    width: 100%;
  }

  div#tabBody {
    // justify-content: flex-start;
    // align-items: center;
    // display: flex;
    width: 100%;
  }
}
.employee {
  font-size: 28px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif !important;
  position: absolute;
  z-index: 1000;
  margin-left: 100px;
}

@media (max-width: 450px) {
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px !important;
  }
  .employee {
    font-size: 13px !important;
    margin-left: 65px;
  }
}

@media (max-width: 391px) {
  .dynamic-punojsit-tabs .ant-tabs-tabpane {
    padding: 0 10px 10px;
  }
  .dynamic-punojsit-tabs .ant-tabs-tabpane {
    overflow-x: hidden;
  }
}
