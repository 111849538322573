@import "../../../index.scss";

.customAntdTransfer {
  width: fit-content;
  overflow: auto;
  height: 602px;
  .transfersContainer {
    @include flex(flex-start, center);
    // width: fit-content;
  }

  .source {
    @include flex(flex-start, flex-start, column);
    min-width: 200px;

    .transferLabelContainer {
      animation: fadeInRight 1000ms both;
    }
  }

  .listContainer {
    @include flex(flex-start, flex-start, column);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
    gap: 5px;
  }

  .transferIcon {
    @include flex(center, center);
    fill: #323338 !important;
    width: 50px;
    align-self: stretch;
    margin: 0 65px;

    svg {
      cursor: pointer;
    }
  }

  .transferredKeys {
    @extend .source;
    .transferLabelContainer {
      animation: fadeInLeft 1000ms both;
    }
  }

  .itemCounter {
    @include text(16px, 600, 0, #323338);
    align-self: center;
  }

  .transferLabel {
    @extend .itemCounter;
    align-self: flex-start;
    width: fit-content;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
