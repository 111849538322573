.add-permission {
  display: flex;
  gap: 5px;
  width: 100%;
  form {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    .ant-form-item {
      width: 100%;
    }
  }
}
.dropdown {
  background: #f5f5f7;
  border: 0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
