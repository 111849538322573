.sistemi-llojet-kerkesave {
  width: 100%;
  height: 100%;
}

@mixin icon-buttons($bgcolor) {
  width: 32px;
  height: 32px;
  background: $bgcolor !important;
  border: none;
  border-radius: 5px;
  // margin-right: 30px;
}
.employee-status-comp {
  margin-top: 0px;

  path#Path_7623 {
    fill: #9a9a9a;
  }
  .employee-status-card {
    height: 438px;
    box-shadow: 1px 1px 2px 1px #00000029;
    .ant-card-head {
      background-color: #1d3445 !important;
      border-radius: 10px 10px 0 0 !important;
      color: white !important;
      font-family: "Open Sans", sans-serif !important;
      font-weight: 600 !important;
      font-size: 18px;
    }
  }

  .add-status-button.ant-btn {
    @include icon-buttons(#f5f5f7);
  }
  .close-status-button.ant-btn {
    @include icon-buttons(#ea3943);
    margin-right: 30px;
  }

  .emp-status .ant-form-item-control-input-content .ant-input {
    background: #f5f5f7;
    color: #9a9a9a;
    width: 244px;
    height: 32px;
  }
  .ant-form-inline .ant-form-item {
    margin-right: 0px; //KUJDES!!!!!!
  }
  .right-side {
    margin-left: 50px;
    .hex-code {
      background-color: #f5f5f7;
      width: 100px;
      height: 25px;
      margin: 10px 5px 20px 20px;
      padding-left: 5px;
      border-radius: 4px;
      border: none;
    }
    .hex-code-button {
      border: none;
      height: 25px;
      border-radius: 4px;
    }
  }
  .holder {
    margin-top: 22px;
  }
  .status-name {
    background-color: #323232;
    color: #fff;
    text-align: center;
    padding: 0px;
    width: 244px;
    height: 32px;
    border-radius: 5px;
    font: normal normal normal 16px/28px "Open Sans", sans-serif;
  }
  .down-button.ant-btn {
    @include icon-buttons(#f5f5f7);
    margin: 0px 0px;
  }
  .up-button.ant-btn {
    @include icon-buttons(#f5f5f7);
    margin: 0px 7px 0px;
  }
  .bin-button.ant-btn {
    @include icon-buttons(#fe4c4a);
    margin: 0px 5px 0px;
  }
  .name-btn-div {
    margin: 0px;
    height: 40px;
  }
  .ant-divider-vertical {
    height: 300px;
    margin-top: 45px;
  }
}

.llojet-kerkesave {
  width: 100%;
  height: 100%;
  text-align: center;
  .employee-status-card {
    .ant-card-head {
      background-color: #1d3445 !important;
      border-radius: 10px 10px 0 0 !important;
      color: white !important;
      font-family: "Open Sans", sans-serif !important;
      font-weight: 600 !important;
      font-size: 18px;
    }
  }

  button.ant-btn.shto-llojn-btn {
    background: #1da193;
    color: #fff;
    border-radius: 5px;
    border: none;
  }
  span.llojet-input-icon {
    background: #1da193;
    border-radius: 3px;
    margin-left: 10px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 25px;
  }
  .label-quill-div {
    h3 {
      margin-top: 20px;
      text-align: start;
      margin-left: 90px;
    }
  }
  .quill-div {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .quill.note_input.quillTextEditor.lloj-kerkesash-quill {
    display: inline-block;
    width: 530px;
    .ql-toolbar.ql-snow {
      width: 100%;
      background: #f5f5f7;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .ql-container.ql-snow {
      background: #f5f5f7;
      height: 80px;
      border: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
.request-title-2 {
  color: #1da193;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 820px) {
  .ant-row.sistemi-llojet-kerkesave-row {
    display: grid;
    .ant-col {
      max-width: 100%;
    }
    .ant-col.ant-col-12.col-respon {
      height: 460px !important;
    }
    .ant-col.ant-col-12.col-second-respon {
      padding-left: 20px;
      margin-bottom: 20px;
    }
  }
  .employee-status-comp
    .emp-status
    .ant-form-item-control-input-content
    .ant-input {
    width: 140px;
  }
}

@media (max-width: 450px) {
  .ant-row.sistemi-llojet-kerkesave-row {
    .ant-col.ant-col-12.col-respon {
      height: 510px !important;
      width: 100% !important;
      padding-right: 0px;
      text-align: start;
    }
    .ant-col.ant-col-12.col-second-respon {
      padding-left: 20px;
      margin-bottom: 20px;
      height: 600px !important;
      width: 100% !important;
      .card {
        height: 100% !important;
      }
      .ant-card {
        height: 100% !important;
      }
      .alldivs {
        display: grid !important;
      }
    }
    .photoshop-picker {
      width: 280px !important;
    }
  }
  .employee-status-comp .right-side {
    margin-left: 0px !important;
  }
  .ant-divider.ant-divider-vertical {
    display: none;
  }
  .employee-status-comp
    .emp-status
    .ant-form-item-control-input-content
    .ant-input {
    width: 140px;
  }
  .llojet-kerkesave .quill.note_input.quillTextEditor.lloj-kerkesash-quill {
    width: 200px;
  }
  .llojet-kerkesave .label-quill-div h3 {
    margin-left: 0px !important;
  }
  .ant-col.ant-col-12.col-respon {
    width: 310px !important;
    height: 100%;
    margin-bottom: 10px;
    .card {
      height: 100% !important;
    }
    .ant-card {
      height: 100% !important;
    }
  }
}
