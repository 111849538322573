// .ImportantDetailsPage {
// 	.ant-modal {
// 		height: 100%;
// 	}
// 	.ant-modal-content {
// 		background-color: #fff;
// 		background-clip: padding-box;
// 		border: 0;
// 		border-radius: 2px;
// 		box-shadow: 0 3px 6px -4pxrgba (0, 0, 0, 0.12), 0 6px 16px 0rgba (0, 0, 0, 0.08),
// 			0 9px 28px 8px rgba(0, 0, 0, 0.05);
// 		height: 100%;
// 	}

// 	.importantDetailsCard {
// 		.headerActionsCard {
// 			display: flex;
// 			flex-direction: row;
// 			gap: 1rem;
// 		}
// 		.ant-card-head {
// 			background-color: #1d3445;
// 			color: #ffffff;
// 			border-radius: 10px 10px 0px 0px;
// 		}
// 		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
// 			width: 254px;
// 			height: 32px;
// 			background: #f5f5f7 0% 0% no-repeat padding-box;
// 			border-radius: 5px;
// 			opacity: 1;
// 		}
// 	}
// 	.goBack {
// 		width: 150px;
// 		height: 32px;
// 		background: #1DA193 0% 0% no-repeat padding-box;
// 		opacity: 1;
// 		border-radius: 5px;
// 		color: #ffffff;
// 	}
// 	.canChangesBtn {
// 		width: 150px;
// 		height: 32px;
// 		color: #ffffff;
// 		border-radius: 5px;
// 		background: #EA3943 0% 0% no-repeat padding-box;
// 		opacity: 1;
// 	}
// 	.saveChangesFestatBtn {
// 		width: 150px;
// 		height: 32px;
// 		color: #ffffff;
// 		background: #1DA193 0% 0% no-repeat padding-box;
// 		border-radius: 5px;
// 		opacity: 1;
// 	}
// 	.nrOfEmployees {
// 		display: flex;
// 		justify-content: center;
// 		flex-direction: column;
// 		gap: 0.2rem;
// 		position: absolute;
// 		right: 3%;
// 		.punonjesitNePune {
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			flex-direction: row;
// 			gap: 0.2rem;
// 			width: 170px;
// 			height: 20px;
// 			color: #ffffff;
// 			background: #1DA193 0% 0% no-repeat padding-box;
// 			opacity: 1;
// 		}
// 		.punonjesitPushim {
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			flex-direction: row;
// 			gap: 0.4rem;
// 			width: 170px;
// 			height: 20px;
// 			color: #ffffff;
// 			background: #EA3943 0% 0% no-repeat padding-box;
// 			opacity: 1;
// 		}
// 	}
// 	.ant-row.row-search {
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		margin-top: 12px;
// 		// padding-left: 30px;
// 		overflow: auto;
// 		gap: 3rem;
// 		.departmentCardsContainer {
// 			width: 100%;
// 			// width: 306px;
// 			height: 351px;
// 			background: #ffffff 0% 0% no-repeat padding-box;
// 			box-shadow: 0px 1px 4px #00000029;
// 			border-radius: 10px;
// 			opacity: 1;
// 			.gjithDepartamenti {
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;
// 				height: 39px;
// 				background: #f5f5f7 0% 0% no-repeat padding-box;
// 				opacity: 1;
// 				.ant-checkbox-inner {
// 					width: 23px;
// 					height: 23px;
// 					background-color: #323338;
// 					border: none;
// 				}
// 				.ant-checkbox-inner:after {
// 					width: 6.714286px;
// 					height: 15.142857px;
// 				}
// 				.gjithDepartamentiText {
// 					width: 222px;
// 					height: 22px;
// 					text-align: center;
// 					font: normal normal normal 16px/22px Open Sans;
// 					letter-spacing: 0px;
// 					color: #323338;
// 					opacity: 1;
// 				}
// 			}
// 			.dataContainer {
// 				height: 40px;
// 				label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.checkboxEmployee {
// 					.ant-checkbox-checked .ant-checkbox-inner:after {
// 						// border: 2px solid black !important;
// 						position: absolute;
// 						display: table;
// 						border: 2px solid black;
// 						border-top: 0;
// 						border-left: 0;
// 						transform: rotate(45deg) scale(1) translate(-50%, -50%);
// 						opacity: 1;
// 						transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
// 						content: " ";
// 					}
// 					.ant-checkbox-inner:after {
// 						width: 6.714286px;
// 						height: 15.142857px;
// 					}
// 				}
// 				.ant-checkbox-inner {
// 					width: 22px;
// 					height: 22px;
// 					background-color: #f1f7fb;
// 					border: none;
// 				}
// 			}
// 			.checkName {
// 				width: 220px;
// 				height: 22px;
// 				font: normal normal normal 16px/22px Open Sans;
// 				letter-spacing: 0px;
// 				color: #323338;
// 				opacity: 1;
// 			}
// 			.ant-card-head {
// 				background-color: #1d3445;
// 				color: #ffffff;
// 				border-radius: 10px 10px 0px 0px;
// 				text-align: center;
// 			}
// 			.ant-card-body {
// 				overflow: auto;
// 				max-height: 400px;
// 			}
// 		}
// 	}
// }

.ImportantDetailsPage {
  .ant-modal-title {
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .ant-modal-header {
    display: flex;
    align-items: center;
    background-color: #1d3445;
    color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    height: 45px;
  }
  .ant-modal-content {
    width: 1300px;
    height: 680px;
  }
  .festatContent {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .depsContent {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 400px;
      height: 510px;
    }
  }
  .inputSearch {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .ant-input {
      width: 380px;
      border-radius: 5px;
      border: none;
      background-color: #f5f5f7;
    }
  }
  .allDeps {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 380px;
  }
  .depName {
    font-size: 16px;
    cursor: pointer;
  }
  .footerContent {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    Button {
      width: 150px;
      height: 32px;
      border-radius: 5px;
      opacity: 1;
      border: none;
      color: #fff;
      &:nth-child(1) {
        background-color: #ea3943;
      }
      &:nth-child(2) {
        background-color: #1da193;
      }
    }
  }
}
