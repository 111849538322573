.menaxhimi-orarit {
  padding-top: 10px;
  height: 100%;
  width: 100%;
  path#Path_7433 {
    fill: #323338;
  }
}

button.ant-btn.lista-paisjet-btn {
  background: #1da193;
  color: #fff;
  border-radius: 5px;
  border: none;
  width: 80px;
  height: 30px;
}

button.ant-btn.krijo-orar-ri-btn {
  background: #1da193;
  color: #fff;
  border-radius: 5px;
}

.column-paisjet-orarit {
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-ndrysho {
    margin-top: 4px;
    width: 32px;
    height: 32px;
    background: #1da193;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }
  path#Path_7431_00000060022719262200020110000009623688766172120195_ {
    fill: #fff;
  }
}
.ag-grid-punojsit .ag-theme-alpine .ag-header-container {
  .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
    background: #fff !important;
    border: none;
    opacity: 1;
    z-index: 4;
  }
  .ag-checkbox-input-wrapper::after {
    // color: #fff !important;
    border: none !important;
  }
}

// modal scss
.ant-modal.paisjet-orarit-modal {
  .ant-modal-content {
    border-radius: 5px;
  }
  .ant-modal-header {
    height: 45px;
    background: #1d3445;
    align-items: center;
    display: flex;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .ant-modal-title {
    color: #fff;
  }
  span.ant-modal-close-x {
    height: 45px;
    color: #fff;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .ant-modal-footer {
    justify-content: space-between;
    display: flex;
    background: #f5f5f7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .ant-row.ant-form-item {
      width: 100%;
    }
    button.ant-btn.ant-btn-primary.ruaj {
      background-color: #1da193;
      border-radius: 5px;
      border: none;
      width: 150px;
      height: 32px;
      text-align: center;
    }
    button.ant-btn.mbyll {
      background: #ea3943;
      color: #fff;
      border-radius: 5px;
      border: none;
      width: 150px;
      height: 32px;
      text-align: center;
    }
  }
  .paisje-orarit-content {
    display: flex;
  }
  .logo-container {
    width: 190px;
    // height: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .grid-container {
    margin-left: 40px;
    display: block;
    width: 100%;
  }
  textarea {
    resize: none;
  }
  .ant-row.ant-form-item {
    margin: 0;
  }
}

.quill.note_input.quillTextEditor.paisjet-orarit-quill {
  height: 170px;
  border-radius: 5px !important;
  background-color: #f1f7fb;
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-toolbar.ql-snow {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #fff;
  }
}

.ant-picker.konfigurimet-shift-picker {
  .ant-picker-content {
    width: 200px !important;
  }
}

.ant-modal.paisjet-orarit-modal {
  .header {
    justify-content: center;
    display: block;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .lista-content {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    padding-left: 20px;
    display: flex;
    justify-content: center;
  }
}

.ant-modal.paisjet-orarit-modal {
  svg.ikona-mbyll {
    path#Path_6864 {
      fill: #fff;
    }
  }
  .lista-icon-mbyll {
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
    background: #ea3943;
  }

  .ant-col.ant-col-10.inline-block {
    display: inline-block;
    justify-content: right;
  }
  .line-col {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .lista-line {
    width: 100%;
    border: 1px solid #d6d6d6;
    height: 2px;
    margin-right: 10px;
    margin-top: 3px;
  }
  .lista-line-red {
    width: 77%;
    border: 1px solid #ea3943;
    height: 2px;
    margin-right: 10px;
    margin-top: 3px;
  }
  .time-content {
    display: inline-block !important;
    width: 52%;
    font-size: 16px;
    text-align: end;
    font-weight: 500;
  }
  .time-content-red {
    width: 57%;
    color: #ea3943;
    display: inline-block !important;
    font-size: 16px;
    text-align: end;
    font-weight: 500;
  }
  .name-conetent-red {
    color: #ea3943;
    font-size: 16px;
    font-weight: 500;
    width: 67%;
  }

  .name-conent {
    width: 70%;
    font-size: 16px;
    font-weight: 500;
  }
  .ant-col.ant-col-1.icona-col {
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 450px) {
  .sistemi-paisjet-orarit {
    .ag-grid-header {
      .ant-col.responsive-col-grid-pagesa {
        flex-wrap: wrap !important;
        gap: 10px !important;
        margin-bottom: 10px !important;
      }
    }
  }
}
@media (max-width: 450px) {
  .ant-modal.paisjet-orarit-modal .paisje-orarit-content {
    display: grid !important;
    .logo-container {
      text-align: center;
      width: 100%;
    }
    .grid-container {
      margin-left: 0px;
      .ant-row {
        display: grid !important;
        .ant-col.ant-col-12 {
          max-width: 100%;
        }
      }
    }
  }
}

.menaxhimi-orarit {
  .Print-Punojsit {
    visibility: collapse;
  }
}
