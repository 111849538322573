.addLeaveEmailContainer {
  width: calc(100vw - 20px) !important;
  width: calc(100dvw - 20px) !important;
  height: calc(100vh - 20px) !important;
  height: calc(100dvh - 20px) !important;
  max-width: 810px !important;
  max-height: 700px !important;
  .ant-modal-content {
    max-height: 700px;

    .ant-modal-close {
      height: 35px;
      width: 35px;
      margin-right: 12px;
      .ant-modal-close-x {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #e7eef2;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      height: calc(100vh - 97px);
      max-height: 600px;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .confirm-body {
        display: flex;
        // align-items: center;
        gap: 20px;
      }
      .modalHeader {
        font-family: Open Sans;
        font-size: 25px;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        background-color: #f1f1f5 !important;
        border-radius: 5px;
        margin: 0 !important;
        height: 32px !important;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #f1f1f5;
      }

      .quill {
        margin-top: 10px;
        height: 100%;
        .ql-container {
          padding: 10px;
          border: 1px solid #dce4e9;
          border-radius: 10px;
          .ql-editor {
            max-height: 200px;
            padding: 0 20px 0 0;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 24px;
      height: 65px;
      .send-request {
        width: 150px;
        height: 32px;
        background-color: #1da193;
        color: #fbfbfb;
        border-radius: 5px;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        border: none;
      }
      .decline {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #ea3943;
        border: none;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        color: #fbfbfb;
      }
    }
  }
}
