.interviewTime {
  width: 100%;
  background-color: #f1f1f1;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  border-radius: 5px;
  .time-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: calc(100% - 5px);
    .ant-input-number {
      padding: 0 7px 0 7px;
      border: none;
      background-color: white;
      font-size: 40px;
      font-weight: 500;
      border-radius: 5px;
      .ant-input-number-input {
        height: auto;
        text-align: center;
      }
    }
    .divider {
      font-size: 2.5rem;
      font-weight: 500;
    }
    .am-or-pm {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 3px;
      .ant-btn-button {
        border: none;
      }
      .selected {
        color: #40a9ff;
        outline: 1px solid #40a9ff;
      }
      .ant-btn {
        width: fit-content;
        padding: 0 8px 0 8px;
        border-radius: 5px;
        border: none;
        span {
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .time {
    .time-container {
      width: 200px;
      .ant-input-number {
        padding: 8px;
        // border: none;
        // background-color: white;
        font-size: 30px;
        // font-weight: 600;
        // border-radius: 5px;
      }
    }
    .am-or-pm {
      .ant-btn {
        padding: 0 5px !important;
      }
    }
  }
}

@media (max-width: 430px) {
  .time {
    // width: calc(100% - 5px);
    .time-container {
      width: 220px;
      .am-or-pm {
        .ant-btn {
          padding: 0 10px !important;
          span {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}
