.happy-holidays-modal {
	.ant-modal-body {
		padding: 0;
		.image-happy-holidays {
			width: 1100;
			height: 700px;
			border-radius: 10px;
		}
	}
}
