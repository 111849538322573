$card_blue: #e7eef2;
.request-modal .ant-modal-body {
  padding-bottom: 5px !important;
}
.request-modal.ant-modal-content {
  border-radius: 20px 20px 10px 10px;
  width: 645px !important;
  height: 100%;
  margin-right: 300px !important;
  margin-left: 50px !important;
  margin-top: 200px;
}
.request-modal .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
  background-color: $card_blue !important;
  font-weight: bolder;
}
.request-modal .ant-modal-title {
  color: #1d3445;
  font-weight: 600;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.request-modal .ant-modal-close {
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.close-change-button {
  width: 150px;
  height: 32px;
  background-color: #ea3943 !important ;
  border-radius: 5px !important;
  border: none !important;
  color: #fff !important;
}
.save-change-button {
  width: 150px;
  height: 32px;
  background-color: #1da193 !important ;
  border-radius: 5px !important;
  border: none !important;
  color: #fff !important;
}

.modal-status-text {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1px 1px;
  margin: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
.label-quill-div {
  h3 {
    margin-top: 20px;
    text-align: start;
    margin-left: 90px;
  }
}
.quill-div {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.quill.note_input.quillTextEditor.lloj-kerkesash-quill {
  display: inline-block;
  width: 100%;
  .ql-toolbar.ql-snow {
    width: 100%;
    background: #f5f5f7;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .ql-container.ql-snow {
    background: #f5f5f7;
    height: 80px;
    border: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px !important;
  }
}
