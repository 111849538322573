.ant-modal.global-filter-modal {
  width: fit-content !important;
  .ant-modal-content {
    border-radius: 10px;
    height: 100%;
    width: 700px !important;
    .ant-modal-body {
      .ant-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 15px;
        margin: 0 !important;
        .ant-col {
          padding: 0 !important;
          flex: 1;
          max-width: 100%;
          .filter-dropdown {
            // width: 200px;
            background: #f5f5f7;
            border: 0;
            border-radius: 5px;
            width: 100%;
          }
          .ant-picker-range {
            width: 250px;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    .mondayButtonContainer > button {
      min-width: 100px;
    }
  }
}
.ant-modal.global-filter-modal {
  .ant-select.filter-dropdown-global.ant-select-single.ant-select-show-arrow {
    background: #f5f5f7 !important;
    border-radius: 5px !important;
  }
}
