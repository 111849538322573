// dynamic component scss for cards

.card {
  // margin-bottom: 25px;
  height: 100%;
  width: 100%;
  display: flex;
  //   box-shadow: 1px 1px 2px 1px #00000029;
  border-radius: 10px;
  // margin-right: 20px;
  .ant-card-head {
    background: #1d3445;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 45px;
    align-items: center;
    display: flex;
  }

  .ant-card-body {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
  }
}

main.ant-layout-content {
  .Print-Punojsit {
    visibility: collapse;
  }
}

.icona-pdf-grid {
  height: 32px;
  width: 32px;
  span.anticon.anticon-file-pdf {
    width: 32px;
    height: 25px;
  }
}
