.actions-ded-add {
  width: 100%;

  .actions-wrapper {
    background-color: #f1f7fb;
    height: 148px;
    padding: 20px;
    border-radius: 5px;
    .action-btns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .first {
        display: flex;
        flex-direction: row;
        gap: 20px;

        .action-btn {
          width: 115px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          border: none;
          cursor: pointer;
          border: 1px solid lightgray;
          /* UI Properties */
          // color: white;
          background: #fff 0% 0% no-repeat padding-box;
          &:hover {
            border: 1px solid #1da193a5;
          }
        }
      }

      .finalize {
        display: flex;
        gap: 20px;
        .action-btn {
          width: 155px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          cursor: pointer;
          color: white;
          background: #1da193 0% 0% no-repeat padding-box;
        }
        .action-btn-delete {
          // width: 115px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          border: none;
          cursor: pointer;
          color: white;
          width: 89px;
          background: #ea3943 0% 0% no-repeat padding-box;
          button.ant-btn.ant-btn-default {
            width: 89px;
            background: #ea3943 0% 0% no-repeat padding-box;
            color: white;
            opacity: 0.9;
            border-radius: 5px;
          }
        }

        .change-shifts-btn {
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          border: none;
          cursor: pointer;
          color: white;
          width: 189px;
          background: #1da193 0% 0% no-repeat padding-box;
        }
      }
    }
    .add-row {
      display: flex;
      gap: 20px;
      .add-element {
        display: flex;
        flex-direction: column;
        label {
          font: normal normal 600 16px/22px Open Sans;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          position: relative;
          background-color: white;
          border: none;
          border-radius: 5px;
        }
        .ant-input-number-input {
          background-color: white;
          border-radius: 5px;
        }

        .ant-input-number {
          border: none;
          border-radius: 5px;
        }
        input.ant-input {
          background: white;
          border: none;
          border-radius: 5px;
        }
        .add-icon {
          width: 32px;
          margin-top: 23px;
          height: 30px;
          background: #1da193 0% 0% no-repeat padding-box;
          border-radius: 5px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
