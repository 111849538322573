.politikat-festave-zyrtare {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.festaCard {
  width: 1200px;
  height: 790px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  opacity: 1;

  .ant-card-head {
    width: 100%;
    height: 45px;
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    color: #ffffff;
    opacity: 1;
    font: normal normal 600 16px/22px Open Sans;
  }

  .weekend {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    border: 1px solid #ffffff;
    width: 105px;
    color: #ffffff;
    background: #ea3943 0% 0% no-repeat padding-box;
    border-radius: 2px;
    height: 25px;
    margin-top: 18px;
  }
  .weekday {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    border: 1px solid #ffffff;
    width: 105px;
    height: 25px;
    color: #ffffff;
    background: #1da193 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    margin-top: 18px;
  }

  .dataIcon {
    // position: relative;
    // left: 5px;
    // width: 15px;
  }

  .dayOfWeekLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    font-size: 14px;
    color: #ffffff;
  }

  .dataZyrtare {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 9.8%;
    left: 16%;
    h3 {
      position: relative;
      bottom: 6px;
    }

    .dateItems {
      display: flex;
    }

    .data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      color: #323338;
      opacity: 1;
      margin-top: 4px;
      font-weight: 500;
    }
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 8%;
    left: 30%;
    h3 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 240px;
      position: relative;
      top: 8px;
    }
    .titleDivider {
      height: 28px;
      color: #323338;
      opacity: 1;
      margin-bottom: 15px;
      font-size: 15px;
      p {
        position: relative;
        right: 40px;
        margin-bottom: 5px;
      }
    }

    .daysOff {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
    }
  }
  .day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10.5%;
    left: 70%;
    h3 {
      position: relative;
      bottom: 3px;
    }
  }

  .selectDate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    height: 25px;
    color: #323338;
    opacity: 1;
    margin-top: 17.5px;
  }
  .saveBtn {
    display: flex;
    justify-content: flex-end;
    padding-top: 25px;
    .btn {
      width: 140px;
      background-color: #1da193;
      color: #ffffff;
      border-radius: 12px;
    }
  }
  .shikoMeSHumeBtn {
    padding-bottom: 15px;
    Button {
      width: 129px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #ffffff;
    }
  }
}

@media (max-width: 821px) {
  .festaCard .day {
    left: 70%;
  }
}
@media (max-width: 450px) {
  .politikat-festave-zyrtare {
    height: fit-content !important;
    .ant-card.ant-card-bordered.festaCard {
      height: 100% !important;
      width: 100% !important;
      margin: 15px;
    }
  }
  .festat-zyrtare-row {
    margin-bottom: 10px !important;
    display: grid !important;
    grid-template-columns: auto auto;
    .ant-col {
      max-width: 100%;
    }
  }
  .festaCard .dataZyrtare {
    position: relative !important;
    left: 0px !important;
  }
  .festaCard .selectDate {
    margin-top: 3px !important;
  }
  .festaCard {
    .description {
      font-style: 15px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
    }
  }
}
