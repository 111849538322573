@import "../../../../index.scss";

//* styles docCategoriesPanel justify-content:center, align-items:flex-start, flex-direction:column
.docCategoriesPanel {
  width: 100%;
  @include flex(center, flex-start, column);

//* styles categoriesPanelTitle font-size:16px, font-weight:bold, letter-spacing:0, and text color
  .categoriesPanelTitle {
    @include text(16px, bold, 0, #323338);
    margin: 25px 0;
  }

//* styles docCategoriesContainer justify-content:flex-start, align-items:center
  .docCategoriesContainer {
    width: 73%;
    @include flex(flex-start, center);
    flex-wrap: wrap;
  }
  span.doc {
    z-index: 1;
    margin-top: 12px;
    margin-bottom: -35px;
    margin-right: 200px;
  }
  &:hover {
    transform: scale(102%, 102%);
  }
}

//* responsive CategoriesPanel for iPad Air
@media (max-width: 821px) {
  .docCategoriesPanel .categoriesPanelTitle {
    font-size: 18px;
    margin: 25px 0 65px;
  }
  span.doc {
    z-index: 1;
    margin-top: 20px;
    margin-bottom: -30px;
    margin-right: 500px;
  }
}

//* responsive CategoriesPanel for iPad mini
@media (max-width: 769px) {
  span.doc {
    z-index: 1;
    margin-right: 470px;
  }
}

//* responsive CategoriesPanel for iPhone 12 pro
@media (max-width: 391px) {
  span.doc {
    z-index: 1;
    margin-top: 0;
    margin-bottom: -45px !important;
    margin-right: 200px;
  }
}