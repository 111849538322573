.user-card-skeleton-on-load {
  padding: 20px;
}

.loaded-user-merge-card {
  width: 100%;
  .container-user-employee {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px;
    .loaded-user-list {
      display: flex;
      justify-content: space-evenly;
      .loaded-user-first-row {
        display: flex;
        flex-direction: column;
        gap: 5px;
        span {
          display: flex;
          justify-content: baseline;
          align-items: center;
          gap: 12px;
        }
      }
    }
    .action-btns {
      display: flex;
      justify-content: space-evenly;
      gap: 30px;
      .dil-btn {
        width: 115px;
        border: none;
        height: 32px;
        background: #1da193 0% 0% no-repeat padding-box;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        color: white;
        align-items: center;
        // &:hover {
        //   opacity: 0.8;
        //   cursor: pointer;
        // }
      }
      .disable-btn {
        width: 115px;
        height: 32px;
        background: #ea3943 0% 0% no-repeat padding-box;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        color: white;
        align-items: center;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
      .delete-btn2 {
        width: 121px;
        height: 32px;
        border: none;
        background: #ea3943 0% 0% no-repeat padding-box;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        color: white;
        align-items: center;
      }
      .delete-btn {
        width: 121px;
        height: 32px;
        border: none;
        background: #ea3943 0% 0% no-repeat padding-box;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        color: white;
        align-items: center;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }

  .no-user-merge {
    // background-color: red;
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    padding-top: 17%;
    align-items: center;
    gap: 5px;
    .no-user-merge__account {
      font: normal normal 700 16px/22px Open Sans;
    }
    .no-user-merge__employee {
      font: normal normal 500 16px/22px Open Sans;
    }
    .add-user {
      width: 124px;
      height: 32px;
      color: white;
      border: none;
      margin-top: 5px;
      background: #1da193 0% 0% no-repeat padding-box;
    }
  }
}

.add-user {
  border: none;
  background-color: aliceblue;
  border: 0.1px solid rgba(128, 128, 128, 0.39);
  border-radius: 5px;
  cursor: pointer;
}

.session-table {
  .session-table-header {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr;
    justify-items: center;
    gap: 20px;
    margin-left: 0px;
    position: sticky;
    // .session__os {
    //   width: 70px;
    //   display: flex;
    //   justify-content: center;
    // }
    // .session__browser {
    //   width: 70px;
    //   display: flex;
    //   justify-content: center;
    // }
    // .session__location {
    //   width: 170px;
    //   display: flex;
    //   justify-content: center;
    // }
    // .session__device {
    //   width: 90px;
    //   display: flex;
    //   justify-content: center;
    // }
    // .session__time {
    //   width: 170px;
    //   display: flex;
    //   justify-content: center;
    // }
    // .session__action {
    //   width: 170px;
    //   display: flex;
    //   justify-content: center;
    // }
  }
  .session-table-cont {
    height: 168px;
    overflow-y: scroll;
    .session-table-row {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 2fr 2fr;
      justify-items: center;
      gap: 20px;
      margin-left: 0px;
      // .session__os {
      //   width: 70px;
      //   display: flex;
      //   justify-content: center;
      // }
      // .session__browser {
      //   width: 70px;
      //   display: flex;
      //   justify-content: center;
      // }
      // .session__location {
      //   width: 170px;
      //   display: flex;
      //   justify-content: center;
      // }
      // .session__device {
      //   width: 90px;
      //   display: flex;
      //   justify-content: center;
      // }
      // .session__time {
      //   width: 170px;
      //   display: flex;
      //   justify-content: center;
      // }
      .session__action {
        // width: 170px;
        // display: flex;
        // justify-content: center;
        button {
          min-width: 75px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .loaded-user-merge-card {
    .action-btns {
      gap: 10px !important;
      // overflow-x: auto;
      // overflow-y: hidden;
      span,
      button {
        min-width: max-content;
        padding: 0 10px;
      }
    }
  }
}
@media (max-width: 450px) {
  .loaded-user-merge-card {
    .loaded-user-list {
      flex-direction: column;
      gap: 5px;
      height: 100px;
      overflow-y: auto;
    }
    .action-btns {
      gap: 10px !important;
      overflow-x: auto;
      overflow-y: hidden;
      span,
      button {
        min-width: max-content;
        padding: 0 10px;
      }
    }
    .session-table {
      overflow: hidden;
      width: 100%;
      overflow-x: scroll;
      .session-table-header,
      .session-table-cont {
        width: 600px;
      }
    }
  }
}
