.ant-drawer.ant-drawer-right.ant-drawer-open.interviewDrawer {
  .ant-drawer-header {
    background: #1d3445;
    border-bottom: 1px solid #1d3445 !important;
  }
  button.ant-drawer-close {
    color: #fff;
    font-size: large;
    // margin-bottom: -50px;
    z-index: 4;
  }
  .ant-drawer-title {
    text-align: center;
    color: #1d3445;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .ant-tabs.ant-tabs-top.notification-tabs {
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      display: none;
    }
    .ant-tabs-nav {
      margin: 0px !important;
    }
    .ant-tabs-nav-wrap {
      background: #1d3445;
      justify-content: center;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      background: #1d3445;
      margin-bottom: -2px;
    }
    span.ant-badge {
      color: #fff;
      font-size: medium;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      background-color: #ffffff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
      border-bottom: none !important;
      span.ant-badge {
        color: #323338;
        text-align: center;
      }
    }
  }
  .ant-drawer-body {
    padding: 0px 0px 15px 0px;
    margin-top: -1px !important;
    .ant-table-thead {
      .ant-table-cell {
        padding: 10px 16px 10px 16px;
        background-color: #f1f1f5;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .ant-table-tbody {
      .ant-table-row:nth-child(odd) {
        background-color: #f1f1f5 !important;
      }
      .ant-table-row {
        cursor: pointer;
        .ant-table-cell {
          padding: 8px 16px 8px 16px;
          border-right: 1px solid #dee2eb;
          border-bottom: none;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
}
