.generate-modal {
  .ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    // margin-left: -12px;
    // width: 200px;
  }
  .input-form {
    width: 345px;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;
    .ant-modal-close {
      color: white;
      //   background-color: #1DA193;
      display: flex;
      justify-content: flex-end;
      //   height: 30px;
    }
  }

  .ant-modal-header {
    height: 55px;
    // background: #1D3445 0% 0% no-repeat padding-box;
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    color: white;
    .ant-modal-title {
      color: white;
      font-size: 18px;
      font-family: "Open Sans", sans-serif;
    }
  }

  .ant-modal-body {
    background-color: white;
    // height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-left: 20px;
  }

  .ant-modal-footer {
    height: 52px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: space-between;

    .close-btn {
      width: 150px;
      height: 32px;
      background: #ea3943 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      text-decoration: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
    .start-btn {
      width: 150px;
      height: 32px;
      //   background: #1DA193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      text-decoration: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }

    .finished-gen {
      .return-btn {
        border: none;
        width: 150px;
        height: 32px;
        background: #e1e1e1 0% 0% no-repeat padding-box;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          opacity: 0.85;
          border: 0.1px solid lightgray;
        }
      }
      .continue-btn {
        border: none;
        width: 150px;
        height: 32px;
        background: #1da193 0% 0% no-repeat padding-box;
        border-radius: 5px;
        color: #f5f5f7;

        cursor: pointer;
        &:hover {
          opacity: 0.85;
          border: 0.1px solid #18aa72d0;
        }
      }
      .print-btn {
        border: none;
        width: 150px;
        height: 32px;
        background: #1da193 0% 0% no-repeat padding-box;
        border-radius: 5px;
        color: #f5f5f7;

        cursor: pointer;
        &:hover {
          opacity: 0.85;
          border: 0.1px solid #18aa72d0;
        }
      }
    }
  }
}

@media only screen and (min-width: 745px) and (max-width: 851px) {
  .pay-mobile {
    display: block;
    .mob-margin {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .mob-margin-2 {
      margin-left: 20px;
      margin-right: 30px;
      margin-bottom: 20px;
    }
    .user-action-card {
      margin-top: 0px !important;
    }
    .label-show-details {
      width: auto;
    }
    .ant-row.frekuenca-row {
      display: grid;
    }
  }
  .ant-card.ant-card-bordered.pay-info-card {
    width: 100% !important;
    height: 400px !important;
  }
  .wages-data-chart {
    width: 100% !important;
  }
  .DATA-GRID-MOB {
    width: 100% !important;
    margin-left: -12px;
    margin-bottom: 20px;
  }
}

@media (max-width: 450px) {
  .pay {
    padding-right: 10px;
  }
  .ant-card.ant-card-bordered.pay-info-card {
    .blured-card-wrapper {
      width: 100% !important;
    }
    .ant-card-body {
      padding: 0px;
    }
    .drag-card-wrapper {
      width: 100% !important;
    }
    .styleHead {
      flex-direction: unset !important;
    }
    .secondStyle {
      flex-direction: unset !important;
    }
    .thirdStyle {
      flex-direction: unset !important;
    }
    .containersContent {
      .style-width-input {
        width: 50px !important;
      }
    }
  }
  .pay-mobile {
    display: block;
    padding: 10px;
    .mob-margin {
      margin-top: 10px;
      // margin-left: 10px;
      // margin-right: 10px;
    }
    .mob-margin-2 {
      // margin-left: 10px;
      // margin-right: 10px;
      margin-bottom: 20px;
    }
    .user-action-card {
      margin-top: 0px !important;
    }
    .label-show-details {
      width: auto;
    }
    .ant-row.frekuenca-row {
      display: grid;
    }
  }
  .ant-card.ant-card-bordered.pay-info-card {
    width: 100% !important;
    height: 100% !important;
  }
  .wages-data-chart {
    width: 100% !important;
  }
  .DATA-GRID-MOB {
    width: 100% !important;
    // margin-left: -12px;
    // margin-bottom: 20px;
  }
}

@media (max-width: 390px) {
  .DATA-GRID-MOB {
    width: 100% !important;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}
