.ant-drawer.ant-drawer-left.ant-drawer-open.menu-drower {
  .ant-drawer-body {
    padding: 0px !important;
  }
  .ant-drawer-content-wrapper {
    height: auto !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  li.ant-menu-item.ant-menu-item-active.sigurt-mobile {
    padding-left: 16px !important;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    span.ant-menu-title-content {
      color: #1d3445 !important;
    }
  }
  li.ant-menu-item.sigurt-mobile {
    padding-left: 16px !important;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    span.ant-menu-title-content {
      color: #1d3445 !important;
    }
  }

  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.mobile-menu {
    background: #fff;
  }

  .mobile-menu li.ant-menu-item.ant-menu-item-selected {
    background: #dce4e9 !important;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .mobile-menu li.ant-menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
}

@media (max-width: 450px) {
  li.ant-menu-submenu.ant-menu-submenu-vertical.subMenu-konfig {
    // height: 50px !important;
    span.ant-menu-title-content {
      color: #1d3445 !important;
    }
  }
  li.ant-menu-item.ant-menu-item-danger.subMenu-konfig {
    path#Path_8190 {
      fill: #1d3445 !important;
    }
    path#Path_8191 {
      fill: #1d3445 !important;
    }
    path#Path_8192 {
      fill: #1d3445 !important;
    }
    circle#Ellipse_228 {
      fill: #1d3445 !important;
    }
  }
}
