.konfigurimet {
  .konfigurimi-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1607843137);
    // height: calc(100vh - 145px);
    border-radius: 10px;
    background-color: #fff;
    transition: 0.2s ease-in;
    overflow: hidden;
    .table-header {
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f5f6f8;
      border-radius: 10px 10px 0 0;
      color: #323338;
      font-size: 16px;
      font-weight: 600;
      padding: 20px 20px;
      transition: 0.2s ease-in;
      p {
        margin: 0;
      }
    }
    .table-content {
      width: calc(100% - 20px);
    }

    .ag-theme-alpine {
      .ag-cell-label-container {
        .ag-icon {
          color: white;
        }
      }
      .ag-header-viewport .ag-header-container {
        background-color: #1d3445;
        .ag-header-row {
          color: white;
          font-weight: 600;
        }
      }
    }
  }
  .konfigurimi-footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 52px;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1607843137);
    animation: showFooter 1s ease-in-out;
  }
  .ag-root-wrapper.ag-layout-normal.ag-ltr {
    border-radius: 0 0 10px 10px;
  }
  .ag-root-wrapper.ag-layout-normal.ag-ltr {
    box-shadow: none;
  }
}

.newQuestionForFleetModal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      max-height: calc(100vh - 280px);
      // overflow: scroll;
      // overflow-y: auto;
      // overflow-x: hidden;
      .requiredSelect {
        .ant-select-selector {
          border: none;
          border-radius: 5px;
          background-color: #fff;
        }
      }
      .ant-form {
        .ant-form-item {
          margin-bottom: 10px;
          .ant-form-item-label {
            font-weight: 600;
            label {
              font-size: 15px;
            }
          }
        }
        .addOptionContainer {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: end;
          margin-bottom: 5px;
          .ant-form-item {
            margin: 0;
          }
          .addOptionInput {
            width: 100%;
          }
          .addButton {
            margin: 20px;
          }
          .addButton {
            width: 30px;
            height: 30px;
            background-color: #71cf48;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2px 0px 1px 10px;
            cursor: pointer;
            transition: 0.4s ease;
          }
          .disabled {
            @extend .addButton;
            background-color: #cfcfcf;
          }
        }
        .optionSection {
          &::-webkit-scrollbar {
            width: 5px;
          }
          height: 100%;
          max-height: 200px;
          overflow: scroll;
          .optionContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            animation: scopesFadeInBottom 500ms both;
            .ant-form-item {
              width: 100%;
              .ant-form-item-control-input-content {
                display: flex;
                .optionTitle {
                  width: 100%;
                }
                .childTitle {
                  width: calc(100% - 40px);
                }
              }
            }
            .removeButton {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fe4c4a;
              border-radius: 5px;
              margin: 2px 0px 0px 10px;
              cursor: pointer;
            }
          }
          .deletingOption {
            @extend .optionContainer;
            animation: scopesFadeOutBottom 500ms both;
          }
        }
      }
    }
  }
  .docOptionsContainer {
    .optionField {
      width: calc(100% - 60px);
    }
    .added {
      top: 28px;
    }

    .notAdded {
      top: 28px;
    }
  }
}

.buttonStyle {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1264a3;
  border-radius: 5px;
  cursor: pointer;
  svg {
    path {
      fill: #f1f1f1 !important;
    }
  }
}

.whiteIconBackground {
  path {
    fill: #fff !important;
  }
}

.confirm-modal {
  .ant-modal-content {
    .ant-modal-header {
      border-radius: 5px 5px 0 0;
    }
    .ant-modal-body {
      display: flex;
      justify-content: center;
      .confirm-body {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-direction: column;
      }
    }
    .ant-modal-footer {
      display: flex;
      gap: 30px;
      justify-content: center;
    }
  }
}

@keyframes scopesFadeInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes scopesFadeOutBottom {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
}
@keyframes showFooter {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 450px) {
  .konfigurimet {
    .konfigurimi-body {
      .table-header {
        flex-direction: column;
        height: max-content;
        gap: 10px;
      }
    }
  }
}
