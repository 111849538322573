@import "../../../../../index.scss";

.documentationSelectorModal {
  //* styles selectorContainer align-items:center, justify-content:space-between, and flex-direction:column
  .selectorContainer {
    width: 100%;
    @include flex(center, space-between, column);
  }

  .docSelector {
    margin-bottom: 20px;
    width: 400px;
    .ant-select {
      border: 0;
      border-radius: 10px;
      background-color: #f5f5f7;
    }
  }

  //* styles docSelectorActionButtons align-items:center, justify-content:center
  .docSelectorActionButtons {
    @include flex(center, center);
  }

  .cancelButton {
    @include mondayButton(#f5f5f7, #e6e9ef, #323338);
    margin-right: 20px;
  }

  .saveButton {
    @include mondayButton(#71cf48, #68c142);
    min-width: 150px;
  }
}
.labeledInputLabel {
  font-weight: 600;
}

//* responsive for iPhone 13 Pro
@media (max-width: 391px) {
  .documentationSelectorModal .docSelector {
    width: auto;
  }
}
