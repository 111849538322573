.edit-qotd-modal {
  .ant-modal-content {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
  }
  .ant-modal-body {
    .edit-qotd-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .input-holder {
        display: flex;
        gap: 5px;
        .ant-input {
          border: none;
          background-color: #f5f5f7;
        }
      }
    }
  }
  .ant-modal-content {
    // height: calc(100vh - 20px) !important;
    // height: calc(100dvh - 20px) !important;
    .ant-modal-close {
      height: 35px;
      width: 35px;
      margin-right: 12px;
      .ant-modal-close-x {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #e7eef2;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .ant-modal-footer {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    border: none;
    .mondayButtonRed {
      background-color: #fe4c4a;
      .mondayButtonDivider {
        background-color: #fe4c4a;
      }
    }
  }

  .ant-modal-close-x {
    color: white;
  }
}
