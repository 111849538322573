.loader-wrapper-2-parent {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100% !important;
	// background-image: url("https://i.ibb.co/rvGkCCF/crhr.png");
	// background-image: url("https://images.unsplash.com/photo-1608315398428-c6d76804838d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
	// background-image: url("https://images.unsplash.com/photo-1542664483-9a6423c9bd73?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
	// background-image: linear-gradient(rgba(184, 131, 32, 0.5), rgba(236, 54, 54, 0.5)),
	// 	url("https://images.unsplash.com/photo-1571680008086-5d37dffeffb3?auto=format&fit=crop&q=80&w=1931&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
	// background-image: url("https://gcdnb.pbrd.co/images/1OHgclWUskyk.jpg?o=1");
	background-size: cover;
	width: 100%;
	.loader-wrapper-2 {
		// position: absolute;
		// top: 45%;
		// left: 46%;
		// transform: translate(-50%, -50%) rotate(45deg);
		width: 150px;
		height: 150px;
		// animation: rotation 0.6s infinite linear;

		//   background-color: rgba(0, 251, 0, 0.315);
		//   .flex-logo-spinner {
		//     animation: rotation 5s infinite linear;
		//   }
	}
	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(359deg);
		}
	}
}
.snowflake {
	display: flex;
	position: absolute;
	width: 0.1%;
	color: #fffafa;
	opacity: 0;
	// font-size: 120px;
	margin: 0;
	padding: 0;
	animation: fall 16s linear infinite;
	// background-image: url("https://www.clipartmax.com/png/middle/156-1563013_snowflake-icon.png");
}

@keyframes fall {
	0% {
		opacity: 0;
	}
	3% {
		opacity: 0.9;
	}

	90% {
		opacity: 0.9;
	}
	100% {
		transform: translate(0, 97vh);
		opacity: 0;
	}
}

// *,*:after,*:before{
// 	-webkit-box-sizing: border-box;
// 	-moz-box-sizing: border-box;
// 	-ms-box-sizing: border-box;
// 	box-sizing: border-box;
// }
// body{
// 	font-family: arial;
// 	font-size: 16px;
// 	margin: 0;
// 	background: #fff;
// 	color: #000;
// }

.snow_wrap {
	height: 90vh;
	width: 84%;
	// background: url("../images/pic.jpg");
	// background-size: cover;
	background-position: center bottom;
	overflow: hidden;
	overflow-y: hidden !important;
	// overflow-x: hidden !important;
	position: absolute;
	z-index: 43333333;
	pointer-events: none;
}

.snow,
.snow:before,
.snow:after {
	position: absolute;
	top: -650px;
	left: 0;
	bottom: 0;
	right: 0;
	background-image: url("./spider_icon.png"), url("./spider_icon.png");
	// radial-gradient(6px 6px at 200px 150px, #fff, transparent),
	// radial-gradient(3px 3px at 300px 250px, #fff 50%, transparent),
	// radial-gradient(4px 4px at 400px 350px, #fff 50%, transparent),
	// radial-gradient(6px 6px at 500px 100px, #fff 50%, transparent),
	// radial-gradient(3px 3px at 50px 200px, #fff 50%, transparent),
	// radial-gradient(4px 4px at 150px 300px, #fff 50%, transparent),
	// radial-gradient(6px 6px at 250px 400px, #fff 50%, transparent),
	// radial-gradient(3px 3px at 350px 500px, #fff 50%, transparent);
	background-size: 65px 65px;

	animation: snow 3s linear infinite;
	// content: "";
	// z-index: inherit;
}

.snow:after {
	margin-left: -250px;
	opacity: 0.5;
	filter: blur(2px);
	animation-duration: 6s;
	animation-direction: reverse;
}

.snow:before {
	margin-left: -350px;
	opacity: 0.7;
	filter: blur(1px);
	animation-duration: 9s;
	animation-direction: reverse;
}

@keyframes snow {
	to {
		transform: translateY(650px);
	}
}
