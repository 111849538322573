.ant-layout-header {
  height: 70px !important;
  background: #fbfbfb !important;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0px !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  // -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  // box-shadow: 0 2px 3px rgba(76, 79, 83, 0.3);
}

.time-weather-div {
  display: flex;
  font-size: 16px;
  font-weight: 500;
}
.fahrenheit-span {
  margin-left: 25px;
  display: flex;
  cursor: pointer;
}

.header-app {
  align-items: center;
  // height: fit-content;
  height: 100%;
  width: 100%;
  .user-info {
    font-size: larger;
    font-weight: 600;
    position: absolute;
  }
  .logo-header {
    margin-right: 5%;
    cursor: pointer;
    position: absolute;
  }
  .icons svg {
    // background: #B31312;
    // Autumn Theme
    background: #1d3445;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    color: #fbfbfb;
    padding: 6px;
    // margin-right: 20px;
    cursor: pointer;
  }
  .icons {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 39px;
    margin-right: 30px;
    line-height: 0;
  }

  .icons-first svg {
    margin-left: 30px;
    background: #0f0f0f;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    color: #fbfbfb;
    padding: 6px;
    margin-right: 20px;
    cursor: pointer;
  }
  .icons-div {
    display: flex;
    align-items: center;
  }
  .user-title h1 {
    font-weight: 600;
    font-size: medium;
  }
  .search-effect {
    margin-right: 20px;
    margin-top: 3px;
  }
  .ant-layout-header {
    height: 119px !important;
    // background: #fff !important;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 0px;
  }
  path#Path_7415_00000134212190235252654350000000058067522740290455_ {
    fill: #fbfbfb;
  }
  button.ant-btn.ktheu-btn {
    background: #0f0f0f;
    color: #fbfbfb;
    border-radius: 5px;
  }
}

.ant-popover.ant-popconfirm.popup-logout-modal.ant-popover-placement-left {
  width: 542px !important;
  height: 70px;
  .ant-popover-inner-content {
    height: 70px !important;
    display: flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .ant-popover-inner {
    border-radius: 10px !important;
  }
  .ant-popover-message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-popover-message-title {
    margin-right: 13px;
    font-weight: 500;
  }
  .ant-popover-buttons {
    height: 70px;
    margin: 0px;
    padding-left: 8.2px;
    button.ant-btn.ant-btn-sm {
      width: 50px;
      height: 70px;
      background: #ff4e00;
      border: none;
      color: #fbfbfb;
      border-radius: 0px;
      cursor: pointer;
    }
    button.ant-btn.ant-btn-primary.ant-btn-sm {
      width: 50px;
      height: 70px;
      border: none;
      background: #1da193;
      margin: 0px;
      color: #fbfbfb;
      border-radius: 0px 8px 8px 0px;
      cursor: pointer;
    }
  }
}
.icons-kryefaqja {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons-kryefaqja svg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbfbfb;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  color: #fbfbfb;
  padding: 6px;
  margin-right: 20px;
  cursor: pointer;
  path#Path_7411_00000052097550595528871170000001066866502481234860_ {
    fill: black;
  }
  path#Path_7412_00000127017924034809551100000006747330487866600615_ {
    fill: black;
  }
}

.timeClock {
  margin-bottom: 2px;
}

.user-title h1 {
  color: #fbfbfb;
}

@keyframes iconHoverScale {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1400px) {
  .dashboard .dashboard-first-row {
    grid-template-columns: auto auto;
    gap: 20px;
    display: grid;
    padding-right: 20px;
  }
  .col-header-name.qotd {
    display: none !important;
  }
}
@media (max-width: 830px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.notification-drawer {
    width: 650px !important;
    .ant-drawer-content-wrapper {
      width: 650px !important;
    }
    .single-notif .notif-actions {
      min-width: 71px;
      display: contents;
    }
  }
  .ag-grid-punojsit {
    width: 94% !important;
  }
  .ag-grid-punojsit .ag-grid-header {
    flex-wrap: wrap;
    gap: 7px;
  }
  .time-sheet-header-comp {
    flex-wrap: wrap;
    gap: 10px;
  }
  // .progress-shift-bar {
  // 	width: 350px !important;
  // }
  .ant-row.small-cards-view {
    margin-top: 10px;
  }
  div#supervisor {
    margin-left: 20px;
  }
  .timesheet-wrapper .timesheet-calculations .timesheet-progress-wrapper {
    max-height: 900px !important;
  }
  .ant-row.harder-row {
    flex-wrap: wrap !important;
    flex-flow: column !important;
    .ant-col {
      flex-wrap: wrap !important;
      gap: 10px !important;
    }
    button.ant-btn.perdorues-te-ri-btn {
      margin-top: -35px !important;
    }
  }
  .ant-modal.punojsit-filter {
    width: 400px !important;
    left: 0px !important;
    height: 250px !important;
    .ant-modal-body {
      width: 750px !important;
    }
    .ant-col-xs-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 25.833333%;
    }
    .ant-col.col-5.ant-col-xs-15.ant-col-xs-offset-1.ant-col-lg-4.ant-col-lg-offset-2 {
      max-width: 25.833333%;
    }
    button.ant-btn.charge-search-btn {
      margin-top: 30px;
    }
  }
  .punojsit-filter button.ant-btn.charge-close-btn {
    margin-left: 40px !important;
    margin-top: 30px !important;
  }

  .punojsit-filter .ant-modal-body {
    width: 750px !important;
  }
  .punojsit-filter .ant-modal-content {
    width: 750px !important;
    height: 250px !important;
  }
  .dynamicViewPage {
    .employe-view-row {
      display: grid;
      grid-template-columns: 360px 360px;
      .ant-card.ant-card-bordered.info-card {
        width: 350px !important;
        margin-top: 10px;
      }
      .ant-card.ant-card-bordered.personal-info-card {
        width: 350px !important;
        form.ant-form.ant-form-horizontal.personal-info {
          width: 310px;
        }
      }
    }

    .container {
      flex-wrap: wrap !important;
      .ant-card.ant-card-bordered.personal-info-card {
        width: 100% !important;
        margin-top: 10px;
      }
      .ant-card.ant-card-bordered.info-card {
        width: 100% !important;
      }
    }
    .ant-card.ant-card-bordered.personal-info-card {
      width: 500px !important;
      margin-top: 10px;
    }
    .ant-card.ant-card-bordered.info-card {
      width: 500px !important;
    }
    .pay {
      .ant-row {
        justify-content: flex-start;
      }
      .payment-grid {
        width: 95% !important;
      }
    }
  }
  .shift-modal .ant-modal-content {
    width: 800px !important;
  }
  .ant-modal.shift-modal {
    width: 800px !important;
    .ant-modal-content {
      width: 800px !important;
    }
    .ag-grid-shifts {
      width: 730px !important;
      height: 100%;
    }
  }
}

@media (max-width: 821px) {
  .ant-row.header-row-konfigurimet {
    .ant-col.ant-col-3 {
      display: none !important;
    }
  }
  .ant-row.header-row {
    padding-left: 0px !important;
    .times-div {
      width: 482px !important;
      gap: 10px !important;
    }
    .header-app .icons {
      margin-right: 10px !important;
    }
  }
}
@media (max-width: 769px) {
  .header-app .icons {
    margin-right: 10px !important;
  }
  .ant-row.header-row {
    padding-left: 0px !important;
    .times-div {
      width: 460px !important;
      gap: 10px !important;
    }
    .time-weather-div {
      font-size: 14px !important;
    }
  }
}
@media (max-width: 600px) {
  .header-for-mobile {
    width: 100%;
    height: 100%;
  }
  .header-dashbord-mobile {
    width: 100%;
    justify-content: flex-end;
    margin-right: 10px;
    display: flex;
  }
  .header-konfig-mobile {
    height: 70px !important;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center !important;
    background: #b31312;
    .icons-div {
      height: 100% !important;
      display: flex;
      align-items: center !important;
    }
    .user-title {
      width: 190px !important;
      h1 {
        font-size: 10px !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 190px;
        font: normal normal 500 11px/15px "Open Sans", sans-serif;
      }
    }
    .icons-kryefaqja {
      display: flex;
      justify-content: center;
      align-items: center;
      position: static;
    }
  }
  .icons-kryefaqja-responsive {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px !important;
    border-radius: 5px;
    background: #fbfbfb;
  }
}

@media (max-width: 391px) {
  .ant-popover.ant-popconfirm.popup-logout-modal.ant-popover-placement-left {
    width: 320px !important;
    left: 0px !important;
  }
  .ant-popover.ant-popconfirm.popup-logout-modal.ant-popover-placement-left
    .ant-popover-message-title {
    font-size: small;
    margin-right: 0px !important;
  }
  .ant-popover.ant-popconfirm.popup-logout-modal.ant-popover-placement-left
    .ant-popover-inner-content {
    padding-left: 0px !important;
  }
  .ant-row.header-row {
    width: 310px !important;
    .ant-col.ant-col-16.col-header-name {
      margin-left: 20px;
      .user-info {
        width: fit-content;
        margin-left: -10px;
        font-size: 10px !important;
      }
    }
    .ant-col.ant-col-5 {
      justify-content: flex-end !important;
    }
  }

  .header-dashbord-mobile {
    width: 100% !important;
  }

  .ant-row.header-row-konfigurimet {
    padding-left: 0px !important;
    .ant-col.ant-col-3 {
      display: none;
    }
  }
  .times-div {
    display: none !important;
  }
  // .icons-div{
  // 	margin-right: -35px !important;
  // }
  // .ant-layout-header .header-app {
  //   display: grid;
  //   grid-template-columns: 310px !important;
  //   width: 310px !important;
  //   .ant-row.header-row {
  //     width: 310px !important;
  //   }
  // }
  // .header-app .ant-col.ant-col-16.konfig-header .user-title {
  //   padding-left: 34px !important;
  //   // width: 272px !important;
  // }
}

.background-img-header {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  //  border: 1px solid #B31312;
  border-radius: 5px;
}

.flex-wish-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  // background-image: url("https://plus.unsplash.com/premium_photo-1669799891975-8f668765be09?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80");
  // object-fit: scale-down;
}
