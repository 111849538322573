@import "../../../../../index.scss";
.interviewsCalendar {
  .interviewsCalendarContainer {
    margin: 0px !important;
    // height: 405px;
    border-radius: 10px;
    background: #f1f1f1;
    label.ant-radio-button-wrapper {
      background: #fbfbfb !important;
    }
    .ant-radio-button-wrapper-checked:not(
        [class*=" ant-radio-button-wrapper-disabled"]
      ).ant-radio-button-wrapper:first-child {
      border-right-color: #1d3445 !important;
    }
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):first-child {
      border-color: #1d3445 !important;
      color: #1d3445 !important;
    }
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):before {
      background-color: #1d3445;
    }

    label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      border-color: #1d3445 !important;
      color: #1d3445 !important;
    }
    .ant-picker-calendar {
      background: #f1f1f1;
      border-radius: 10px;
      padding: 20px;
    }
    .ant-picker-calendar .ant-picker-panel {
      background: #fbfbfb;
      border-top: 1px dashed black;
      padding: 0 10px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none !important;
      background: none !important;
    }
    span.ant-select-arrow {
      display: none;
    }
    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner:before {
      border: none;
    }
    label.ant-radio-button-wrapper {
      margin-right: 5px;
    }
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
      .ant-select-selector {
      padding: 0 3px;
    }
    .ant-select-single.ant-select-sm:not(
        .ant-select-customize-input
      ).ant-select-show-arrow
      .ant-select-selection-item,
    .ant-select-single.ant-select-sm:not(
        .ant-select-customize-input
      ).ant-select-show-arrow
      .ant-select-selection-placeholder {
      padding: 0px;
      font-size: MEDIUM;
      font-weight: 500;
    }
    .event-calendar.calendar-tooltip {
      @include flex(center, center);
      color: #fff !important;
      width: 35px;
      height: 30px;
      // margin-left: 5px;
      border-radius: 5px;
      z-index: 4;
      pointer-events: visible;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      color: rgb(26, 24, 24);
      background: none;
    }

    .custom-date-wrapper {
      width: 100%;
      height: 100%;
      @include flex(center, center);
    }
    tbody {
      tr {
        max-height: 30px;
        .ant-picker-cell {
          padding: 0 !important;
          .date-cell {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &:hover {
            background-color: #e3ebf1;
          }
          .date-cell.selected-date {
            background: #2e5d9e;
            color: #fff;
          }
        }
      }
    }
  }
}
