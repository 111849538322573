.punonjesit-ag-grid {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  .ag-grid-header {
    display: flex;
    gap: 20px;
    background: transparent;
  }
}
.request-details-btn.ant-btn {
  background-color: #1da193;
  color: white;
  text-align: center;
  font: normal normal normal 14px/19px "Open Sans";
  border-radius: 6px;
}
.status-column {
  width: 100%;
  height: 38px;
  color: white;
  display: flex;
  padding-left: 10px;
  justify-content: flex-start;
  align-items: center;
  font-family: "Open Sans";
  border-radius: 3px;
}

.user-requests-aggrid {
  .Print-Punojsit {
    visibility: collapse;
  }
}
