.shift-log-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .alert-no-changes {
    .ant-alert {
      width: 100%;
      display: flex;
      justify-content: center;
      // text-align: center;
    }
    .ant-alert-content {
      text-align: center;
    }
    .anticon {
      text-align: center;
      // background-color: red;
      margin-left: -220px;
      margin-top: 4px;
      position: absolute;
    }
  }
}

.ant-drawer-header {
  height: 45px;
  background-color: #e7eef2 !important;
  color: white;
  .ant-drawer-title {
    color: #1d3445;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.ant-drawer-close {
  // background-color: red !important;
  fill: white !important;
  color: wheat !important;
  margin-left: -10px;
  svg {
    fill: white;
    color: white;
  }
}
