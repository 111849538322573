.njoftime {
  // width: 1630px;
  // padding: 5px 20px 0px 20px;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.card-container {
  background: #fff !important;
  height: 100%;
  // border-radius: 10px;
  box-shadow: 1px 1px 1px 1px #dfdfde;
  // .ant-tabs-nav-wrap {
  //   // border-top-left-radius: 10px;
  //   // border-top-right-radius: 10px;
  // }
  border-radius: 10px;
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-bottom
    > div
    > .ant-tabs-nav
    .ant-tabs-tab
    + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top
    > div
    > .ant-tabs-nav
    .ant-tabs-tab
    + .ant-tabs-tab {
    margin-right: 0;
  }
  .ant-tabs-nav-list {
    width: 100% !important;
  }

  .ant-tabs-tab:hover {
    color: #1d3445 !important;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    border: none;
    width: -webkit-fill-available;
    justify-content: center;
    background: #f1f7fb;
    font-weight: 600;
    svg {
      margin-right: 5px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    // background: #1D3445 !important;
    background: #1da193 !important;
    color: #fff !important;
    svg {
      margin-right: 5px;
    }
    path#Path_8272 {
      fill: #fff;
    }
    path#Path_8273 {
      fill: #fff;
    }
    path#Path_8274 {
      fill: #fff;
    }
    path#Path_8275 {
      fill: #fff;
    }
    path#Path_8276 {
      fill: #fff;
    }
    path#Path_8278 {
      fill: #fff;
    }
    path#Path_8279 {
      fill: #fff;
    }
    path#Path_8280 {
      fill: #fff;
    }
    path#Path_8281 {
      fill: #fff;
    }
    path#Path_8282 {
      fill: #fff;
    }
    path#Path_8283 {
      fill: #fff;
    }
    path#Path_8284 {
      fill: #fff;
    }
    path#Path_7414_00000160884763990628686030000012834844531731564702_ {
      fill: #fff;
    }
    path#Path_7413_00000169551406889492978480000003617827709912210329_ {
      fill: #fff;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
    text-shadow: 0 0 0.25px currentColor;
  }
}

.njoftime {
  .card-container {
    path#Path_7414_00000160884763990628686030000012834844531731564702_ {
      fill: #1d3445;
    }
    path#Path_7413_00000169551406889492978480000003617827709912210329_ {
      fill: #1d3445;
    }
  }
}
