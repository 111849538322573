.new-shift-approvals {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  .shiftFiltersContainer {
    width: 100%;
    .more-shift-filters2 {
      overflow: hidden;
      height: 75px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding-left: 20px;
      padding-right: 10px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 4px #00000029;
      border-radius: 10px;
      opacity: 1;

      .new-shift-filter-input {
        width: 100%;
        max-width: 180px;
        .ant-select-selector {
          background-color: #f1f7fb;
          border: none;
          border-radius: 5px;
        }
        .ant-picker {
          border: none;
          width: 205px;
          background-color: #f1f7fb !important;
          .ant-picker-input > input {
            text-align: start;
          }
        }
      }

      .activeEmployeesCheckbox {
        min-width: 200px;
      }
      .new-shift-filter-datepicker {
        border: none;
        width: 100%;
        max-width: 200px;
        background-color: #f1f7fb !important;
        border-radius: 5px;
        .ant-picker-input > input {
          text-align: start;
        }
      }
      .ant-picker-outlined {
        border: none;
        background: #f1f7fb;
      }
    }
  }
  .employee-shifts-approvals {
    height: 100%;
    width: 100%;
    .ag-grid-shifts-approvals {
      border-radius: 10px;
      height: calc(100% - 45px);
      width: 100%;
      .ag-grid-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 45px;
        background: #ffffff 0% 0% no-repeat padding-box;
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        .searchInput {
          width: 300px;
          min-width: 100px;
          border-radius: 5px;
          border: none;
          background-color: #f1f7fb;
          > * {
            background-color: #f1f7fb;
          }
          input::placeholder {
            color: #1d3445;
            background-color: #f1f7fb;
          }
        }
      }
    }

    .ag-theme-alpine .ag-header {
      background: #1d3445 !important;
      border: none;
    }

    .ag-theme-alpine .ag-root-wrapper {
      border-bottom-left-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
    }

    .ag-theme-alpine .ag-header-row.ag-header-row-column {
      color: #ffffff !important;
      font-weight: 400;
      font-size: medium;
    }

    .ag-theme-alpine
      .ag-row-odd.ag-row.ag-row-position-absolute.ag-row-not-inline-editing {
      background: #f5f5f7 !important;
      border: none !important;
    }

    .ag-theme-alpine
      .ag-row-even.ag-row.ag-row-position-absolute.ag-row-not-inline-editing {
      border: none !important;
    }

    //hover cells ag grid

    .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-not-inline-editing:hover {
      background: #e1e1e6 !important;
    }

    .ag-theme-alpine
      .ag-row-even.ag-row.ag-row-position-absolute.ag-row-not-inline-editing:hover {
      background: #e1e1e6 !important;
    }

    .ag-theme-alpine
      .ag-row-odd.ag-row.ag-row-position-absolute.ag-row-not-inline-editing:hover {
      background: #e1e1e6 !important;
    }

    .ag-row-even.ag-row-position-absolute.ag-row-focus {
      background: #fff;
      border: none;
    }

    .ag-row-odd.ag-row-position-absolute.ag-row-focus {
      background: #f6f6f6;
      border: none;
    }

    .ag-row-even.ag-row-position-absolute.ag-row-focus:hover {
      background: #e1e1e6;
    }

    .ag-row-odd.ag-row-position-absolute.ag-row-focus:hover {
      background: #e1e1e6;
    }

    .ag-theme-alpine .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
      background: #1d3445;
    }
    .ag-row-even.ag-row-selected:hover {
      background: #e1e1e6 !important;
    }

    .ag-theme-alpine
      .ag-row-odd.ag-row.ag-row-position-absolute.ag-row-focus.ag-row-selected:hover {
      background: #e1e1e6 !important;
    }

    .ag-theme-alpine
      .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-selected.ag-row-not-inline-editing:hover {
      background: #e1e1e6 !important;
    }

    .ag-theme-alpine .ag-checkbox .ag-input-wrapper {
      background: #1d3445;
      width: 20px;
      height: 18px;
    }
    .ag-theme-alpine .ag-checkbox-input-wrapper::after {
      color: #1d3445 !important;
    }

    .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
      color: #1d3445 !important;
      background: #ffffff !important;
    }

    .ag-theme-alpine .ag-checkbox-input-wrapper {
      font-size: 20px !important;
    }

    .ag-theme-alpine .ag-row-selected {
      background: #cdd3df !important;
    }

    .ag-theme-alpine
      .ag-row-odd.ag-row.ag-row-position-absolute.ag-row-focus.ag-row-selected {
      background: #cdd3df !important;
    }

    .ag-theme-alpine
      .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-selected.ag-row-not-inline-editing {
      background: #cdd3df !important;
    }

    .ag-theme-alpine .ag-paging-panel {
      border: none !important;
    }

    .ag-theme-alpine a {
      color: #323338;
    }

    .ag-theme-alpine span.ag-header-icon.ag-header-cell-menu-button {
      color: #fff !important;
    }
    .ag-theme-alpine span.ag-icon.ag-icon-asc {
      color: #fff !important;
    }
    .ag-theme-alpine span.ag-icon.ag-icon-desc {
      color: #fff !important;
    }
  }
  .Print-Punojsit {
    display: none;
  }
}
@media (max-width: 1200px) {
  .shiftFiltersContainer {
    overflow-x: scroll;
    .new-shift-approvals {
      width: 1000px;
    }
  }
}

@media (max-width: 821px) {
  .more-shift-filters2 {
    flex-wrap: wrap;
    height: fit-content !important;
    padding: 20px 20px;
  }
}
