$card_blue: #1d3445;

.request-status-modal .ant-modal-content {
	border-radius: 20px 20px 10px 10px;
	width: 300px;
	height: 100%;
	margin-right: 300px !important;
	margin-left: 180px !important;
	margin-top: 200px;
}
.request-status-modal .ant-modal-header {
	border-radius: 10px 10px 0px 0px;
	background-color: #1d3445 !important;
	font-weight: bolder;
}
.request-status-modal .ant-modal-title {
	color: white;
	font-weight: 600;
	font-size: 16px;
	font-family: "Open Sans", sans-serif;
}
.request-status-modal .ant-modal-close {
	color: white;
	font-size: 16px;
	font-weight: 600;
}
.label-text {
	text-align: left;
	font: normal normal 600 16px/22px "Open Sans";
	color: #323338;
}
.modal-status-text {
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 1px 1px;
	margin: 5px 10px;
	border-radius: 5px;
	color: #fff;
}
