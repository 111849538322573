.pergjithshme-container {
  width: 100%;
  .content-container {
    .divider {
      border: 2px solid #f1f1f1;
      margin: 5px 0;
      border-radius: 5px;
    }
    .first-section {
      padding: 5px;
      p {
        font-size: 25px;
        font-weight: 600;
      }
    }
    .tags-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      .tag-container {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;

        width: calc(85% - 5px);
        display: flex;
        flex-wrap: nowrap;
        padding: 5px;
        max-width: calc(100% - 5px);
        overflow-x: auto;
        .ant-tag {
          border: none;
          border-radius: 30px;
          padding: 5px 0 5px 20px;
          background-color: #f5f5f7;
          font-weight: 600;
          .anticon {
            position: relative;
            top: -10px;
            left: 5px;
            background-color: #ea3943;
            border-radius: 50%;
            padding: 4px;
            svg {
              fill: #fff;
            }
          }
        }
      }
      .add-tag-btn {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #20ac9d;
        border: none;
        color: #ffffff;
      }
    }
    .personal-information {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px;
      p {
        margin-bottom: 0;
      }
      span {
        font-weight: 600;
      }
    }
    .applicant-work-information {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px;
      p {
        margin-bottom: 0;
      }
      span {
        font-weight: 600;
      }
      .password-show {
        display: flex;
        gap: 10px;
        button {
          border: none;
          background-color: white;
        }
        button:hover {
          border-radius: 5px;
          background-color: #f1f1f1;
        }
      }
    }
    .show-applicant-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      .first-container {
        flex: 1 1 0;
        padding: 5px;
        .title {
          align-items: flex-start;
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        span {
          font-weight: 600;
        }
        .quill-title {
          font-weight: 600;
          // padding-bottom: 3px;
        }
        .university-container {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
          min-width: 150px;
          overflow: hidden;
          p {
            flex: 1 0 auto;
            margin-bottom: 5px;
          }
        }
        .university-container:hover {
          overflow: visible;
          p {
            z-index: 1;
          }
        }
        .quill {
          .ql-container {
            height: 140px;
            border: none;
            .ql-editor {
              max-height: 140px;
              padding: 0 20px 0 0;
            }
          }
        }
      }
      .second-container {
        width: 100%;
        flex: 1 1 0;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-weight: 600;
        }
        .quill {
          .ql-container {
            border: none;
            .ql-editor {
              max-height: 250px;
              // border: 1px solid black;
              padding: 10px 10px 10px 0;
            }
          }
        }
      }
      .divider {
        border: 2px solid #f1f1f1;
      }
    }
  }
  .ant-divider {
    background-color: #f8f8fa;
    height: 5px;
    border-radius: 5px;
    margin: 10px 0 10px 0;
  }
  .custom-padding {
    .ant-space {
      padding: 0 20px 0 20px;
    }
  }
  .tags-container {
    padding: 0 20px 0 20px;
    gap: 10px;
    height: 100%;
    .row-medium-gap {
      flex: 1;
      align-items: center;
      gap: 20px;
      .ant-space {
        flex: 1;
        padding: 5px;
        overflow-x: auto;
        .ant-tag {
          border: none;
          border-radius: 30px;
          padding: 5px 0 5px 20px;
          background-color: #f5f5f7;
          font-weight: 600;
          .anticon {
            position: relative;
            top: -10px;
            left: 5px;
            background-color: #ea3943;
            border-radius: 50%;
            padding: 4px;
            svg {
              fill: #fff;
            }
          }
        }
      }
    }
    .ant-col {
      display: flex;
      align-items: center;
      .custom-button {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #20ac9d;
        border: none;
        color: #ffffff;
      }
    }
  }
  .custom-container {
    display: flex;
    height: 100%;
    .more-information {
      h2 {
        margin: 0 0 5px 0;
      }
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 3px;
      .ql-container {
        max-height: 130px;
        padding: 0 0 0 10px;
        .ql-editor {
          padding: 10px 5px 10px 0;
        }
      }
    }
    .custom-divider {
      background-color: #f8f8fa;
      height: 210px;
      margin: 0 10px 0 10px;
      width: 5px;
    }
    .reference {
      width: 40%;
      .ql-container {
        max-height: 180px;
        overflow-y: auto;
        padding: 0 0 0 10px;
        .ql-editor {
          padding: 10px 5px 10px 0;
        }
      }
    }
    .ql-container {
      border: none;
      max-height: 130px;
      .ql-editor {
        padding: 0;
      }
    }
  }
}

.tag-modal {
  width: calc(100vw - 20px) !important;
  max-width: 700px !important;
  .ant-modal-body {
    height: calc(100% - 115px);
    .tag-modal-container {
      height: calc(100vh - 5px);
      max-height: 375px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .modal-header {
        width: calc(100% - 10px);
        display: flex;
        gap: 10px;
        align-content: center;
        p {
          margin: 0;
          span {
            font-weight: 700;
          }
        }
      }
      .modal-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .input-container {
          display: flex;
          gap: 10px;
          .ant-input {
            border-radius: 5px;
            background-color: #f8f8fa;
            border: none;
            width: 200px;
          }
          .custom-add-button {
            border: none;
            border-radius: 5px;
            background-color: #f8f8fa;
          }
          .input-left-padding {
            padding-left: 10px;
          }
        }
        .tags-container {
          display: flex;
          flex-flow: wrap;
          max-height: 250px;
          overflow-y: scroll;
          padding: 5px;
          gap: 9px 5px;
          .ant-tag {
            border: none;
            border-radius: 30px;
            padding: 5px 0 5px 20px;
            background-color: #f5f5f7;
            font-weight: 600;
            .anticon {
              position: relative;
              top: -10px;
              left: 5px;
              background-color: #ea3943;
              border-radius: 50%;
              padding: 4px;
              svg {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    border: none;
    padding: 20px 29px;
    .flex-between {
      display: flex;
      justify-content: space-between;
    }
    .ant-btn {
      border-radius: 5px;
    }
    .custom-confirm-button {
      background-color: #20ac9d;
      border: none;
      color: #ffffff;
    }
    .custom-confirm-button:hover {
      background-color: #20ac9ebd;
    }
  }
}

.avantazhetDisavantazhet-container {
  width: 100%;
  height: 100%;
  .content-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    .divider {
      border: 2px solid #f1f1f1;
      margin: 5px 0;
      border-radius: 5px;
    }
    .sections {
      flex: 1 1 0;
      .title {
        display: flex;
        flex-direction: row;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .tag-container {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;

        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        padding: 5px;
        overflow-x: auto;
        .ant-tag {
          border: none;
          border-radius: 30px;
          padding: 5px 20px 5px 20px;
          background-color: #f5f5f7;
          font-weight: 600;
          .anticon {
            position: relative;
            top: -10px;
            left: 5px;
            background-color: #ea3943;
            border-radius: 50%;
            padding: 4px;
            svg {
              fill: #fff;
            }
          }
        }
      }
      .quill {
        height: 100%;
        .ql-container {
          border: none;
          .ql-editor {
            max-height: 200px;
            padding: 0 20px 0 0;
          }
        }
      }
    }
  }
  .avantazhetDisavantazhet-footer {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.dokumente-container {
  width: calc(100% - 5px);
  padding: 5px;
  .content-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .upload-container {
      height: 130px;
      // max-height: 500px;
      .upload-kandidati-button {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px dashed #1d3445;
        border-radius: 5px;
        padding: 5px;
        .upload-kandidati-button-text-container {
          display: flex;
          flex-direction: column;
          padding: 10px;
          gap: 10px;

          .upload-kandidati-button-text {
            width: 192px;
            font-family: Open Sans;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
        .ant-btn-confirm {
          border-radius: 5px;
          border-color: #20ac9d;
          background-color: #20ac9d;
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          .upload-kandidati-button-text {
            font-family: Open Sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
      }
    }
    .uploadedFilesSection {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      max-height: 100% !important;
      overflow: scroll;
      .fileCard {
        cursor: pointer;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 275px;
        height: 47px;
        border-radius: 5px;
        background-color: #f1f1f5;
        .uploadedFile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 10px;
        }
        .uploadedFileIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 15px;
        }
        .uploadFileName {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 10px 0;
          cursor: pointer;
        }
        .fileName {
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          margin-left: 5px;
          max-width: 210px;
          overflow: hidden;
          text-wrap: nowrap;
        }
        .closeIconContainer {
          background-color: transparent;
          border: none;
          height: fit-content;
          width: fit-content;
        }
        .closeIcon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.info-shtese-container {
  width: calc(100% - 5px);
  .first-content-container {
    .content-header {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      align-items: center;
      p {
        font-size: 1.563rem;
        font-weight: 600;
        margin: 0;
      }
      .ndryshoniBtn {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #2e5d9e;
        border: none;
        color: #ffffff;
      }
      .ndryshoniBtn:hover {
        background-color: #2e5d9ee3;
      }
    }
    .content-container {
      height: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      gap: 10px;
      .first-container {
        overflow-y: auto;
        .quill {
          height: 250px;
          border-radius: 5px;
          background-color: #f1f1f1;
          padding: 10px 0 10px 10px;
          .ql-container {
            border: none;
            .ql-editor {
              max-height: 250px;
              padding: 10px;
            }
          }
        }
      }
      .second-container {
        max-height: 300px;
        overflow-y: auto;

        .second-container-header {
          display: flex;
          gap: 10px;
          padding: 5px;
          align-items: center;
          p {
            margin: 0;
          }
          span {
            font-weight: 600;
          }
        }
        .second-content-container {
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-between;
          overflow-y: auto;
          padding: 5px;
          gap: 10px;
          max-height: 200px;
          .upload-container {
            width: calc(25% - 5px);
            min-width: 250px;
            height: 47px;
            background-color: rgb(241, 241, 241);
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
            .text-image {
              display: flex;
              align-items: center;
              text-align: center;
              gap: 5px;
              // padding-left: 10px;
              overflow: hidden;
              .uploadedFileIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 12px !important;
                height: 15px !important;
              }
            }
            p {
              margin: 0;
            }
            .delete-button {
              border: none;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.shenime-modal {
  width: calc(100vw - 20px) !important;
  max-width: 1200px !important;
  .ant-modal-content {
    .ant-modal-body {
      height: calc(100% - 115px);
      .shenime-modal-container {
        display: flex;
        justify-content: space-between;
        max-height: 677px;
        .first-modal-content-container {
          width: 100%;
          .quill-box {
            height: 650px;
            border-radius: 5px;
            background-color: #f1f1f1;
            padding: 10px 0 10px 10px;
            .ql-container {
              border: none;
              .ql-editor {
                max-height: 650px;
                padding: 10px;
              }
            }
          }
        }
        .second-modal-content-container {
          width: 43%;
          height: calc(100% - 5px);
          .content-column-container {
            height: 40%;
            max-height: 650px;
            .ant-upload {
              display: flex;
              justify-content: center;
              .ant-upload-drag-container {
                p {
                  width: 200px;
                  margin: 10px 0;
                }
                .ant-btn-confirm {
                  border-radius: 5px;
                  border-color: #20ac9d;
                  background-color: #20ac9d;
                  color: white;
                }
                .ant-btn-confirm:hover {
                  background-color: #20ac9ecc;
                  border-color: #20ac9ecc;
                }
              }
            }
            .upload-items-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              max-height: 350px;
              overflow-y: auto;
              padding: 5px;
              gap: 10px;
              .upload-container {
                width: calc(50% - 5px);
                min-width: 200px;
                background-color: rgb(241, 241, 241);
                display: flex;
                justify-content: space-between;
                padding: 5px;
                border-radius: 5px;
                .text-image {
                  display: flex;
                  align-items: center;
                  text-align: center;
                  gap: 5px;
                  padding-left: 10px;
                }
                p {
                  margin: 0;
                }
                .delete-button {
                  border: none;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      border: none;
      padding: 20px 29px;
      .flex-between {
        display: flex;
        justify-content: space-between;
      }
      .ant-btn-dangerous {
        border-radius: 5px;
      }
      .ant-btn-confirm {
        border-radius: 5px;
        border-color: #20ac9d;
        background-color: #20ac9d;
      }
      .ant-btn-confirm:hover {
        background-color: #20ac9ecc;
        border-color: #20ac9ecc;
      }
    }
  }
}

.confirmModal {
  width: calc(100vw - 20px) !important;
  max-width: 660px !important;
  .ant-modal-content {
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    max-width: 660px;
    max-height: 405px;
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 5px;
          .ant-modal-confirm-content {
            width: calc(65% - 5px);
            text-align: center;
            span {
              font-weight: 600;
            }
          }
        }
        .ant-modal-confirm-btns {
          display: flex;
          justify-content: center;
          .ant-btn-confirm {
            width: 115px;
            border-radius: 5px;
            border-color: #20ac9d;
            background-color: #20ac9d;
          }
          .ant-btn-confirm:hover {
            background-color: #20ac9ecc;
            border-color: #20ac9ecc;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .shenime-modal {
    .ant-modal-content {
      .ant-modal-body {
        .shenime-modal-container {
          .second-modal-content-container {
            .content-column-container {
              .upload-items-container {
                .upload-container {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .tag-modal {
    .ant-modal-body {
      padding: 10px;
      .tag-modal-container {
        .modal-content {
          .tags-container {
            max-height: 220px;
          }
        }
      }
    }

    .ant-modal-footer {
      border: none;
      padding: 20px 10px;
    }
  }
  .pergjithshme-container {
    .content-container {
      .personal-information,
      .applicant-work-information,
      .show-applicant-info {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 450px) {
  .show-applicant-info {
    .first-container {
      .university-container {
        display: flex;
        flex-direction: column !important;
      }
    }
  }
}
