.merge-user-empl {
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;
    .ant-modal-close {
      color: white;
      // background-color: #e7eef2;
      display: flex;
      justify-content: flex-end;
      border-radius: 10px 10px 10px 10px;
    }
  }

  .ant-modal-header {
    height: 45px;
    background: #e7eef2 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    color: white;
    .ant-modal-title {
      color: #1d3445;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 21.79px;
      text-align: left;
    }
  }

  .ant-modal-body {
    background-color: white;
    height: 150px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;

    .merge-user-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .title-merge {
        font: normal normal 600 16px/22px Open Sans;
      }
    }
  }

  .ant-modal-footer {
    height: 52px;
    background: #fff 0% 0% no-repeat padding-box;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: space-between;
    border: none;
    .close-btn {
      width: 150px;
      height: 32px;
      background: #ea3943 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      text-decoration: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
    .start-btn {
      width: 150px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      text-decoration: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
