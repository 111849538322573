$card_blue: #e7eef2;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
.activity-modal .ant-modal-content {
  border-radius: 20px 20px 10px 10px;
  width: 1095px;
  height: 454px;
  // margin-top: 100px;
  margin-right: 300px !important;
}
.ant-modal.activity-modal {
  width: 1095px !important;
}
.activity-modal .ant-modal-body {
  padding: 20px;
}
.table-header {
  .ant-col {
    font: normal normal 600 18px/39px "Open Sans";
    color: #323338;
  }
}
.data-rows {
  .ant-col {
    color: #323338;
    font: normal normal normal 16px / 25px Open Sans;
  }
}
.changes-container {
  display: flex;
  flex-direction: column;
  height: 370px;
  overflow-x: hidden;
  .table-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .exportButtons {
    display: flex;
    gap: 10px;
    .exportKandidateBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      border: none;
      padding: 0;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

@media (max-width: 1200px) {
  .activity-modal .ant-modal-content {
    width: 100%;
    overflow: hidden;
    .changes-container {
      width: 100%;
      overflow-x: scroll;
      .table-header,
      .table-body {
        width: 1095px;
      }
    }
  }
}
