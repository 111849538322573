.req-drawer .ant-drawer-content-wrapper {
  width: 800px !important;

  .req-drawer .ant-drawer-content {
    height: 100% !important;
    overflow-y: scroll;
    border-radius: 3px;
  }
  .req-drawer .ant-drawer-content-wrapper {
    height: 100% !important;
  }
  //Stilizimi i filtrave
  .requests-drawer {
    margin-top: 5px;
  }
  .filters-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;
    .name-filter-input {
      background-color: white !important;
      border: 1px solid #d9d9d9 !important;
      width: 180px;
      border-radius: 6px;
    }
    .status-filter-select.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      width: 180px;
      border-radius: 6px;
    }
    .status-filter-select {
      .ant-select-arrow {
        right: -53px !important;
      }
    }
    .date-filter {
      border-radius: 6px;
      width: 180px;
    }
    .filter-button.ant-btn {
      border: 1px solid #1da193 !important;
      color: #1da193;
      border-radius: 5px;
      &:hover {
        background-color: #1da19310;
      }
    }
    .clear-filter.ant-btn {
      border: 1px solid #ea3943 !important;
      color: #ea3943;
      border-radius: 5px;
      &:hover {
        background-color: #ea394310;
      }
    }
  }
  .requests-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 14px;
  }
}
.kerkesa-leje-container {
  height: 100%;
  overflow: scroll;
  width: -webkit-fill-available;
}
.ant-row.leave-request-row {
  font-size: medium;
  button.ant-btn.leave-request-btn {
    border-radius: 5px;
    color: #fff;
    border: none;
    height: 26px;
    width: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
.wrapDrawer {
  .add-request {
    width: 755px;
    height: 32px;
    background: #1da193 0% 0% no-repeat padding-box;
    color: #fff;
    font: normal normal normal 14px/6px "Open Sans";
    border-radius: 5px;
    margin-left: 20px;
    margin-top: -30px;
  }
}
