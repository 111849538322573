.festatZyrtareLogs {
  //   width: calc(100vw - 20px) !important;
  //   width: calc(100dvw - 20px) !important;
  //   height: calc(100vh - 20px) !important;
  //   height: calc(100dvh - 20px) !important;
  //   width: fit-content;
  //   max-width: 610px !important;
  //   max-height: 700px !important;
  .ant-modal-content {
    // height: calc(100vh - 20px) !important;
    // height: calc(100dvh - 20px) !important;
    max-height: 700px;
    border-radius: 10px;
    .ant-modal-close {
      height: 35px;
      width: 35px;
      margin-right: 12px;

      .ant-modal-close-x {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #e7eef2;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      height: calc(100vh - 97px);
      max-height: 600px;
      overflow-x: hidden;
      overflow-y: auto;
      .ag-root-wrapper {
        box-shadow: none !important;
      }
    }
    .ant-modal-footer {
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;

      .decline {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #ea3943;
        border: none;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        color: #fbfbfb;
      }
    }
  }
}
