.payStub-table2 {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    border: none;
    width: 100%;
    border: 1px solid #e3eaf3;
  }

  td,
  th {
    // border: 1px solid #dddddd;
    // text-align: left;
    padding: 5px;
    border: 1px solid #e3eaf3;
  }
  th {
    background-color: white;
    .sort-icon-wrapper {
      display: none;
    }
    &:hover {
      color: #fcc94a;
      cursor: pointer;
      .sort-icon-wrapper {
        display: contents;
      }
    }
  }
  tr {
    background-color: #70707017;
    // text-align: right;
    &:hover {
      background-color: #e3eaf3;
    }
  }

  tr:nth-child(even) {
    background-color: #ffffff;
    &:hover {
      background-color: #e3eaf3;
    }
  }
}
