@import "../../../../index.scss";

//* styles docConfigHeader justify-content:flex-end, and align-items:center
.docConfigLogoHeader {
  @include flex(flex-end, center);
//* styles labelContainer justify-content:flex-start, align-items:flex-end, and flex-direction:column
  .labelContainer {
    @include flex(flex-start, flex-end, column);
    margin-left: 10px;
  }
//* styles title text font-size:24px, font-weight:bold, letter-spacing:0, and text-color
  .title {
    @include text(24px, bold, 0, #323338);
  }

//* styles subtitle text font-size:18px, font-weight:400, letter-spacing:0, and text color
  .subtitle {
    @include text(18px, 400, 0, #323338);
    font-style: italic;
    max-width: 401px;
  }

  .logo {
    height: 100px;
  }
}

//* responsive ViewLogoHeader for iPad Air
@media (max-width: 821px) {
  .docConfigLogoHeader {
    margin-left: 70px;
  }
}

//* responsive ViewLogoHeader for Pc monitor
@media (max-width: 1025px) {
  .docConfigLogoHeader .subtitle {
    font-size: 25px;
  }
}

//* responsive ViewLogoHeader for iPhone 12 Pro
@media (max-width: 391px) {
  .docConfigLogoHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-left: 40px;
  }
  .docConfigLogoHeader .title {
    width: 200px;
    font-size: 16px !important;
  }
  .docConfigLogoHeader .subtitle {
    font-size: 14px;
    width: 165px;
  }
  .docConfigLogoHeader .logo {
    height: 50px !important;
  }
}