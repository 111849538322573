.problematikaModal {
  width: calc(100vw - 20px) !important;
  width: calc(100dvw - 20px) !important;
  height: calc(100vh - 20px) !important;
  height: calc(100dvh - 20px) !important;
  max-width: 810px !important;
  max-height: 700px !important;
  .ant-modal-content {
    // height: calc(100vh - 20px) !important;
    // height: calc(100dvh - 20px) !important;
    display: flex;
    flex-direction: column;
    height: 700px;
    .ant-modal-close {
      height: 35px;
      width: 35px;
      margin-right: 12px;
      .ant-modal-close-x {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #e7eef2;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      // height: calc(100vh - 97px);
      // max-height: 600px;
      height: 100%;
      overflow-y: hidden;
      overflow-x: auto;
      .ant-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // flex-wrap: wrap;
        height: 100%;
        gap: 10px !important;
        .selectUserFormItem {
          width: 100%;
        }
        .ant-form-item {
          margin-bottom: 0px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: none;
          background-color: #f1f1f5 !important;
          border-radius: 5px;
          margin: 0 !important;
          height: 32px !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: #f1f1f5;
        }
        .statusSelect {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 155px;
          height: 25px;
          border-radius: 5px;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: center;
          .ant-select-selector {
            height: 30px;
            border: none;
            background-color: transparent !important;
            align-items: center;
            font-size: 14px;
            border-radius: 5px;
          }

          .ant-select-arrow {
            display: none;
          }
        }
        .textAreaFormItem {
          width: 100% !important;
          // height: calc(100% - 164px);
          // min-height: 200px;
          height: 100%;
          .ant-form-item-row {
            height: 100%;
            .ant-col {
              height: 100%;
              .ant-form-item-control-input {
                height: 100%;
                .ant-form-item-control-input-content {
                  height: 100%;
                }
              }
            }
            .lajmerime-quill {
              height: 100%;
              border-radius: 5px !important;
              background-color: #f1f1f5;
              .ql-container.ql-snow {
                border: none !important;
              }
              .ql-disabled {
                .ql-editor {
                  > * {
                    cursor: not-allowed !important;
                  }
                }
              }
              .ql-toolbar.ql-snow {
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
                border-bottom: 1px solid #fff;
                width: 100%;
                .ql-image {
                  display: inline-block !important;
                }
                input.ql-image {
                  display: none !important;
                }
              }
              .ql-editor {
                height: calc(100% - 64px);
              }
              .ql-editor > p > img {
                width: fit-content !important;
                height: 100% !important;
              }
            }
          }
        }

        .ngarkoniDokumenteButtonContainer {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 187px;
          height: 32px;
          border-radius: 2px;
          border: 1px dashed #1d3445;
        }

        .confirmButtonModal {
          display: flex;
          align-items: center;
          gap: 10;
        }
        .formFirstRowContainer {
          height: fit-content;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
        }
        .formSecondRowContainer {
          height: fit-content;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
          gap: 10px;
          .ant-select {
            width: 30%;
            width: 345px !important;
          }
        }

        .formItemButtonContainer {
          display: flex;
          justify-content: space-between;
          align-content: flex-start;
          width: 70%;
          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: transparent !important;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftSideButtonFooter {
        display: flex;
        align-items: center;
        gap: 10px;
        .ant-btn {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .confirmClosedButton {
        width: 184px;
        height: 32px;
        border-radius: 5px;
        background-color: #f1f1f5;
        border: none;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        color: #1d3445;
      }
      .showLogsButton {
        height: 32px;
        width: 35px;
        border-radius: 5px;
        background-color: #0f5c97;
        border: none;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        color: #fbfbfb;
      }
      .showButtonDisabled {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
        border-color: none;
      }
      .send-request {
        width: 150px;
        height: 32px;
        background-color: #1da193;
        color: #fbfbfb;
        border-radius: 5px;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        border: none;
      }
      .decline {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #ea3943;
        border: none;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        color: #fbfbfb;
      }
      .decline:disabled {
        opacity: 0.3;
      }
    }
  }
}

.ant-form-item-label {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.dropdownStatus {
  background-color: transparent !important;
  box-shadow: none !important;
  .ant-select-item
    .ant-select-item-option
    .ant-select-item-option-active
    .ant-select-item-option-selected {
    min-height: 25px !important;
  }
  .ant-select-item {
    text-align: center;
    min-height: 25px !important;
    padding: 0px !important;
    .ant-select-item-option-content {
      max-height: 25px !important;
    }
  }
}
@media (max-width: 820px) {
  .formSecondRowContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
  }
}
@media (max-width: 820px) {
  .formSecondRowContainer {
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    .ant-form-item {
      .ant-select {
        width: 100% !important;
        // width: 345px !important;
      }
    }
    .formItemButtonContainer {
      justify-content: center !important;
      gap: 15px;
    }
  }
}
@media (max-width: 550px) {
  .formItemButtonContainer {
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    gap: 5px !important;
    .ngarkoniDokumenteButtonContainer {
      margin-bottom: 0px !important;
    }
  }
  .formFirstRowContainer {
    flex-direction: column;
  }
}
@media (max-width: 1560px) {
  .logsModalSideContainer {
    right: auto !important;
  }
}
