.menaxhimi-mungeses {
  padding-top: 10px;
  height: 100%;
  width: 100%;
  path#Path_7433 {
    fill: #323338;
  }
}

button.ant-btn.krijoMungese {
  background: #1da193;
  color: #fff;
  border-radius: 5px;
}

.column-paisjet-orarit {
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-ndrysho {
    margin-top: 4px;
    width: 32px;
    height: 32px;
    background: #1da193;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }
}
.ag-grid-punojsit .ag-theme-alpine .ag-header-container {
  .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
    background: #fff !important;
    border: none;
    opacity: 1;
    z-index: 4;
  }
  .ag-checkbox-input-wrapper::after {
    // color: #fff !important;
    border: none !important;
  }
}

// modal scss
.ant-modal.paisjet-orarit-modal {
  .ant-modal-content {
    border-radius: 5px;
  }
  .ant-modal-header {
    height: 45px;
    background: #1d3445;
    align-items: center;
    display: flex;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .ant-modal-title {
    color: #fff;
  }
  span.ant-modal-close-x {
    height: 45px;
    color: #fff;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .ant-modal-footer {
    justify-content: space-between;
    display: flex;
    background: #f5f5f7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .ant-row.ant-form-item {
      width: 100%;
    }
    button.ant-btn.ant-btn-primary.ruaj {
      background-color: #1da193;
      border-radius: 5px;
      border: none;
      color: #fff;
      width: 150px;
      height: 32px;
      text-align: center;
    }
    button.ant-btn.mbyll {
      background: #ea3943;
      color: #fff;
      border-radius: 5px;
      border: none;
      width: 150px;
      height: 32px;
      text-align: center;
    }
  }
  .paisje-orarit-content {
    display: flex;
  }
  .logo-container {
    width: 180px;
    height: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .grid-container {
    margin-left: 40px;
    display: block;
    width: 100%;
  }
  textarea {
    resize: none;
  }
  .ant-row.ant-form-item {
    margin: 0;
  }
}

.quill.note_input.quillTextEditor.paisjet-orarit-quill {
  height: 170px;
  border-radius: 5px !important;
  background-color: #f1f7fb;
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-toolbar.ql-snow {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #fff;
  }
}

.ant-picker.konfigurimet-shift-picker {
  .ant-picker-content {
    width: 200px !important;
  }
}

.sistemi-paisjet-orarit {
  width: 100%;
  height: 100%;
}

//modal
.ant-modal.politikat-per-orarin-modal {
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    background: #1d3445;
    height: 45px;
    align-items: center;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .ant-modal-title {
    color: #fff;
  }
  .ant-modal-close-x {
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .ant-modal-footer {
    background: #f5f5f7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: space-between;
    display: flex;
    button.ant-btn.ant-btn-default.Mbyll-btn {
      background: #ea3943;
      color: #fff;
      border-radius: 5px;
      border: none;
    }
    button.ant-btn.ant-btn-default.ruaj-btn {
      background: #1da193;
      color: #fff;
      border-radius: 5px;
      border: none;
    }
  }
}

.mungesatIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4px;
  .deleteIcon {
    background: #ea3943;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
    align-self: center;
    cursor: pointer;
    margin-left: 15px;
    // margin-top: 4px;
    path#Path_7433 {
      fill: #fff;
    }
  }
  .icon-edit {
    background: #1da193;
    cursor: pointer;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
    align-self: center;
    margin-left: 15px;
    // margin-top: 4px;
    path#Path_7431_00000060022719262200020110000009623688766172120195_ {
      fill: #fff;
    }
  }
}
.quill.note_input.quillTextEditor.modal-quill-edit {
  .ql-toolbar.ql-snow {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #f5f5f7;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #fff;
    width: 100%;
  }
  .ql-editor.ql-blank {
    background: #f5f5f7;
    border: 0px;
    height: 70px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .ql-container.ql-snow {
    height: 70px;
    background: #f5f5f7;
    border: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  button.ql-clean {
    display: none;
  }
}
