.headerDataWorkShift {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.4rem;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px;
  .header-shift-item {
    font-weight: 600;
    font-size: 15px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.overtimeWorkshift {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  // height: 235px;
  height: 100%;
}

.shift-overtime {
  display: flex;
  width: 100%;
  padding: 8px 16px;
}

.admin-noOvertime {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 45px;
  .admin-noOvertimeText {
    display: flex;
    justify-content: center;
    width: 284px;
    height: 22px;
    font: normal normal 600 16px/19px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    margin-top: 10px;
  }
  .admin-noOvertimeText-text {
    font-size: 15px;
    width: 307px;
    height: 57px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 5px;
  }
}
