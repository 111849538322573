.userPersonalInfo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  .ant-card-head {
    background-color: #1d3445;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .userLettersName {
    display: flex;
    justify-content: center;
    .sb-avatar {
      opacity: 0.3;
    }
  }

  .userLettersName h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 102px;
    height: 102px;
    border-radius: 50%;
    background: #4895ef 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #ffffff;
    font-size: 30px;
  }
  .userActions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .uploadBtn {
      width: 162px;
      height: 32px;
      color: #ffffff;
      background: #1da193 0% 0% no-repeat padding-box;
      opacity: 1;
      border-radius: 6px;
    }
    .SignInBtn {
      width: 162px;
      height: 32px;
      color: #ffffff;
      background: #1da193 0% 0% no-repeat padding-box;
      opacity: 1;
      border-radius: 6px;
      display: flex;
      justify-content: center;
    }
  }
  .userToolTip {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 40%;
    left: 20%;
  }
  .userToolTip h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #fe4c4a;
    background-color: #f1f7fb;
    color: #fe4c4a;
    path#Path_6864 {
      fill: #fe4c4a;
    }
    &:hover {
      background-color: #fe4c4a;
      color: #f1f7fb;
      cursor: pointer;
      path#Path_6864 {
        fill: #fff;
      }
    }
  }
  .idle {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // position: absolute;
    // bottom: 10%;
    span {
      display: flex;
      justify-content: flex-start;
      font-family: Open Sans, sans-serif;
      font-size: 15px;
    }
  }
  .saveBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    background-color: #68c142;
    border-color: #68c142;
    color: #fff;
    border-radius: 7px;
  }
}

.footer-profile {
  bottom: 0 !important;
  align-self: flex-end;
  width: 100%;
  height: 70px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .shiko-btn {
    width: 150px;
    height: 32px;
    background: #1da193 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #f1f7fb;
    border-radius: 5px;
    margin-right: 20px;
  }
  .ruaj-btn {
    width: 150px;
    height: 32px;
    background: #1da193 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #f1f7fb;
    border-radius: 5px;
    margin-right: 20px;
  }
}

.user-profile {
  .ant-card.ant-card-bordered.employeePersonalInfoCard {
    .ant-card-body {
      padding-top: 80px;
    }
  }
}

.profile-img-div {
  position: relative;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  img.profile-img {
    box-shadow: 0px 1px 4px rgb(0 0 0 / 16%);
    width: 130px;
    height: 130px;
    padding: 2px;
    border-radius: 50%;
  }
  .remove-profile-photo {
    position: absolute;
    right: -8.5px;
    background-color: #fe4c4a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    width: 17px;
    height: 17px;
    cursor: pointer;
  }
}

@media (max-width: 821px) {
  .user-profile {
    .profile-user-row {
      display: grid !important;
      .ant-col {
        max-width: 100%;

        padding-left: 10px;
        padding-bottom: 20px;
      }
    }
    .userPersonalInfo .userToolTip {
      left: 0px !important;
      display: flex;
      justify-content: center;
      margin-left: 75px;
    }
  }
}
@media (max-width: 391px) {
  .user-profile {
    .profile-user-row {
      .ant-col.ant-col-5 {
        max-width: 310px !important;
      }
      .ant-col.ant-col-9 {
        max-width: 310px !important;
      }
      .ant-col.ant-col-10 {
        max-width: 310px !important;
      }
    }
    .footer-profile {
      button.ant-btn.ant-btn-default.ruaj-btn {
        width: 120px !important;
        padding: 0px;
      }
      .shiko-btn {
        width: 120px !important;
        padding: 0px;
      }
    }
    .ant-card.ant-card-bordered.employeePersonalInfoCard {
      .ant-form .ant-form-item {
        display: grid !important;
      }
    }
    .employeePersonalInfoCard .employeeInfo Input {
      width: 220px !important;
    }
    .icon-rest {
      display: none;
    }
    .ant-card.ant-card-bordered.contactInfoCard {
      .ant-form .ant-form-item {
        display: grid !important;
      }
      .icon-rest {
        display: none;
      }
    }
    .contactInfoCard .contactInfo Input {
      width: 220px;
    }
    .userPersonalInfo .userToolTip {
      display: none;
    }
    .userPersonalInfo .idle {
      margin-top: 30px;
    }
  }
}

span.ant-input-affix-wrapper.ant-input-password.pasw-prof-input {
  input.ant-input {
    background: transparent;
  }
}

.user-profile {
  .ant-card.ant-card-bordered.employeePersonalInfoCard {
    width: 700px !important;
  }

  .ant-card.ant-card-bordered.contactInfoCard {
    width: 600px !important;
  }

  .ant-card.ant-card-bordered.userPersonalInfo {
    width: 260px !important;
  }
}

.user-profile-btn {
  // width: 140px;
  cursor: pointer;
  height: 32px;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 12px;
  /* UI Properties */
  background: #1da193 0% 0% no-repeat padding-box;
  &:hover {
    opacity: 0.7;
  }
}

@media (max-width: 821px) {
  .user-profile {
    .sortable-list-container {
      margin-right: 0px !important;
    }
    .drag-container {
      overflow: auto;
    }
    .contactInfoCard .contactInfo Input {
      width: 320px;
    }
  }
  .user-profile .ant-card.ant-card-bordered.contactInfoCard {
    width: 415px !important;
  }
}

@media only screen and (min-width: 745px) and (max-width: 851px) {
  .user-profile .ant-card.ant-card-bordered.userPersonalInfo {
    width: 100% !important;
    height: 380px !important;
    .idle {
      left: 32% !important;
      bottom: 3% !important;
    }
  }
  .user-profile .ant-card.ant-card-bordered.contactInfoCard {
    width: 100% !important;
    height: 450px !important;
  }
  .user-profile .ant-card.ant-card-bordered.employeePersonalInfoCard {
    width: 100% !important;
    height: 350px !important;
    .ant-card-body {
      padding-top: 23px !important ;
    }
  }
  .preferencat .KartatEShfaqura {
    margin-left: 75px !important;
  }
}
@media (max-width: 769px) {
  .user-profile {
    .sortable-list-container {
      margin-right: 0px !important;
    }
    .drag-container {
      overflow: auto;
    }
    .contactInfoCard .contactInfo Input {
      width: 230px;
    }
    .ant-card.ant-card-bordered.employeePersonalInfoCard {
      width: 100% !important;
    }
  }
  // .user-profile .ant-card.ant-card-bordered.contactInfoCard {
  //     width: 365px !important;
  // }
}

@media (max-width: 450px) {
  .user-profile .ant-card.ant-card-bordered.userPersonalInfo {
    width: 100% !important;
    display: flex;
    justify-content: center;
    height: 500px;
    .ant-card-body {
      width: 100%;
    }
  }

  .userPersonalInfo .idle {
    // margin-left: 15px;
    left: 22.3%;
  }
  .user-profile .ant-card.ant-card-bordered.contactInfoCard {
    width: 100% !important;
    height: 440px !important;
    input.ant-input.ant-input-disabled {
      width: 220px !important;
    }
  }
  .user-profile .ant-card.ant-card-bordered.employeePersonalInfoCard {
    width: 100% !important;
    height: 350px !important;
    .ant-card-body {
      padding-top: 20px;
    }
  }
  .ant-row.ant-form-item.custom\:department {
    display: grid !important;
    input.ant-input.ant-input-disabled {
      width: 220px !important;
    }
  }
  .ant-row.ant-form-item.roliIpunonjesit {
    display: grid !important;
    input.ant-input.ant-input-disabled {
      width: 220px !important;
    }
  }
  .ant-row.ant-form-item.roliIperdoruesit {
    display: grid !important;
    input.ant-input.ant-input-disabled {
      width: 220px !important;
    }
  }
}

@media (max-width: 390px) {
  .user-profile
    .ant-card.ant-card-bordered.contactInfoCard
    input.ant-input.ant-input-disabled {
    width: 200px !important;
  }
  .ant-row.ant-form-item.custom\:department {
    display: grid !important;
    input.ant-input.ant-input-disabled {
      width: 200px !important;
    }
  }
  .ant-row.ant-form-item.roliIpunonjesit {
    display: grid !important;
    input.ant-input.ant-input-disabled {
      width: 200px !important;
    }
  }
  .ant-row.ant-form-item.roliIperdoruesit {
    display: grid !important;
    input.ant-input.ant-input-disabled {
      width: 200px !important;
    }
  }
}
