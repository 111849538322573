.kompania-pozicionet {
  width: 100%;
  height: 100%;
  // padding-top: 20px;
  .ant-card.card-pozicionet-settings {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
}

svg.White-it {
  path#Path_8175 {
    fill: #1d3445 !important;
  }
  path {
    fill: #1d3445 !important;
  }
}

.nuk-ka-departament {
  margin: 50px;
  height: 60px;
  width: 100%;
  border-radius: 10px;
  background: #1d3445;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.card-pozicionet-settings {
  .body-card {
    width: 100%;
    height: 100%;
    // height: 210px;
    justify-content: center;
    display: flex;
    .logo-with-content {
      width: 40%;
      display: grid;
      text-align: center;
      justify-items: center;
      border-radius: 5px;
      align-content: center;
      height: 100%;
      margin-right: 10px;
      background: #f1f7fb;
      .DESC {
        font-size: larger;
        font-weight: 500;
      }
    }
  }
}
.card-pozicionet-settings {
  .ant-card-body {
    padding: 15px !important;
  }
}

.ant-modal.dpartament-rolet-modal {
  .ant-modal-header {
    background: #1d3445;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 45px;
    display: flex;
    align-items: center;
  }
  .ant-modal-title {
    color: #fff;
  }
  .ant-modal-close-x {
    color: #fff;
    display: flex;
    align-items: center;
    width: 35px;
    height: 45px;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-footer {
    text-align: center !important;
  }
  button.ant-btn.Mbyll-btn {
    width: 150px;
    height: 32px;
    border-radius: 5px;
    border: none;
    background: #ea3943;
    color: #fff;
  }
  .ant-modal-body {
    padding: 20px;
  }
  .ant-modal-footer {
    background: #f5f5f7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .body-content {
    width: 100%;
    height: 350px;
    display: flex;
    .logo-div-container {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 100%;
      background: #f1f7fb;
      border-radius: 5px;
      margin-right: 20px;
      .LOOGO {
        display: grid;
        justify-items: center;
        // padding-bottom: 2px;
      }
      g#Group_5594 {
        display: contents;
      }
    }
    .punojsit-name {
      width: 100%;
      overflow: auto;
      height: 100%;
      // background-color: #f1f7fb;
      border-radius: 5px;
      .header {
        justify-content: space-between;
        display: flex;
        margin-bottom: 10px;
        .punsuar {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          height: 27px;
          width: 175px;
          background: #1d3445;
          margin-right: 30px;
          color: #fff;
          border-radius: 2px;
        }
        .Punojsi {
          // text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 30px;
          background: #1d3445;
          color: #fff;
          height: 27px;
          width: 175px;
          border-radius: 2px;
          font-size: 16px;
        }
      }
      .name-div {
        width: 100%;
        display: flex;
        font-size: medium;
        font-weight: 500;
        margin-bottom: 2px;
        .name-punojsit {
          width: 50%;
          align-items: center;
          font-weight: 400;
          display: flex;
          margin-left: 30px;
        }
        .data-div {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
        }
      }
    }
  }
  .ska-punonjes {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f1f7fb;
    font-size: medium;
    font-weight: 500;
    border-radius: 5px;
  }
}

@media (max-width: 821px) {
  .kompania-pozicionet .ant-row.kompania-pozicionet-row {
    display: grid !important;
    // grid-template-columns: auto auto !important;
    .body-card {
      width: 310px !important;
    }
  }
  .ant-col.kompania-pozicionet-col {
    width: 100% !important;
    max-width: 100% !important;
  }
  .card-pozicionet-settings {
    width: 345px !important;
  }
}

@media (max-width: 769px) {
  .kompania-pozicionet .ant-row.kompania-pozicionet-row {
    display: grid !important;
    // grid-template-columns: auto auto !important;
    .body-card {
      width: 350px !important;
    }
  }
  .card-pozicionet-settings {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .kompania-pozicionet .ant-row.kompania-pozicionet-row {
    display: grid !important;
  }
}

@media (max-width: 391px) {
  .kompania-pozicionet .ant-row.kompania-pozicionet-row {
    grid-template-columns: auto !important;
  }
  .kompania-pozicionet .ant-row.kompania-pozicionet-row .body-card {
    // display: grid;
    width: 100% !important;
    height: 280px !important;
    gap: 10px;
    .logo-with-content {
      width: 230px;
      margin: 0px;
    }
  }
}
