.ant-card.card-report-header {
  .ant-card-body {
    padding: 15px;
    height: auto;
  }
  .ant-card-head-title {
    font-weight: 400 !important;
  }
  .ant-card-head {
    // background-color: #1D3445;
    //Autumn
    background-color: #1d3445 !important;
    color: #ffffff;
    min-height: 45px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .content-header-card {
    display: flex;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
    .time-period {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      gap: 15px;
    }
  }
  .ant-select.ant-select-borderless.select-option.ant-select-single.ant-select-show-arrow {
    background: #f5f5f7;
    border-radius: 5px;
    height: 32px;
    svg {
      fill: #323338;
    }
  }
  .ant-select.select-option-multi.ant-select-multiple.ant-select-show-search {
    background: #f5f5f7 !important;
    border-radius: 5px;
    width: 500px !important;
    // height: 32px;
    height: auto !important;
  }

  .ant-picker.ant-picker-range.ant-picker-borderless.range-pick-report {
    background: #f5f5f7 !important;
    border-radius: 5px;
    height: 32px;
  }
  button.ant-btn.ant-btn-default.go-back {
    width: 135px;
    height: 32px;
    border-radius: 5px;
    border: none;
    background: #1da193;
    color: #ffffff;
  }
  button.ant-btn.ant-btn-default.generate-btn-filtrim {
    border-radius: 5px;
    border: none;
    width: 135px;
    height: 32px;
    background: #1da193;
    color: #ffffff;
  }
  button.ant-btn.ant-btn-default.filtro-btn {
    width: 135px;
    height: 32px;
    background: #1da193;
    border: none;
    border-radius: 5px;
    color: #ffffff;
  }
}

.ant-select-selector {
  height: 100% !important;
}
