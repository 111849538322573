@import "../../../../../../index.scss";

// lajmerimet card
.notification-container {
  width: 99%;
  height: 400px;
  // padding-left: 20px;
  overflow: auto;
  z-index: 10;
  svg.priority {
    path#Path_8272 {
      fill: #fcc94a;
    }
    path#Path_8273 {
      fill: #fcc94a;
    }
    path#Path_8274 {
      fill: #fcc94a;
    }
    path#Path_8275 {
      fill: #fcc94a;
    }
    path#Path_8276 {
      fill: #fcc94a;
    }
  }
  .notification {
    display: flex;
    align-items: center;
    font-size: 16px !important;
    font-weight: 500;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  .notification:hover {
    background: #f1f7fb;
    border-radius: 5px;
    // margin-left: 10px;
    // width: 97%;
  }
  .notification-open {
    background: #1d3445;
    color: #fff;
    // width: 96%;
    font-size: 16px;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    // margin-right: 5px;
    path#Path_8272 {
      fill: #fff;
    }
    path#Path_8273 {
      fill: #fff;
    }
    path#Path_8274 {
      fill: #fff;
    }
    path#Path_8275 {
      fill: #fff;
    }
  }
}

.notification-post-container {
  h1 {
    font-size: 17px;
  }
}

.line {
  border-right: 1px solid black;
  height: 400px;
  margin-left: -5px;
}

.photo-ditlindje {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}

.ant-row.lajmerime-post-row {
  .quill.note_input.quillTextEditor.lajmerime-quill {
    height: 165px;
    border-radius: 5px !important;
    background-color: #f1f7fb;
    .ql-container.ql-snow {
      border: none !important;
    }
    .ql-toolbar.ql-snow {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid #fff;
      width: 100%;
    }
  }
}

.star-color {
  color: #ea3943;
}

.notification-container::-webkit-scrollbar-thumb {
  background-color: #1d3445;
  max-height: 10%;
  min-height: 10%;
  height: 10%;
}

.notification-open-container {
  width: 100%;
  height: 360px;
  // overflow: auto;
  z-index: 1000;
  display: grid;
  text-align: center;
  .content {
    max-width: 390px;
    text-align: start;
    height: 260px;
    overflow: auto;
  }
  .content-container {
    height: 290px !important;
    text-align: start;
    overflow: auto;
    width: 99%;
    padding-left: 10px;
  }
}
button.ant-btn.fshij-lajmerim-btn {
  background: #ea3943;
  color: #fff;
  border-radius: 5px;
  width: 130px;
  margin-left: 120px;
}
.notification-header {
  background: #f1f7fb;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 0px 20px 20px 5px;
  border-radius: 5px;
  font-size: small;
  h3 {
    margin-bottom: 0px;
    font-size: 16px;
  }
}

.dep-specifik-slection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 93%;
}

.nuk-ka-lajmerim {
  // width: 100%;
  margin: 20px;
  border-radius: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  background: #f1f7fb;
}

.notification-post-container {
  padding-left: 20px;
  width: 100%;
  height: 400px;
  overflow: auto;
  z-index: 1000;
  display: flex;
  .permbajtja-lajmerimit {
    padding-top: 10px;
  }
  .ant-row.ant-form-item {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  .notification-post-header {
    display: flex;
    padding-top: 32px;
    justify-content: center;
    .switch-div {
      margin-left: 20px;
    }
  }
  .lajmerimet-conent {
    textarea.ant-input {
      background: #f1f7fb;
      border: none;
      border-radius: 5px;
    }
    textarea {
      resize: none;
    }
  }
  .lajmerimet-fotter {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    button.ant-btn.lajmerimet-anullo-btn {
      background: #ea3943;
      border-radius: 5px;
      color: #fff;
      margin-right: 10px;
      width: 115px;
    }
    button.ant-btn.lajmerimet-publiko-btn {
      border-radius: 5px;
      color: #fff;
      background: #1da193;
      width: 115px;
    }
  }
}
@media (max-width: 821px) {
  .ant-row.lajmerime {
    height: 100% !important;
    display: grid;
    .ant-col.ant-col-8 {
      max-width: 100%;
    }
  }
  .njoftime {
    height: 1300px;
    padding: 0px 10px 0px 10px;
    .ant-tabs {
      height: 100% !important;
    }
    .card-container {
      height: 100%;
    }
  }
  .ant-row.lajmerime {
    height: 100% !important;
    display: grid;
    .ant-col.ant-col-8 {
      max-width: 100%;
    }
    .notification-container .notification-open {
      margin-left: 0px;
      margin-right: 0px;
    }
    .ant-col.ant-col-6 {
      margin-top: 20px;
      max-width: 100%;
    }
    .ant-col.ant-col-10 {
      max-width: 100%;
    }
    .ant-col.line-col {
      display: none;
    }
  }
  .ant-row.ditlindje-row {
    width: 100%;
    display: grid;
    grid-template-columns: 650px;
    .ant-col.ant-col-6 {
      max-width: 100%;
    }
    .ant-col.ant-col-6.calendar-col {
      max-width: 100% !important;
      margin-top: 30px;
    }
  }
  .njoftime {
    width: 700px !important;
  }
  .ant-row.lajmerime-njoftime {
    display: grid !important;
    .ant-col {
      max-width: 100%;
    }
  }
}
@media (max-width: 450px) {
  .pagat-fitimet-setings .ant-col {
    width: 100% !important;
  }
  .ant-drawer-wrapper-body {
    .krijo-modal-content {
      width: fit-content !important;
    }
    .modal-footer {
      margin-top: 0px;
    }
    .ant-drawer-body {
      padding-left: 75px;
      padding-top: 30px;
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    overflow: auto;
  }
}

@media (max-width: 390px) {
  .njoftime {
    height: 1415px;
    padding: 0px 10px 0px 10px;
    .ant-tabs {
      height: 100% !important;
    }
    .card-container {
      height: 100%;
    }
  }
  .ant-row.lajmerime {
    height: 100% !important;
    display: grid;
    .ant-col.ant-col-8 {
      max-width: 100%;
    }
    .notification-container .notification-open {
      margin-left: 0px;
      margin-right: 0px;
    }
    .ant-col.ant-col-6 {
      margin-top: 20px;
      max-width: 100%;
    }
    .ant-col.ant-col-10 {
      max-width: 100%;
    }
    .ant-col.line-col {
      display: none;
    }
    .notification-post-container .lajmerimet-conent textarea.ant-input {
      height: 148px;
    }
  }
  .dashboard .dashboard-first-row {
    .small-card {
      width: 280px;
    }
  }
  .dashboard .requests {
    .person-info {
      display: grid;
      grid-template-columns: 130px 130px;
      button#details {
        justify-self: flex-end;
      }
    }
  }
  .small-card {
    .event-card-title .title-text {
      font-size: 13px;
      padding-top: 3px;
    }
    .event-card-title .month {
      padding: 3px 8px;
    }
    .events {
      overflow-x: HIDDEN;
      .anniversary {
        margin: 5px 0px;
      }
      img {
        margin-left: 5px;
        width: 45px !important;
        height: 45px !important;
      }
    }
    .employee-card {
      gap: 50px;
    }
    .employee-card-content {
      span.line-employe {
        width: 50px !important;
      }
    }
  }
  .ant-modal-wrap.request {
    right: 175px !important;
    left: 0px;
    .ant-modal-content {
      margin-left: 0px !important;
    }
    .request {
      margin: 0px;
    }
  }
  .ant-row.ditlindje-row {
    width: 100%;
    display: grid;
    grid-template-columns: 280px;
    .ant-col.ant-col-6 {
      max-width: 100%;
    }
    .ant-col.ant-col-6.calendar-col {
      max-width: 100% !important;
    }
  }

  .full-card {
    margin-left: 8px !important;
    height: 100% !important;
    width: 96% !important;
    .progress-shift {
      display: block;
    }

    .time-sheet-header-comp .month-filter-btns .inactive-filter {
      width: fit-content !important;
    }
    .time-sheet-header-comp .month-filter-btns .active-filter {
      width: fit-content !important;
    }
    .time-sheet-header-comp .month-filter-btns {
      margin-left: 0px;
    }
    .time-sheet-header-comp .filter-date-range {
      margin-left: 0px;
    }
  }
  .info-rreth-kompanis .ant-card {
    width: 300px !important;
  }
  .pagat-fitimet-setings .ant-col {
    width: 100% !important;
  }
  .pagat-fitimet-setings button.ant-btn.krijo-btn {
    margin-left: 10px;
  }
  .ant-drawer-wrapper-body {
    .krijo-modal-content {
      width: fit-content !important;
    }
    .modal-footer {
      margin-top: 0px;
    }
    .ant-drawer-body {
      padding-left: 55px;
      padding-top: 10px;
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    // overflow: auto;
    display: grid !important;
  }
  .ant-row.ditlindje-row {
    height: fit-content !important;
    .ant-col.ant-col-6 {
      max-height: 500px;
    }
    .calendar-container {
      margin: 0px !important;
    }
    .ant-col.ant-col-6.calendar-col {
      margin-left: 10px;
    }
  }
  div#tabBody {
    margin-left: 10px !important;
  }
  .dynamicViewPage .employe-view-row {
    display: grid;
    grid-template-columns: 360px !important;
    .ant-col.ant-col-6 {
      width: 310px !important;
      .responsive {
        height: 779px !important;
      }
    }
    .ant-card.ant-card-bordered.info-card {
      width: 290px !important;
      margin-bottom: 5px;
    }
    form#contact {
      width: 250px !important;
    }
    .ant-card.ant-card-bordered.personal-info-card {
      width: 290px !important;
      height: 500px !important;
    }
    .ant-card.ant-card-bordered.personal-info-card
      form.ant-form.ant-form-horizontal.personal-info {
      width: 260px !important;
    }
    .react-tel-input .form-control {
      width: 250px !important;
    }
  }
  .dynamicViewPage
    .employe-view-row
    .ant-card.ant-card-bordered.personal-info-card {
    height: 766px !important;
  }
  .dynamicViewPage .container {
    .ant-card.ant-card-bordered.info-card {
      width: 295px !important;
      height: 100%;
    }
    .details .wide-div {
      width: 250px;
    }
    div#supervisor {
      margin-top: 40px;
      margin-left: -70px;
    }
    .my-picker.ant-picker {
      width: 100% !important;
    }
    .status .wide-div {
      width: 240px;
    }
    input#status_employeeReasonforTermination {
      width: 240px !important;
    }
  }
  .pay {
    .ant-card.ant-card-bordered.pay-info-card {
      width: 290px !important;
    }
    .ant-card.ant-card-bordered.payment-card {
      width: 290px !important;
      height: 100% !important;
    }
  }
  .ant-drawer.ant-drawer-right.ant-drawer-open.notification-drawer {
    // margin-right: 166px;
    width: 390px !important;
    .ant-drawer-body {
      overflow: auto !important;
    }
    .ant-drawer-content-wrapper {
      width: 390px !important;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      width: 100% !important;
    }
  }
  .notifs-list-container .notifs-extra-filters .filter-btn {
    gap: 2px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-size: 9px;
  }
  .single-notif .notif-content .notif-body {
    min-width: 168px !important;
  }
  .dynamicViewPage {
    .tabContainer .ant-tabs-tab.ant-tabs-tab-active {
      background: #1d3445;
      color: #fff;
      border-radius: 5px;
      height: 35px;
      justify-content: center !important;
    }
    .ant-tabs-tab {
      height: 35px;
      justify-content: flex-start;
    }
    .tabContainer .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff;
    }
    .tabContainer .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .tabContainer .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      overflow: hidden;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      display: none;
    }
    .ant-tabs-tab {
      justify-content: flex-start !important;
    }
    div#rc-tabs-0-tab-0 {
      margin-left: 15px !important;
    }
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open.notification-drawer
    .ant-tabs
    > .ant-tabs-nav
    .ant-tabs-nav-list,
  .ant-drawer.ant-drawer-right.ant-drawer-open.notification-drawer
    .ant-tabs
    > div
    > .ant-tabs-nav
    .ant-tabs-nav-list {
    margin-bottom: 3px;
  }
  .ant-tabs.ant-tabs-top.ant-tabs-mobile.notification-tabs {
    margin-bottom: 3px;
    .ant-tabs-tab.ant-tabs-tab-active {
      justify-content: center;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0px;
      padding-left: 30px;
    }
    div#rc-tabs-1-tab-1 {
      margin-left: 30px !important;
    }
  }
  .ant-row.lajmerime-njoftime {
    display: grid !important;
    height: 900px !important;
    .ant-col.ant-col-12 {
      max-width: 100%;
    }
  }
}

@media (max-width: 360px) {
  .info-rreth-kompanis .ant-card {
    width: 275px !important;
  }
  .info-rreth-kompanis
    .info-rreth-kompanis-footer
    button.ant-btn.shiko_ndryshimet_btn {
    width: 120px;
    padding: 0;
  }
  .info-rreth-kompanis button.ant-btn.ruaj_ndryshimet_btn {
    width: 110px;
    padding: 0;
  }
  .pagat-fitimet-setings .ant-col {
    width: 100% !important;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    overflow: auto;
  }
}
