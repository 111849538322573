// punojsit page CARDS PUNOJSTI STYLE
// .punonjesit-cards {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// width: auto;
// .ant-card.ant-card-bordered {
//   border-radius: 10px;
//   box-shadow: 1px 1px 1px 1px #00000029;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 1190px;
// }
// }

//ICONA per cards
// svg#Layer_1 {
//   fill: transparent;
// }
.add-icon {
  margin-left: 110px;
  path#Path_6861_00000147205192370109036890000003473032641409739905_ {
    fill: #1da193;
  }
}

.employee-shifts {
  justify-content: center;
  align-items: center;
  width: 100%;
  // height: 755px !important;
  .shiftGridContent {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 1rem;
    .ag-grid-component {
      border-radius: 15px;
    }
  }
}
.ag-root-wrapper.ag-layout-normal.ag-ltr {
  width: 100% !important;
  height: 100%;
}
// hader i ag grid
.ag-grid-shifts {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 1190px;
  height: 732px;
  box-shadow: 1px 1px 2px 1px #00000029;
  border-radius: 15px;
  margin-top: 0px !important;
  .ag-grid-header {
    padding: 10px;
    display: flex;
    background: transparent;
    gap: 15px;
    .approve-buttons {
      margin-left: 20px;
      margin-right: 20px;
    }
    .ant-btn-group > .ant-btn:last-child:not(:first-child),
    .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      background: #1da193;
      color: white;
      border-radius: 0px 5px 5px 0px;
      &:hover {
        opacity: 0.8;
      }
    }
    .ant-btn-group > .ant-btn:first-child,
    .ant-btn-group > span:first-child > .ant-btn {
      margin-left: 0;
      background: #1da193;
      width: 100px;
      color: white;
      border-radius: 5px 0px 0px 5px;
      &:hover {
        opacity: 0.8;
      }
    }
    input.ant-input {
      background: transparent !important;
      margin-left: 10px;
      border: none;
    }
    input.ant-input::placeholder {
      color: black !important;
    }
    .icon-search-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      path#Path_6858_00000086666139294581268680000013212754614146113725_ {
        fill: #1d3445;
      }
    }
  }
  button.ant-btn.ant-btn-icon-only.ant-input-search-button {
    border: none;
    color: black !important;
    background: #f1f7fb;
  }
  button.ant-btn.pastro-btn {
    background: #ea3943;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    width: 80px;
    border-radius: 5px;
    margin-right: 20px;
    margin-left: 20px;
    color: #fff;
    cursor: pointer;
  }

  .header-search {
    background: #f1f7fb;
    display: flex;
    margin-left: 20px;
    border-radius: 5px;
    height: 32px;
  }
  // dropdwon departamenti
  .punojsit-dropdown {
    margin-left: 3px !important;
    border: none;
    .ant-select-selector {
      border-radius: 5px !important;
      background: transparent !important;
    }
    span.ant-select-arrow {
      color: #323338;
    }
  }
  .punojsit-div {
    display: flex;
    background: #f1f7fb;
    height: 32px;
    margin-left: 3px;
    border-radius: 5px;
    padding-left: 10px;
    .icon-punojsit {
      margin-top: 5px;
      path#Path_6861_00000147205192370109036890000003473032641409739905_ {
        fill: #323338;
      }
    }
    input.ant-input::placeholder {
      color: black !important;
    }
  }
  button.ant-btn.ant-btn-icon-only.ant-input-search-button {
    border: none;
    color: black !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
}

// style per iconat e header te ag grid
.header-icons {
  margin-left: 3px;
  display: flex;
}

.header-icons svg {
  background: #f1f7fb;
  width: 31px;
  height: 31px;
  border-radius: 5px;
  fill: #323338;
  padding: 7px;
  margin-right: 20px;
  cursor: pointer;
}

.header-icons {
  svg#Layer_1 {
    fill: #fff;
  }
  path#Path_7431_00000060022719262200020110000009623688766172120195_ {
    fill: #323338;
  }
  path#Path_7428_00000028307989207194211430000003663479962056332434_ {
    fill: #323338;
  }
  path#Path_6546_00000109745927328506804000000014354080646234951053_ {
    fill: #323338;
  }
  path#Path_7427_00000027570199658901481330000001697806187456729770_ {
    fill: #323338;
  }

  path#Path_7429_00000029032457934157017360000010140754146058612150_ {
    fill: #323338;
  }
}

// style per iconat e header te ag grid
.header-icons {
  margin-left: 3px;
  display: flex;
}

.header-icons {
  svg#Layer_1 {
    fill: #fff;
  }
  path#Path_7431_00000060022719262200020110000009623688766172120195_ {
    fill: #323338;
  }
  path#Path_7428_00000028307989207194211430000003663479962056332434_ {
    fill: #323338;
  }
  path#Path_6546_00000109745927328506804000000014354080646234951053_ {
    fill: #323338;
  }
  path#Path_7427_00000027570199658901481330000001697806187456729770_ {
    fill: #323338;
  }

  path#Path_7429_00000029032457934157017360000010140754146058612150_ {
    fill: #323338;
  }
}

// dropdown icona rraket
// .ant-dropdown.ant-dropdown-placement-bottomCenter.ant-dropdown {
//   width: 250px;
// }

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  height: 32px !important;
  border-bottom: 2px solid #efefef;
  padding-top: 19px;
  padding-bottom: 19px;
}
.ant-dropdown-menu {
  background: #f5f5f7;
  padding: 0px 0px;
}

.ant-dropdown.ant-dropdown-placement-bottomCenter
  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
  background: #cdd3df;
}
// .ant-dropdown.ant-dropdown-placement-bottomCenter
//   li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
//   height: 32px !important;
//   border-bottom: 2px solid #efefef;
//   padding-top: 19px;
//   padding-bottom: 19px;
// }

// .ant-dropdown.ant-dropdown-placement-bottomCenter .ant-dropdown-menu {
//   background: #f5f5f7;
//   padding: 0px 0px;
// }

// ag grid  punojsit
.ag-grid-shifts .ag-theme-alpine .ag-header {
  background: #1d3445 !important;
  border: none;
}

.ag-grid-shifts .ag-theme-alpine .ag-root-wrapper {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.ag-grid-shifts .ag-theme-alpine .ag-header-row.ag-header-row-column {
  color: #ffffff !important;
  font-weight: 400;
  font-size: medium;
}

.ag-grid-shifts .ag-theme-alpine .ag-header-cell-resize {
  // display: none;
}

.ag-grid-shifts
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
  background: #f5f5f7 !important;
  border: none !important;
}

.ag-grid-shifts
  .ag-theme-alpine
  .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
  border: none !important;
}

//hover cells ag grid
.ag-grid-shifts
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-shifts
  .ag-theme-alpine
  .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-shifts
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-shifts
  .ag-row-even.ag-row-position-absolute.ag-after-created.ag-row-focus {
  background: #fff;
  border: none;
}
.ag-grid-shifts
  .ag-row-odd.ag-row-position-absolute.ag-after-created.ag-row-focus {
  background: #f6f6f6;
  border: none;
}
.ag-grid-shifts
  .ag-row-even.ag-row-position-absolute.ag-after-created.ag-row-focus:hover {
  background: #e1e1e6;
}
.ag-grid-shifts
  .ag-row-odd.ag-row-position-absolute.ag-after-created.ag-row-focus:hover {
  background: #e1e1e6;
}
.ag-grid-shifts
  .ag-theme-alpine
  .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  background: #1d3445;
}
.ag-grid-shifts .ag-row-even.ag-after-created.ag-row-selected:hover {
  background: #e1e1e6 !important;
}

.ag-grid-shifts
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-focus.ag-row-selected:hover {
  background: #e1e1e6 !important;
}
.ag-grid-shifts
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-selected.ag-row-no-focus:hover {
  background: #e1e1e6 !important;
}

.ag-grid-shifts .ag-theme-alpine .ag-checkbox .ag-input-wrapper {
  background: #1d3445;
  width: 20px;
  height: 18px;
}
.ag-grid-shifts .ag-theme-alpine .ag-checkbox-input-wrapper::after {
  color: #1d3445 !important;
}

.ag-grid-shifts .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  color: #1d3445 !important;
  background: #ffffff !important;
}

.ag-grid-shifts .ag-theme-alpine .ag-checkbox-input-wrapper {
  font-size: 20px !important;
}

.ag-grid-shifts .ag-theme-alpine .ag-row-selected {
  background: #cdd3df !important;
}

.ag-grid-shifts
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-focus.ag-row-selected {
  background: #cdd3df !important;
}

.ag-grid-shifts
  .ag-theme-alpine
  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-after-created.ag-row-selected.ag-row-no-focus {
  background: #cdd3df !important;
}

.ag-grid-shifts .ag-theme-alpine .ag-paging-panel {
  border: none !important;
}

.ag-grid-shifts .ag-theme-alpine a {
  color: #323338;
}
.ag-grid-shifts
  .ag-theme-alpine
  span.ag-header-icon.ag-header-cell-menu-button {
  color: #fff !important;
}
.ag-grid-shifts .ag-theme-alpine span.ag-icon.ag-icon-asc {
  color: #fff !important;
}
.ag-grid-shifts .ag-theme-alpine span.ag-icon.ag-icon-desc {
  color: #fff !important;
}
.month-picker.ant-picker {
  border: none;
  background-color: #f1f7fb;
  // .anticon{
  //   display: none;
  // }
  .ant-picker-input > input {
    text-align: center;
  }
}

.employee-shifts {
  path#Path_7433 {
    fill: #323338;
  }
}

.ant-modal.shift-modal {
  .Print-Punojsit {
    visibility: collapse;
  }
}

@media (max-width: 1250px) {
  .ag-grid-shifts {
    .shiftGridContent {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
  }
}
