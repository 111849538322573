.custom-warnings {
	.amount-cards-wrapper {
		display: flex;
		flex-direction: row;
		// flex-wrap: wrap;
		gap: 10px;
	}
}

.amount-card {
	width: 170px;
	height: 70px;
	/* UI Properties */
	background: #e7e7e7 0% 0% no-repeat padding-box;
	border-radius: 5px;
	.amount-card__content {
		padding: 10px;
		animation: appearSoftly 0.8s;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.amount-card_title {
			font: normal normal normal 16px/22px Open Sans;
		}
		.amount-card_amount {
			font: normal normal 600 18px/27px Open Sans;
			animation: appearSoftly 0.8s;
		}
	}
}

@keyframes appearSoftly {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
